import { ReactElement } from 'react'
import { useSubscribeToEventsQuery } from '../../../utils/api/wsApi'
import cls from './SimplifiedLayout.module.scss'

interface LightsLayoutProps {
  header: ReactElement
  content: ReactElement
}

export const SimplifiedLayout = (props: LightsLayoutProps) => {
  const { header, content } = props
  useSubscribeToEventsQuery()

  return (
    <div className={cls.LightsLayout}>
      <div className={cls.header}>{header}</div>
      <div className={cls.content}>{content}</div>
    </div>
  )
}
