import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import React from 'react'
import { classNames } from '../../../utils/classNames/classNames'
import cls from '../NavPLCGroup/NavPLCGroup.module.scss'
import lightCls from '../NavLightGroup/NavLightGroup.module.scss'
import { Tag } from '../../UI/Tag'
import { useAppSelector } from '../../../store/hooks'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import { getLightById } from '../../../store/slices/lights/lightsSelector'

interface NavLightItemProps {
  lightId: number
  onGroupClick: (parentId: number, plcId: number, lightId: number) => void
  padLeft: number
  isManage: boolean
  plc_id: number
  parent_id: number
}

export const NavLightItem = (props: NavLightItemProps) => {
  const { lightId, onGroupClick, padLeft, isManage, plc_id, parent_id } = props
  const clickedLightId = useAppSelector(uiSelector.getClickedLightId)
  const currentLight = useAppSelector((state) => getLightById(state, lightId))

  return (
    <div
      onClick={() => onGroupClick(parent_id, plc_id, lightId)}
      style={{ paddingLeft: `${padLeft + 15}px` }}
      className={classNames(cls.navGroupTitle, {
        [cls.isOpen]: clickedLightId === currentLight?.lampID,
        [lightCls.active]: clickedLightId === currentLight?.lampID,
      })}
    >
      <i className={classNames(lightCls.lampIcon, {}, ['bi bi-lightbulb mr-8'])} />
      {isManage ? (
        <>
          {currentLight?.comment == '' ? (
            <span className={cls.navGroupName}>{parseInt(currentLight?.lampRecID, 16)}</span>
          ) : (
            <>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-group-name">{currentLight?.comment}</Tooltip>}>
                <span className={cls.navGroupName}>{currentLight?.comment}</span>
              </OverlayTrigger>
              <span className={lightCls.navGroupLight}>{parseInt(currentLight?.lampRecID, 16)}</span>
            </>
          )}
        </>
      ) : (
        <>
          {currentLight?.comment == '' ? (
            <div className={cls.navGroupName}>{parseInt(currentLight?.lampRecID, 16)}</div>
          ) : (
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-group-name">{currentLight?.comment}</Tooltip>}>
              <div className={cls.navGroupName}>{currentLight?.comment || parseInt(currentLight?.lampRecID, 16)}</div>
            </OverlayTrigger>
          )}
          {(!!currentLight?.alarm_count || !!currentLight?.warning_count) && (
            <Tag
              label={currentLight.alarm_count + currentLight.warning_count}
              theme={currentLight.alarm_count !== 0 ? 'error' : 'warning'}
            />
          )}
        </>
      )}
    </div>
  )
}
