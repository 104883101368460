import { ChangeEvent, memo, ReactNode } from 'react'
import { HStack } from '../Stack'
import { Icon } from '../Icon'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import { ReactComponent as CheckMarkIcon } from '../../../assets/icons/check-mark-icon.svg'
import cls from './Checkbox.module.scss'

interface CheckboxProps {
  /**
   * Текст подписи к чекбоксу
   */
  label?: ReactNode
  /**
   * Обработчик переключения чекбокса
   */
  onChange: (value: boolean) => void
  /**
   * Управление состоянием вкл/выкл чекбокса (native prop)
   */
  isChecked?: boolean
  /**
   * Управление состоянием включен / выключен
   */
  isDisable?: boolean
  /**
   * Доп. класс для чекбокса
   */
  className?: string
  /**
   * Управление неопределенным состоянием чекбокса
   */
  indeterminate?: boolean
}

export const Checkbox = memo((props: CheckboxProps) => {
  const { label, onChange, isChecked = false, isDisable = false, className, indeterminate = false } = props

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  const mods: Mods = {
    [cls.disabled]: isDisable,
    [cls.checked]: isChecked,
    [cls.indeterminate]: indeterminate,
  }

  return (
    <label className={classNames(cls.component, mods, [className])}>
      <HStack gap="8">
        <input type="checkbox" disabled={isDisable} checked={isChecked} className={cls.checkbox} onChange={handleChange} />
        <span className={cls.box}>
          {isChecked && <Icon Svg={CheckMarkIcon} className={cls.checkedIcon} />}

          {indeterminate && !isChecked && <span className={cls.indeterminateLine} />}
        </span>
        {label}
      </HStack>
    </label>
  )
})
