import React, { memo } from 'react'
import { VStack } from '../../UI/Stack'
import { ControlInfoType, RowInfo } from '../../UI/RowInfo'
import { Controller } from '../../../types/controller'
import { formatDt } from '../../../utils/functions/formatDt'

interface CounterInfoProps {
  controller: Controller
}

export const CounterInfo = memo((props: CounterInfoProps) => {
  const { controller } = props

  return (
    <VStack max gap="8">
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Серийный номер"
        value={controller.electricityMeterInfo.serialNumber || 'не найден'}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Дата изготовления"
        value={controller.electricityMeterInfo.manufacturingDate || 'не найден'}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Время показаний по часам счетчика"
        value={formatDt(controller.electricityMeterInfo.timestampByMeter || Date.now())}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Время показаний по часам контроллера"
        value={formatDt(controller.electricityMeterInfo.timestampByServer || Date.now())}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Мощность активная, Вт"
        value={controller.electricityMeterInfo.activePowerByPhaseSum / 100 || 0}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Энергия активная по сумме тарифов, кВт×ч"
        value={controller.electricityMeterInfo.activeEnergyByTarifSum / 1000 || 0}
      />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Частота сети, Гц"
        value={controller.electricityMeterInfo.frequencyNetwork / 100 || 0}
      />
    </VStack>
  )
})
