import React from 'react'
import {
  AppRoutes,
  getRouteEvents,
  getRouteGraph,
  getRouteLogin,
  getRouteManage,
  getRouteMap,
  getRouteStatus,
  getRouteUser,
  getRouteForbidden,
  getRouteYearSchedule,
  getRouteManageSettings,
  getRouteDiagrams,
  getRouteScripts,
  getRouteLicense,
} from '../consts/router'
import { AppRoutesProps } from '../types/router'
import { LoginPage } from '../pages/LoginPage'
import { StatusPage } from '../pages/StatusPage'
import { EventsPage } from '../pages/EventsPage'
import { MapPage } from '../pages/MapPage'
import { GraphPage } from '../pages/GraphPage'
import { User } from '../pages/UserPage'
import { NotFoundPage } from '../pages/NotFoundPage'
import { YearSchedulePage } from '../pages/YearSchedulePage'
import { ControllerDiagramsPage } from '../pages/ControllerDiagramsPage'
import { ControllerScriptsPage } from '../pages/ControllerScriptsPage'
import { ManageSettingsPage } from '../components/ManageSettingsPage'
import { Manage } from '../pages/ManagePage/ui/Manage/Manage'
import { ForbiddenPage } from '../pages/ForbiddenPage'
import { LicensedPage } from '../pages/LicensedPage'

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <LoginPage />,
  },
  [AppRoutes.STATUS]: {
    path: getRouteStatus(),
    element: <StatusPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'status',
  },
  [AppRoutes.EVENTS]: {
    path: getRouteEvents(),
    element: <EventsPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'events',
  },
  [AppRoutes.MAP]: {
    path: getRouteMap(),
    element: <MapPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'map',
  },
  [AppRoutes.GRAPH]: {
    path: getRouteGraph(),
    element: <GraphPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'reports',
  },
  [AppRoutes.MANAGE]: {
    path: getRouteManage(),
    element: <Manage />,
    authOnly: true,
    scriptsOnly: true,
    licenseOnly: true,
    pageTypes: 'manage',
  },
  [AppRoutes.YEAR_SCHEDULE]: {
    path: getRouteYearSchedule(),
    element: <YearSchedulePage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'manage',
  },
  [AppRoutes.DIAGRAMS]: {
    path: getRouteDiagrams(),
    element: <ControllerDiagramsPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'manage',
  },
  [AppRoutes.SCRIPTS]: {
    path: getRouteScripts(),
    element: <ControllerScriptsPage />,
    authOnly: true,
    scriptsOnly: true,
    licenseOnly: true,
    pageTypes: 'manage',
  },
  [AppRoutes.MANAGE_SETTINGS]: {
    path: getRouteManageSettings(),
    element: <ManageSettingsPage />,
    authOnly: true,
    licenseOnly: true,
    pageTypes: 'manage',
  },
  [AppRoutes.USER]: {
    path: getRouteUser(),
    element: <User />,
    authOnly: true,
    pageTypes: 'user',
  },
  [AppRoutes.LICENSE]: {
    path: getRouteLicense(),
    element: <LicensedPage />,
    authOnly: true,
    scriptsOnly: true,
  },
  [AppRoutes.FORBIDDEN]: {
    path: getRouteForbidden(),
    element: <ForbiddenPage />,
  },
  // last
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <NotFoundPage />,
    authOnly: true,
  },
}
