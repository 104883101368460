import React, { memo } from 'react'
import { HStack, VStack } from '../Stack'
import { Text, TextSize, TextTheme } from '../Text'
import { Tag } from '../Tag'
import { ReactComponent as ControllerIcon } from '../../../assets/icons/controller-icon.svg'
import { ReactComponent as LightIcon } from '../../../assets/icons/light-icon.svg'
import { Icon } from '../Icon'

interface PageTitleProps {
  title: string
  subTitle?: string
  type?: 'light' | 'controller'
  addonLeft?: React.VFC<React.SVGProps<SVGSVGElement>>
  id?: string
  isMini?: boolean
}

export const PageTitle = memo((props: PageTitleProps) => {
  const { title, subTitle, type, addonLeft, id, isMini = false } = props

  return (
    <HStack align="center" gap="8">
      {!!addonLeft && <Icon Svg={addonLeft} />}
      {type && <Icon Svg={type === 'controller' ? ControllerIcon : LightIcon} width={isMini ? 16 : undefined} />}
      <VStack max>
        <HStack gap="8">
          <Text size={isMini ? TextSize.S : TextSize.L} title={title} />
          {id && !isMini && <Tag label={id} />}
        </HStack>
        <Text text={subTitle} theme={TextTheme.LIGHT} />
      </VStack>
    </HStack>
  )
})
