import { memo, useMemo } from 'react'
import { Nav } from 'react-bootstrap'
import { NavBarItem } from './NavBarItem'
import { config } from '../../config.js'
import { useAppSelector } from '../../store/hooks'
import { getNavbarItems } from '../../store/slices/navbar/navbarSelector'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-icon.svg'
import { VStack } from '../UI/Stack'
import { classNames } from '../../utils/classNames/classNames'
import cls from './NavBarVert.module.scss'

const NavBarVert = () => {
  console.log('🚀 ~ file: NavBarVert isRecon', config.isRecon)
  const navbarItemsList = useAppSelector(getNavbarItems)

  const itemsList = useMemo(() => navbarItemsList.map((item) => <NavBarItem item={item} key={item.path} />), [navbarItemsList])

  return (
    <VStack className={classNames(cls.navbar, { [cls.isRecon]: config.isRecon })}>
      <div className={classNames(cls.logoWrapper, { [cls.isRecon]: config.isRecon })}>{!config.isRecon && <LogoIcon />}</div>

      <Nav className="flex-column align-items-center py-2 nav-main" activeKey="/map">
        {itemsList}
      </Nav>
    </VStack>
  )
}

export default memo(NavBarVert)
