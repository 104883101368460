import cls from './Loader.module.scss'
import { classNames } from '../../../utils/classNames/classNames'

type LoaderSize = 's' | 'm' | 'l'

interface LoaderProps {
  className?: string
  size?: LoaderSize
}

export const Loader = ({ className, size = 'm' }: LoaderProps) => (
  <div className={classNames(cls.spinner, {}, [className, cls[size]])}>
    <div className={cls.bounce1} />
    <div className={cls.bounce2} />
    <div className={cls.bounce3} />
  </div>
)
