/**
 * Created by Yoshka on 22.01.2020.
 */
export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` }
  }

  return {}
}
