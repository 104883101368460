const phaseStatuses = {
  lowerAlarmLevel: 154,
  lowerRefLevel: 176,
  upperRefLevel: 264,
  upperAlarmLevel: 286,
}

export const colorize = {
  phase(value) {
    let color = '#282828'
    if (
      (value <= phaseStatuses.lowerRefLevel && value > phaseStatuses.lowerAlarmLevel) ||
      (value >= phaseStatuses.upperRefLevel && value < phaseStatuses.upperAlarmLevel)
    ) {
      color = 'yellow'
    } else if (value <= phaseStatuses.lowerAlarmLevel || value >= phaseStatuses.upperAlarmLevel) {
      color = 'red'
    }
    const colorizedValue = <span style={{ color, fontSize: '14px' }}>{value}</span>

    return colorizedValue
  },
  door(value) {
    let color = ''
    color = value == 'открыта' && 'red'
    const colorizedValue = <span style={{ color }}>{value}</span>

    return colorizedValue
  },
  fireAlarm(value) {
    let color = ''
    color = value == 'тревога' && 'red'
    const colorizedValue = <span style={{ color }}>{value}</span>

    return colorizedValue
  },
  output(value) {
    let color = ''
    color = value == 'выкл.' && 'red'
    const colorizedValue = <span style={{ color }}>{value}</span>

    return colorizedValue
  },
}

export const formatTime = (val) => {
  const min =
    parseInt(val?.replace(/(\d+):(\d+)/, '$2')) < 10 && !val?.replace(/(\d+):(\d+)/, '$2').includes('0')
      ? val?.replace(/(\d+):(\d+)/, '0$2')
      : val?.replace(/(\d+):(\d+)/, '$2')
  const h =
    parseInt(val?.replace(/(\d+):(\d+)/, '$1')) < 10 && !val?.replace(/(\d+):(\d+)/, '$1').includes('0')
      ? val?.replace(/(\d+):(\d+)/, '0$1')
      : val?.replace(/(\d+):(\d+)/, '$1')

  return val?.replace(/(\d+):(\d+)/, `${h}:${min}`)
}
