import React from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { AdminManage } from '../AdminManage/AdminManage'
import { ManagePage } from '../../index'

export const Manage = () => {
  const plcId = useAppSelector(uiSelector.getClickedPlcId)

  return plcId ? <AdminManage /> : <ManagePage />
}
