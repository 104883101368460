export const formatDt = (date: number | Date = Date.now(), hideTime?: boolean, hideDate?: boolean) => {
  const options: Intl.DateTimeFormatOptions = {
    year: !hideDate ? '2-digit' : undefined,
    month: !hideDate ? '2-digit' : undefined,
    day: !hideDate ? '2-digit' : undefined,
    hour: !hideTime ? 'numeric' : undefined,
    minute: !hideTime ? 'numeric' : undefined,
    second: !hideTime && !hideDate ? '2-digit' : undefined,
  }

  return new Intl.DateTimeFormat('ru', options).format(typeof date === 'number' ? new Date(date * 1000) : date)
}
