import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { render } from 'react-dom'
import './style.scss'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from './store/store'
import App from './App'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import ThemeProvider from './utils/providers/ThemeProvider'

render(
  <BrowserRouter>
    <Provider store={store}>
      <ErrorBoundary>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
