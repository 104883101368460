import { Feature, FeatureFlag } from '../../../types/feature'

let featureFlags: FeatureFlag = {}

export function setFeatureFlags(newFeatureFlags?: Feature[]) {
  if (newFeatureFlags?.length) {
    const newFeatures = {} as FeatureFlag
    newFeatureFlags.forEach((feature: Feature) => {
      newFeatures[feature.name] = feature.value
    })
    featureFlags = newFeatures
  }
}

export function getFeatureFlag(flag: keyof FeatureFlag) {
  return featureFlags[flag]
}
