import { memo, ReactElement } from 'react'
import { classNames } from '../../../utils/classNames/classNames'
import { useSubscribeToEventsQuery } from '../../../utils/api/wsApi'
import cls from './MainLayout.module.scss'

interface MainLayoutProps {
  className?: string
  header: ReactElement
  content: ReactElement
  sidebar: ReactElement
  navBarVert: ReactElement
}

export const MainLayout = memo((props: MainLayoutProps) => {
  const { className, content, navBarVert, header, sidebar } = props

  useSubscribeToEventsQuery()

  return (
    <div className={classNames(cls.MainLayout, {}, [className])}>
      <div className={cls.sidebar}>{sidebar}</div>
      <div className={cls.navbarvert}>{navBarVert}</div>
      <div className={cls.rightbar}>
        <div className={cls.header}>{header}</div>
        <div className={cls.content}>{content}</div>
      </div>
    </div>
  )
})
