/**
 * Created by Aleksey Osipov on 26.05.2020.
 */

let new_diag
let newState

const pls_diagrams = (state = {}, action) => {
  if (action.diag?.profile?.points) {
    const newPoints = action.diag?.profile?.points?.reduce((acc, val) => {
      if (acc.find((f) => f.time === val.time)) {
        return acc
      }
      acc.push(val)

      return acc
    }, [])

    action.diag.profile.points = newPoints
  }
  switch (action.type) {
    case 'ADD_PLS_DIAGRAM':
      new_diag = {}
      Object.defineProperty(new_diag, action.diag.diagramID, {
        enumerable: true,
        value: { ...action.diag },
      })

      return { ...state, ...new_diag }

    case 'ADD_PLS_DIAGRAMS':
      newState = { ...state }
      action.diags.forEach((diag) => {
        Object.defineProperty(newState, diag.diagramID, { enumerable: true, value: diag })
      })

      return { ...newState }

    case 'EDIT_PLS_DIAGRAM':
      return { ...state, [action.diag.diagramID]: { ...action.diag } }

    case 'DEL_PLS_DIAGRAM':
      newState = state
      Object.values(newState).forEach((diag) => {
        if (diag.diagramID == action.diag_id) delete newState[diag.diagramID]
      })

      return { ...state, ...newState }

    default:
      return state
  }
}

export default pls_diagrams
