import React, { useMemo } from 'react'
import { useAppSelector } from '../../store/hooks'
import { getPLSById } from '../../store/slices/plcs/plcsSelector'
import { ControlParamsCard } from '../../pages/StatusPage/ui/ControlParamsCard/ControlParamsCard'
import { HStack } from '../UI/Stack'
import cls from './VoltageOnPhaseList.module.scss'
import { colorize } from '../../pages/StatusPage/model/helper/helper'

interface VoltageOnPhaseListProps {
  plcId: number
  isElectric?: boolean
  isChangeTitle?: boolean
}

export const VoltageOnPhaseList = (props: VoltageOnPhaseListProps) => {
  const { plcId, isElectric = false, isChangeTitle = false } = props
  const currentPlc = useAppSelector((state) => getPLSById(state, plcId))

  const values = useMemo(
    () => [
      {
        name: 'L1',
        value: (
          <HStack justify="center" className={cls.valueWrapper}>
            {(isElectric
              ? colorize.phase(
                  currentPlc?.electricityMeterInfo?.voltageOnPhase1
                    ? currentPlc.electricityMeterInfo.voltageOnPhase1 / 100
                    : currentPlc?.voltageOnPhase?.l1
                )
              : currentPlc?.voltageOnPhase?.l1) ?? '-'}
          </HStack>
        ),
      },
      {
        name: 'L2',
        value: (
          <HStack justify="center" className={cls.valueWrapper}>
            {(isElectric
              ? colorize.phase(
                  currentPlc?.electricityMeterInfo?.voltageOnPhase2
                    ? currentPlc.electricityMeterInfo.voltageOnPhase2 / 100
                    : currentPlc?.voltageOnPhase?.l2
                )
              : currentPlc?.voltageOnPhase?.l2) ?? '-'}
          </HStack>
        ),
      },
      {
        name: 'L3',
        value: (
          <HStack justify="center" className={cls.valueWrapper}>
            {(isElectric
              ? colorize.phase(
                  currentPlc?.electricityMeterInfo?.voltageOnPhase3
                    ? currentPlc.electricityMeterInfo.voltageOnPhase3 / 100
                    : currentPlc?.voltageOnPhase?.l3
                )
              : currentPlc?.voltageOnPhase?.l3) ?? '-'}
          </HStack>
        ),
      },
    ],
    [
      currentPlc.electricityMeterInfo?.voltageOnPhase1,
      currentPlc.electricityMeterInfo?.voltageOnPhase2,
      currentPlc.electricityMeterInfo?.voltageOnPhase3,
      currentPlc?.voltageOnPhase?.l1,
      currentPlc?.voltageOnPhase?.l2,
      currentPlc?.voltageOnPhase?.l3,
      isElectric,
    ]
  )

  return <ControlParamsCard title={`Напряжение на ${isChangeTitle ? 'входах' : 'фазах'}, В`} values={values} type="vertical" />
}
