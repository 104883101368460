import React, { memo, useMemo } from 'react'
import { Controller } from '../../../types/controller'
import { HStack, VStack } from '../../UI/Stack'
import { ControlParamsCard } from '../../../pages/StatusPage/ui/ControlParamsCard/ControlParamsCard'
import { classNames } from '../../../utils/classNames/classNames'
import { VoltageOnPhaseList } from '../../VoltageOnPhaseList/VoltageOnPhaseList'
import cls from '../ControllerState/ControllerState.module.scss'

interface CounterStateProps {
  plcId: number
  controller: Controller
  className?: string
}

export const CounterState = memo((props: CounterStateProps) => {
  const { plcId, controller, className } = props

  const cardData = useMemo(() => {
    return [
      {
        id: 2,
        title: 'Ток на фазах, А',
        values: [
          {
            name: 'L1',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.amperageOnPhase1 / 1000 || 0}
              </HStack>
            ),
          },
          {
            name: 'L2',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.amperageOnPhase2 / 1000 || 0}
              </HStack>
            ),
          },
          {
            name: 'L3',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.amperageOnPhase3 / 1000 || 0}
              </HStack>
            ),
          },
        ],
      },
      {
        id: 3,
        title: 'Мощность активная по фазам, Вт',
        values: [
          {
            name: 'L1',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.activePowerByPhase1 / 100 || 0}
              </HStack>
            ),
          },
          {
            name: 'L2',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.activePowerByPhase2 / 100 || 0}
              </HStack>
            ),
          },
          {
            name: 'L3',
            value: (
              <HStack justify="center" className={cls.valueWrapper}>
                {controller.electricityMeterInfo.activePowerByPhase3 / 100 || 0}
              </HStack>
            ),
          },
        ],
      },
    ]
  }, [controller])

  return (
    <VStack max gap="12" className={classNames('', {}, [className])}>
      <VoltageOnPhaseList plcId={plcId} isElectric />
      {cardData.map((data) => (
        <ControlParamsCard key={data.id} title={data.title} values={data.values} type="vertical" />
      ))}
    </VStack>
  )
})
