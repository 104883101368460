import { createAsyncThunk } from '@reduxjs/toolkit'
import { ThunkConfig } from '../../store'
import { OrganisationLicence } from '../../../types/organisationLicence'
import { Status } from '../../../types/status'

interface LicenseResult {
  result: Status
  errorText?: string
  successText?: string
  organisationLicence?: OrganisationLicence
}

export const licenseService = createAsyncThunk<LicenseResult, string, ThunkConfig<string>>(
  'auth/licenseService',
  async (code, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi

    try {
      const response = await extra.api.post<LicenseResult>('/api/groups/activate/licence', {
        code,
      })

      if (!response.data) {
        throw new Error()
      }

      return response.data
    } catch (e) {
      console.log(e)

      return rejectWithValue('error set license')
    }
  }
)
