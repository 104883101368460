import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { config } from '../../../config'
import { Status } from '../../../types/status'

export type IFeatureItem = { id: number; name: string; desc: string }
export type IRoleFeaturesItem = { id: number; value: boolean }

export interface IRole {
  id: number | null
  name: string
  features: number[]
}

export interface IRolesState {
  status_get_role: Status
  status_get_features: Status
  status_save_role: Status
  roles: IRole[]
  features: IFeatureItem[]
  error: any
}

const initialState: IRolesState = {
  status_get_role: 'idle',
  status_get_features: 'idle',
  status_save_role: 'idle',
  roles: [],
  features: [],
  error: '',
}

const getRoles = createAsyncThunk('roles/get', async (_, { rejectWithValue }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  try {
    const response = await axios.get(`${config.apiUrl}/api/users/roles`)

    return response.data.roles.map((v: any) => {
      return { ...v, features: v.features.map((ftr: any) => ftr.id) }
    })
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response?.statusText || error?.message)
    }

    return rejectWithValue(error)
  }
})

const createSaveRole = createAsyncThunk(
  'roles/set',
  async (body: { id: number | null; name: string; features: number[] }, { rejectWithValue }) => {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    try {
      const response = await axios.post(`${config.apiUrl}/api/users/roles`, body)

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.statusText || error?.message)
      }

      return rejectWithValue(error)
    }
  }
)

const getFeatures = createAsyncThunk('features/get', async (_, { rejectWithValue }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  try {
    const response = await axios.get(`${config.apiUrl}/api/users/features`)

    return response.data.features
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response?.statusText || error?.message)
    }

    return rejectWithValue(error)
  }
})

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = ''
    },
    resetSaveRoleStatus: (state) => {
      state.status_save_role = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.status_get_role = 'loading'
        state.error = ''
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.status_get_role = 'error'
        state.error = action.payload
      })
      .addCase(getRoles.fulfilled, (state, action: PayloadAction<IRole[]>) => {
        state.status_get_role = 'success'
        state.roles = action.payload
        state.error = ''
      })
      .addCase(createSaveRole.pending, (state) => {
        state.status_save_role = 'loading'
        state.error = ''
      })
      .addCase(createSaveRole.rejected, (state, action) => {
        state.status_save_role = 'error'
        state.error = action.payload
      })
      .addCase(createSaveRole.fulfilled, (state, action: PayloadAction<IRole[]>) => {
        state.status_save_role = 'success'
        state.error = ''
      })

      .addCase(getFeatures.pending, (state) => {
        state.status_get_features = 'loading'
        state.error = ''
      })
      .addCase(getFeatures.rejected, (state, action) => {
        state.status_get_features = 'error'
        state.error = action.payload
      })
      .addCase(getFeatures.fulfilled, (state, action: PayloadAction<IFeatureItem[]>) => {
        state.status_get_features = 'success'
        state.features = action.payload
        state.error = ''
      })
  },
})

export const rolesActions = { ...rolesSlice.actions, createSaveRole, getFeatures, getRoles }

export default rolesSlice.reducer
