import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { uiSelector } from '../../store/slices/ui/uiSelector'
import { uiActions as uiActionsNew } from '../../store/slices/ui/uiSlice'
import cls from './HeaderSearch.module.scss'
import { HStack } from '../UI/Stack'
import { Input } from '../UI/Input'
import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg'
import { Icon } from '../UI/Icon'

const HeaderSearch = () => {
  const dispatch = useAppDispatch()
  const menuSearchText = useAppSelector(uiSelector.getMenuSearchText)

  const onChangeSearch = useCallback(
    (value: string) => dispatch(uiActionsNew.changeMenuSearchText({ searchText: value })),
    [dispatch]
  )

  return (
    <HStack max align="center" justify="center" className={cls.headerSearch}>
      <Input
        className={cls.input}
        placeholder="Название или ID объекта"
        value={menuSearchText}
        onChange={onChangeSearch}
        addonRight={<Icon Svg={SearchIcon} inverted />}
      />
    </HStack>
  )
}

export default HeaderSearch
