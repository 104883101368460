import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthData, AuthDataSchema } from '../../../types/authData'
import { authService } from './authService'

const localStorageUser = JSON.parse(localStorage.getItem('user'))

const initialState: AuthDataSchema = {
  status: 'idle',
  authData: localStorageUser ? { token: localStorageUser.token, refreshToken: localStorageUser.refreshToken } : null,
  error: {},
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authService.pending, (state) => {
        state.status = 'loading'
        state.error = ''
      })
      .addCase(authService.rejected, (state, action) => {
        state.status = 'error'
        state.error = action.payload
      })
      .addCase(authService.fulfilled, (state, { payload }: PayloadAction<AuthData>) => {
        state.status = 'success'
        state.authData = payload
        state.error = ''
      })
  },
})

export const authActions = { ...authSlice.actions }

export default authSlice.reducer
