import React from 'react'
import { useAppSelector } from '../../store/hooks'
import { uiSelector } from '../../store/slices/ui/uiSelector'
import { LightSettingsPage } from '../LightSettings'
import { ControllerSettingsPage } from '../ControllerSettings'

export const ManageSettingsPage = () => {
  const lightId = useAppSelector(uiSelector.getClickedLightId)

  return lightId ? <LightSettingsPage /> : <ControllerSettingsPage />
}
