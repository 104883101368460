const addPLSScript = (script) => ({ type: 'ADD_PLS_SCRIPT', script })
const addPLSScripts = (scripts) => ({ type: 'ADD_PLS_SCRIPTS', scripts })
const editPLSScript = (plc_id, script) => ({ type: 'EDIT_PLS_SCRIPT', plc_id, script })
const delPLSScripts = (plc_id) => ({ type: 'DEL_PLS_SCRIPT', plc_id })

export const plsScriptsActions = {
  addPLSScript,
  addPLSScripts,
  editPLSScript,
  delPLSScripts,
}
