import React, { InputHTMLAttributes, memo, ReactNode, useEffect, useRef } from 'react'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import { HStack, VStack } from '../Stack'
import { Text, TextSize, TextTheme } from '../Text'
import cls from './Input.module.scss'

type HTMLInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange' | 'readOnly' | 'size'>

type InputSize = 's' | 'm' | 'l'

interface InputProps extends HTMLInputProps {
  className?: string
  value?: string | number
  label?: string
  onChange?: (value: string) => void
  autofocus?: boolean
  readonly?: boolean
  afterIcon?: React.VFC<React.SVGProps<SVGSVGElement>>
  fullWidth?: boolean
  isInvalid?: boolean
  addonLeft?: ReactNode
  addonRight?: ReactNode
  size?: InputSize
  labelIsBottom?: boolean
}

export const Input = memo((props: InputProps) => {
  const {
    className,
    value,
    onChange,
    type = 'text',
    placeholder,
    autofocus,
    readonly,
    fullWidth,
    isInvalid,
    addonLeft,
    addonRight,
    label,
    size = 'm',
    labelIsBottom = false,
    ...otherProps
  } = props
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (autofocus) {
      ref.current?.focus()
    }
  }, [autofocus])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  const modsWrapper: Mods = {
    [cls.fullWidth]: fullWidth,
  }

  const mods: Mods = {
    [cls.withAddonLeft]: Boolean(addonLeft),
    [cls.withAddonRight]: Boolean(addonRight),
    [cls.isInvalid]: isInvalid,
  }

  return (
    <div className={classNames(cls.InputWrapper, modsWrapper, [className])}>
      <VStack max>
        {!labelIsBottom && (
          <Text
            text={label}
            className={classNames(cls.label, { [cls.required]: props.required })}
            theme={TextTheme.LIGHT}
            size={size === 's' ? TextSize.S : TextSize.M}
          />
        )}
        <HStack max>
          <div className={cls.addonLeft}>{addonLeft}</div>
          <input
            ref={ref}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChangeHandler}
            className={classNames(cls.input, mods, [cls[size]])}
            readOnly={readonly}
            {...otherProps}
          />
          {!!addonRight && <div className={cls.iconWrapper}>{addonRight}</div>}
        </HStack>
        {labelIsBottom && <Text text={label} theme={TextTheme.LIGHT} size={size === 's' ? TextSize.S : TextSize.M} />}
      </VStack>
    </div>
  )
})
