import { Col } from 'react-bootstrap'
import { memo, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HeaderSearch from '../../HeaderSearch'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { Button } from '../../UI/Button'
import { classNames } from '../../../utils/classNames/classNames'
import { uiActions as uiActionsNew } from '../../../store/slices/ui/uiSlice'
import { RootHomeGroup } from '../RootHomeGroup/RootHomeGroup'
import { mapDataParsing } from '../../../store/slices/mapData/mapDataService'
import { getOrganisationLicense } from '../../../store/slices/user/userSelectors'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import cls from './Sidebar.module.scss'

const Sidebar = () => {
  const groupId = useAppSelector(uiSelector.getClickedGroupId)
  const lightId = useAppSelector(uiSelector.getClickedLightId)
  const [collapsed, setCollapsed] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const license = useAppSelector(getOrganisationLicense)
  const dispatch = useAppDispatch()

  const { pathname } = useLocation()

  useEffect(() => {
    if (license?.isLicensed && !license?.licenseState) {
      setIsDisabled(true)
      setCollapsed(true)
    } else {
      setIsDisabled(false)
      setCollapsed(false)
    }
  }, [license])

  const onToggle = () => {
    setCollapsed((prev) => !prev)
  }

  const onGroupClick = useCallback(
    (group_id: number, plc_id: number, light_id: number) => {
      dispatch(uiActionsNew.setClickedGroup({ id: group_id }))
      dispatch(uiActionsNew.setClickedPLC({ id: plc_id }))
      dispatch(uiActionsNew.setClickedLight({ id: light_id }))
      if (groupId !== group_id || lightId !== light_id) {
        dispatch(uiActionsNew.setEventsTotalCount({ count: 0 }))
        dispatch(uiActionsNew.setCurrentPage(1))
      }
      dispatch(mapDataParsing({ groupId: group_id, plcId: plc_id, lightId: light_id }))
    },
    [dispatch, groupId, lightId]
  )

  let content
  if (pathname === '/user') {
    return null
  }
  if (pathname === '/events') {
    content = <RootHomeGroup onGroupClick={onGroupClick} isFullHeight />
  } else {
    content = (
      <>
        <HeaderSearch />
        <RootHomeGroup onGroupClick={onGroupClick} isManage />
      </>
    )
  }

  return (
    <>
      {!isDisabled && (
        <Col className={classNames(cls.sidebar, { [cls.collapsed]: collapsed })} xs={3}>
          <div className={cls.contentWrapper}>{content}</div>

          <Button onClick={onToggle} className={cls.collapseBtn} disabled={isDisabled}>
            {collapsed ? '>' : '<'}
          </Button>
        </Col>
      )}
    </>
  )
}

export default memo(Sidebar)
