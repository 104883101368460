import React from 'react'
import { HStack, VStack } from '../../UI/Stack'
import cls from './CounterCard.module.scss'
import { PageTitle } from '../../UI/PageTitle'
import { Button, ButtonTheme } from '../../UI/Button'
import { Icon } from '../../UI/Icon'
import { Card } from '../../UI/Card'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { CounterInfo } from '../CounterInfo/CounterInfo'
import { CounterState } from '../CounterState/CounterState'
import { useAppSelector } from '../../../store/hooks'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import { getPLSById } from '../../../store/slices/plcs/plcsSelector'

interface CounterCardProps {
  onClose: () => void
}

export const CounterCard = ({ onClose }: CounterCardProps) => {
  const plcId = useAppSelector(uiSelector.getClickedPlcId)
  const currentPlc = useAppSelector((state) => getPLSById(state, plcId))

  return (
    <VStack className={cls.counterCardWrapper} gap="16">
      <HStack max justify="between" className={cls.header}>
        <PageTitle title="Показания счётчика" />
        <Button theme={ButtonTheme.CLEAR} onClick={onClose}>
          <Icon Svg={CloseIcon} className={cls.close} />
        </Button>
      </HStack>
      <Card max className={cls.card}>
        <VStack gap="16">
          <CounterInfo controller={currentPlc} />
          <CounterState plcId={plcId} controller={currentPlc} className={cls.stateWrapper} />
        </VStack>
      </Card>
    </VStack>
  )
}
