import React from 'react'
import { Collapse } from 'react-bootstrap'
import { connect } from 'react-redux'
import { uiActions as uiActionsNew } from '../store/slices/ui/uiSlice'
import { socket } from '../utils'
import { NavPLCGroup } from './Sidebar/NavPLCGroup/NavPLCGroup'
import { config } from '../config'
import { groupsActions } from '../store/slices/groups/groupsSlice'
import { plcsActions } from '../store/slices/plcs/plcsSlice'
import { getFeatureFlag, ToggleFeatures } from '../utils/features'
import { AddGroupModal } from '../pages/ManagePage/ui/AddGroupModal/AddGroupModal'
import { getUser } from '../store/slices/user/userSelectors'

class NavAddressGroupManage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // groups: this.props.groups,
      // open: false,
      showRemove: false,
      showEdit: false,
    }
    this.changeGroup = this.changeGroup.bind(this)
    this.handleRemoveGroup = this.handleRemoveGroup.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleChangeEditVisibility = this.handleChangeEditVisibility.bind(this)
    this.handleChangeRemoveVisibility = this.handleChangeRemoveVisibility.bind(this)
    this.checkParents = this.checkParents.bind(this)
    this.asyncClose = this.asyncClose.bind(this)
    this.closeParents = this.closeParents.bind(this)
  }

  handleClick() {
    this.props.onGroupClick(this.props.parent_id, null, null)
  }

  handleToggleMenu() {
    const child = [...Object.values(this.props.groups).filter((group) => group.parent_id == this.props.currentGroup.id)]

    if (this.props.currentGroup.isMenuOpen || !this.props.currentGroup.isMenuOpen || child) {
      child.map((group) => {
        this.props.closeMenuChild(group)
      })
    }
    this.props.toggleMenu(this.props.currentGroup, !this.props.currentGroup.isMenuOpen)
  }

  handleChangeEditVisibility(value) {
    this.setState({ showEdit: value })
  }

  handleChangeRemoveVisibility(value) {
    this.setState({ showRemove: value })
  }

  handleRemoveGroup() {
    console.log('Удаление группы')

    socket?.emit('/groups/remove', {
      groupId: this.props.parent_id,
    })
    this.props.removeGroup(this.props.parent_id)
  }

  checkParents(parentId) {
    if (parentId !== 'root') {
      this.props.openMenu(this.props.groups[parentId])
      this.checkParents(this.props.groups[parentId]?.parent_id)
    }
  }

  asyncClose(parentId) {
    return new Promise((resolve, reject) => {
      this.closeParents(parentId)
      resolve(parentId)
    })
  }

  closeParents(parentId) {
    if (parentId !== 'root') {
      this.props.closeMenuChild(this.props.groups[parentId])
      this.closeParents(this.props.groups[parentId]?.parent_id)
    }
  }

  changeGroup(value) {
    console.log(value)

    socket?.emit('/groups/edit', { groupId: this.props.parent_id, name: value }, (data) => {
      console.log(data)
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.groups !== prevProps.groups) {
      this.setState({ groups: this.props.groups })
    }

    if (this.props.ui.menuSearchText !== prevProps.ui.menuSearchText) {
      const parentId = this.props.groups[this.props.parent_id]?.parent_id

      if (this.props.ui.menuSearchText) {
        if (this.props.groups[this.props.parent_id]?.name.toLowerCase().includes(this.props.ui.menuSearchText.toLowerCase())) {
          if (parentId !== 'root') {
            this.asyncClose(parentId).then((parentId) => {
              this.checkParents(parentId)
            })
          }
        } else if (parentId !== 'root') {
          this.closeParents(parentId)
        }
      } else if (parentId !== 'root') {
        this.closeParents(parentId)
      }
    }
  }

  render() {
    return (
      <div className="nav-group">
        <div
          onClick={this.handleClick}
          style={{ paddingLeft: `${this.props.padLeft}px` }}
          className={`nav-group-title nav-group-title-${this.props.currentGroup?.isMenuOpen ? 'open' : 'close'} ${
            config.isRecon ? 'isRecon' : ''
          } ${this.props.ui.clicked_group_id === this.props.currentGroup.id ? 'isActive' : ''}`}
        >
          <i className="bi bi-collection" />
          <div
            className={`${
              this.props.ui.menuSearchText
                ? !this.props.groups[this.props.parent_id]?.name
                    .toLowerCase()
                    .includes(this.props.ui.menuSearchText.toLowerCase())
                  ? 'nav-group-name'
                  : 'nav-group-name__find'
                : 'nav-group-name'
            }`}
          >
            {this.props.groups[this.props.parent_id]?.name}
          </div>
          <div />
          <div className="chevronBtn" onClick={this.handleToggleMenu}>
            <i className={`bi bi-chevron-${this.props.currentGroup?.isMenuOpen ? 'up' : 'down'}`} />
          </div>
        </div>
        <Collapse in={this.props.currentGroup?.isMenuOpen}>
          <div>
            {this.props.currentGroup?.isMenuOpen && (
              <>
                {this.props.sortedGroups
                  ?.filter((group) => group.parent_id == this.props.parent_id)
                  .map((group) =>
                    group.hasOwnProperty('plc_id') ? (
                      <NavPLCGroup
                        key={group.id.toString()}
                        padLeft={this.props.padLeft + 15}
                        currentGroup={group}
                        parent_id={group.id}
                        groups={this.props.groups}
                        plc_id={group.plc_id}
                        onGroupClick={this.props.onGroupClick}
                        isManage
                      />
                    ) : (
                      <NavAddressGroupManage
                        padLeft={this.props.padLeft + 15}
                        key={group.id.toString()}
                        isUserAdmin={getFeatureFlag('ui_manage_plcs')}
                        currentGroup={this.props.groups[group.id]}
                        ui={this.props.ui}
                        sortedGroups={this.props.sortedGroups}
                        parent_id={group.id}
                        groups={this.props.groups}
                        onGroupClick={this.props.onGroupClick}
                        toggleMenu={this.props.toggleMenu}
                        closeMenuChild={this.props.closeMenuChild}
                        openMenu={this.props.openMenu}
                        menuSearchText={this.props.ui.menuSearchText}
                      />
                    )
                  )}
                {this.props.isUserAdmin ? (
                  <ToggleFeatures
                    feature="ui_manage_groups"
                    // on={<AddGroup padLeft={this.props.padLeft + 15} parent_id={this.props.parent_id} />}
                    on={<AddGroupModal padLeft={this.props.padLeft + 15} isBigBtn parentId={this.props.parent_id} />}
                    off={<></>}
                  />
                ) : (
                  <div />
                )}
              </>
            )}
          </div>
        </Collapse>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  ui: state.ui,
  groups: state.groups,
  events: state.events,
  user: getUser(state),
})

const mapDispatchToProps = (dispatch) => ({
  removeGroup: (group_id) => {
    dispatch(uiActionsNew.setClickedGroup({ id: null }))
    dispatch(groupsActions.removeGroup(group_id))
  },
  addPLCGroup: (group) => dispatch(plcsActions.addPLCGroup(group)),
  toggleMenu: (group) => dispatch(groupsActions.toggleMenu(group.id)),
  closeMenuChild: (group) => dispatch(groupsActions.closeMenuChild(group.id)),
  openMenu: (group) => dispatch(groupsActions.openMenu(group.id)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavAddressGroupManage)
