import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const getPLCs = (state: RootState) => state.plcs

export const getPLSById = createSelector([getPLCs, (state: RootState, id: number) => id], (plcs, id: number) => plcs[id])
export const getPlcScheduleById = createSelector(
  [getPLCs, (state: RootState, id: number) => id],
  (plcs, id: number) => plcs[id]?.shedule
)
export const getPLSByServerId = createSelector([getPLCs, (state: RootState, id: number) => id], (plcs, id: number) => plcs[id])
