import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Controller } from '../../../types/controller'
import { Light } from '../../../types/light'
import { ServerGroups } from '../../../types/serverGroups'
import { Schedule } from '../../../types/schedule'

interface PlcsSchema {
  [key: number]: Controller
}

const initialState: PlcsSchema = {}

const plcsSlice = createSlice({
  name: 'plcsSlice',
  initialState,
  reducers: {
    changePLCPower: (state, { payload }: PayloadAction<{ id: number; power: number }>) => {
      state[payload.id].power = payload.power
    },
    changePLCLightGroupPower: (state, { payload }: PayloadAction<{ id: number; lightGroupId: number; power: number }>) => {
      state[payload.id].serverGroups.forEach((gr) => {
        if (gr.id === payload.lightGroupId) {
          gr.power = payload.power
        }
      })
    },
    removeLightFromPLC: (state, { payload }: PayloadAction<{ id: number; light: Light; serverGroupRemoveFlag: boolean }>) => {
      state[payload.id].light_ids.splice(state[payload.id].light_ids.indexOf(payload.light.id), 1)
      if (payload.serverGroupRemoveFlag) {
        const serverGroups = state[payload.id].serverGroups.filter((group) => group.id == payload.light.serverGroup)
        state[payload.id].serverGroups.splice(state[payload.id].serverGroups.indexOf(serverGroups[0]), 1)
      }
    },
    addLightToPLC: (state, { payload }: PayloadAction<{ id: number; light: Light }>) => {
      state[payload.id].light_ids.push(payload.light.lampID)
      if (state[payload.id].serverGroups.filter((group) => group.id == payload.light.serverGroup).length === 0) {
        state[payload.id].serverGroups.push({ id: payload.light.serverGroup, power: 0 })
      }
    },
    editPLC: (state, { payload }: PayloadAction<Controller>) => {
      state[payload.id] = payload
    },
    editPLCScriptNumber: (state, { payload }: PayloadAction<{ id: number; scriptNumber: number }>) => {
      if (state[payload.id]) {
        state[payload.id].scriptNumber = payload.scriptNumber
      }
    },
    editPLCServerGroup: (state, { payload }: PayloadAction<{ id: number; serverGroup: ServerGroups }>) => {
      if (state[payload.id] && !state[payload.id]?.serverGroups?.some((group) => group.id === payload.serverGroup.id)) {
        state[payload.id].serverGroups.push(payload.serverGroup)
        state[payload.id].serverGroups.sort((a, b) => {
          if (a.id > b.id) {
            return 1
          }

          return -1
        })
      }
    },
    editPLCSchedule: (state, { payload }: PayloadAction<{ id: number; schedule: Schedule }>) => {
      if (state[payload.id]) {
        state[payload.id].shedule = payload.schedule
      }
    },
    addPLC: (state, { payload }: PayloadAction<Controller>) => {
      state[payload.id] = payload
    },
    addPLCs: (state, { payload }: PayloadAction<Controller[]>) => {
      const newState = { ...state }
      payload.forEach((plc) =>
        Object.defineProperty(newState, plc.id, {
          enumerable: true,
          value: plc,
        })
      )

      return newState
    },
  },
})

export const { actions: plcsActions, reducer: plcsReducer } = plcsSlice
