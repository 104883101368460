import { Suspense } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { VerifyEmail } from '../pages/VerifyEmailPage'

const PrivateRoute = ({ component: Component }) => {
  const location = useLocation()

  if (location.pathname === '/verify_email') {
    return (
      <Suspense fallback="">
        <VerifyEmail />
      </Suspense>
    )
  }

  return localStorage.getItem('user') ? <Component location={location} /> : <Navigate to="/login" />
}

export default PrivateRoute
