import { memo, useCallback, useMemo } from 'react'
import { Event } from '../../../types/event'
import { HStack, VStack } from '../../UI/Stack'
import { Status, StatusTheme } from '../../UI/Status'
import { Text, TextSize, TextTheme } from '../../UI/Text'
import { formatDt } from '../../../utils/functions/formatDt'
import { useAppSelector } from '../../../store/hooks'
import { getGroups } from '../../../store/slices/groups/groupsSelector'
import { Group } from '../../../types/group'

interface NotificationItemProps {
  event: Event
}

export const NotificationItem = memo((props: NotificationItemProps) => {
  const { event } = props
  const groups = useAppSelector((state) => getGroups(state)) as Group[]

  const groupName = useMemo(() => {
    const currentGroup = Object.values(groups).find((f) => f.id === event.group_id)

    if (currentGroup.plc_id) {
      return Object.values(groups).find((f) => f.id === currentGroup.parent_id)?.name || ''
    }

    return currentGroup.name
  }, [groups, event.group_id])

  const renderStatus = useCallback(() => {
    switch (event.status) {
      case 'alarm':
        return <Status theme={StatusTheme.ALARM} />
      case 'warning':
        return <Status theme={StatusTheme.WARNING} />
      case 'work':
        return <Status theme={StatusTheme.WORK} />
      default:
        return null
    }
  }, [event.status])

  return (
    <HStack max gap="16" align="start">
      {renderStatus()}
      <HStack gap="8">
        <VStack>
          <Text size={TextSize.S} text={event.time} />
          <Text
            size={TextSize.S}
            text={event.date === formatDt(Date.now() / 1000, true) ? 'Сегодня' : event.date}
            theme={TextTheme.LIGHT}
          />
        </VStack>
        <VStack>
          <HStack gap="4">
            <Text size={TextSize.S} text={groupName} />
            <Text size={TextSize.S} text={event.obj_name} />
          </HStack>
          <Text size={TextSize.S} text={event.name} theme={TextTheme.LIGHT} />
        </VStack>
      </HStack>
    </HStack>
  )
})
