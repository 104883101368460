import React, { memo } from 'react'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import cls from './Icon.module.scss'

interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string
  Svg: React.VFC<React.SVGProps<SVGSVGElement>>
  inverted?: boolean
  defaultColor?: boolean
}

export const Icon = memo((props: IconProps) => {
  const { className, Svg, inverted, defaultColor = true, ...otherProps } = props

  const mods: Mods = {
    [cls.defaultColor]: defaultColor,
    [cls.inverted]: inverted,
  }

  return <Svg className={classNames(inverted ? cls.inverted : cls.Icon, mods, [className])} {...otherProps} />
})
