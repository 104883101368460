import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { Group } from '../../../types/group'

export const getGroups = (state: RootState) => state.groups as Group[]

export const getGroupById = createSelector([getGroups, (state: RootState, id: number) => id], (groups, id: number) => groups[id])

export const getGroupByPlcId = createSelector([getGroups, (state: RootState, id: number) => id], (groups, id: number) =>
  Object.values(groups).find((f) => f.plc_id === id)
)
