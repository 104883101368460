import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Light } from '../../../types/light'

interface PlcReview {
  isSuccess?: boolean
  isLoading?: boolean
  error?: string
  lights?: Light[]
  plcId?: number
}

interface PlcReviewSchema {
  [key: number]: PlcReview
}

const initialState: PlcReviewSchema = {}

const plcReviewSlice = createSlice({
  name: 'plcReviewSlice',
  initialState,
  reducers: {
    addReview: (state, { payload }: PayloadAction<PlcReview>) => {
      state[payload.plcId] = payload
    },
    changeReviewIsLoading: (state, { payload }: PayloadAction<{ plcId: number; isLoading: boolean }>) => {
      state[payload.plcId].isLoading = payload.isLoading
    },
    changeReviewError: (state, { payload }: PayloadAction<{ plcId: number; error: string }>) => {
      state[payload.plcId].error = payload.error
    },
    changeReviewIsSuccess: (state, { payload }: PayloadAction<{ plcId: number; isSuccess: boolean }>) => {
      state[payload.plcId].isSuccess = payload.isSuccess
    },
    addReviewLights: (state, { payload }: PayloadAction<{ plcId: number; light: Light }>) => {
      state[payload.plcId].lights = state[payload.plcId].lights
        ? state[payload.plcId].lights.concat(payload.light)
        : [payload.light]
    },
    deleteReviewLights: (state, { payload }: PayloadAction<{ plcId: number; lightName: string }>) => {
      state[payload.plcId].lights = state[payload.plcId].lights?.filter((f) => f.ID !== payload.lightName)
    },
  },
})

export const { actions: plcReviewActions, reducer: plcReviewReducer } = plcReviewSlice
