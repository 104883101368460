import { HTMLAttributes, memo, ReactNode } from 'react'
import { ConnectableElement } from 'react-dnd/dist/types'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { classNames } from '../../../utils/classNames/classNames'
import cls from './Card.module.scss'

export enum CardTheme {
  NORMAL = 'normal',
  OUTLINED = 'outlined',
  ACTIVE = 'active',
}

interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * дополнительный класс, переданный из вне
   */
  className?: string
  /**
   * содержимое карточки
   */
  children: ReactNode
  /**
   * Тема карточки
   */
  theme?: CardTheme
  /**
   * Увеличивает ширину карточки на всю свободную ширину
   */
  max?: boolean
  /**
   * Кастомный ref для навешивания на карточку элемента управления переносом (drag and drop)
   */
  customRef?: (node: ConnectableElement) => void
}

export const Card = memo((props: CardProps) => {
  const { className, customRef, children, theme = CardTheme.NORMAL, max, ...otherProps } = props

  return (
    <DndProvider backend={HTML5Backend}>
      <div ref={customRef} className={classNames(cls.Card, { [cls.max]: max }, [className, cls[theme]])} {...otherProps}>
        {children}
      </div>
    </DndProvider>
  )
})
