import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, ButtonTheme } from '../../../../components/UI/Button'
import { Icon } from '../../../../components/UI/Icon'
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg'
import { Group } from '../../../../types/group'
import { Input } from '../../../../components/UI/Input'
import { socket } from '../../../../utils'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { RootState } from '../../../../store/store'
import { groupsActions } from '../../../../store/slices/groups/groupsSlice'

interface EditGroupModalProps {
  currentGroup: Group
}

export const EditGroupModal = (props: EditGroupModalProps) => {
  const { currentGroup } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const groupId = useAppSelector(uiSelector.getClickedGroupId)
  const groups = useAppSelector((state: RootState) => state.groups as Group[])
  const dispatch = useAppDispatch()

  const toggleModal = useCallback(() => {
    setIsOpen((prev) => {
      if (!prev) {
        setName(currentGroup.name)
      }

      return !prev
    })
  }, [currentGroup.name])

  const changeName = useCallback((value: string) => {
    setName(value)
  }, [])

  const onSubmit = useCallback(() => {
    socket?.emit('/groups/edit', { groupId, name }, (data: undefined) => {
      console.log(data)
    })
    const editGr = structuredClone(groups[groupId])
    editGr.name = name
    dispatch(groupsActions.editGroup(editGr))
    toggleModal()
  }, [groupId, name, groups, dispatch, toggleModal])

  return (
    <>
      <Button theme={ButtonTheme.CLEAR} onClick={toggleModal}>
        <Icon width="18" height="18" Svg={EditIcon} />
      </Button>

      <Modal show={isOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Переименовать группу</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input value={name} label="Название" placeholder="Введите название" onChange={changeName} />
        </Modal.Body>
        <Modal.Footer>
          <Button theme={ButtonTheme.BACKGROUND} onClick={onSubmit}>
            Переименовать
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
