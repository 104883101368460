import React, { memo, useMemo } from 'react'
import { HStack, VStack } from '../../../../components/UI/Stack'
import { Text, TextSize, TextTheme } from '../../../../components/UI/Text'
import { Card, CardTheme } from '../../../../components/UI/Card'
import { CardElement } from '../../../../types/logicGroup'
import { classNames } from '../../../../utils/classNames/classNames'
import { Slider } from '../../../../components/UI/Slider'
import { VerticalCard } from '../../../../components/UI/VerticalCard'
import cls from './ControlParamsCard.module.scss'

interface LogicGroupCardProps {
  className?: string
  title?: string
  values: CardElement[]
  type: 'horizontal' | 'vertical'
  isWrap?: boolean
  isSlide?: boolean
}

export const ControlParamsCard = memo((props: LogicGroupCardProps) => {
  const { title, values, type = 'horizontal', className, isWrap = false, isSlide = false } = props

  const content = useMemo(() => {
    return (
      <HStack gap="8" justify="start" className={classNames(cls.cardWrapper, { [cls[type]]: true, [cls.isWrap]: isWrap })} max>
        {values.map((el) => {
          return type === 'horizontal' ? (
            <Card key={el.name} theme={CardTheme.OUTLINED} className={cls.card}>
              <HStack max justify="between">
                <Text text={el.name} size={TextSize.S} theme={TextTheme.LIGHT} />
                {el.value}
              </HStack>
            </Card>
          ) : (
            <VerticalCard key={el.name} title={el.name} content={el.value} isActive={el.isActive} />
          )
        })}
      </HStack>
    )
  }, [isWrap, type, values])

  return (
    <VStack gap="4" max className={classNames('', {}, [className])}>
      {title && <Text size={TextSize.S} text={title} theme={TextTheme.LIGHT} />}
      {isSlide ? <Slider>{content}</Slider> : content}
    </VStack>
  )
})
