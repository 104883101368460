import { memo, useCallback, useMemo, useState } from 'react'
import { classNames } from '../../../../utils/classNames/classNames'
import { Card, CardTheme } from '../../../../components/UI/Card'
import { HStack, VStack } from '../../../../components/UI/Stack'
import { Text, TextSize, TextTheme } from '../../../../components/UI/Text'
import { Controller } from '../../../../types/controller'
import { config } from '../../../../config'
import { Button, ButtonTheme } from '../../../../components/UI/Button'
import ManagePLSServerDocs from '../../../../components/manage/ManagePLSServerDocs'
import { ToggleFeatures } from '../../../../utils/features'

interface ControlDocsProps {
  className?: string
  plc: Controller
}

export const ControlDocs = memo((props: ControlDocsProps) => {
  const { className, plc } = props
  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const openModal = useCallback(() => {
    setIsShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsShowModal(false)
  }, [])

  const files = useMemo(() => {
    if (plc.serverDocs?.length) {
      return plc.serverDocs?.map((doc) => (
        <div key={doc.id} className="SD_file" onClick={() => window.open(`${config.apiUrl}/map/${doc.path}`, '_blank')}>
          <span>
            <i className="bi bi-file-earmark" />
            <span className="SD_file__title">{doc.title ? doc.title : 'Описание документа отсутствует'}</span>
          </span>
        </div>
      ))
    }

    return (
      <HStack justify="center" max>
        <Text text="Документация отсутствует" theme={TextTheme.LIGHT} />
      </HStack>
    )
  }, [plc.serverDocs])

  return (
    <Card className={classNames('', {}, [className])} theme={CardTheme.OUTLINED} max>
      <VStack gap="12">
        <Text title="Документация" size={TextSize.S} />
        <ToggleFeatures feature="ui_manage_plc_docs" on={<>{files}</>} off={<></>} />
        <ToggleFeatures
          feature="ui_manage_plc_docs_enable"
          on={
            <HStack justify="center" max>
              <Button theme={ButtonTheme.OUTLINE} onClick={openModal}>
                <i className="bi bi-upload mr-8" />
                Загрузить документ
              </Button>
              <ManagePLSServerDocs isShow={isShowModal} handleClose={closeModal} plc={plc} />
            </HStack>
          }
          off={<></>}
        />
      </VStack>
    </Card>
  )
})
