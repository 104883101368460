import React from 'react'
import { VStack } from '../../../../components/UI/Stack'
import { Text } from '../../../../components/UI/Text'
import { ControlInfoType, RowInfo } from '../../../../components/UI/RowInfo'
import { useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { getPLSById } from '../../../../store/slices/plcs/plcsSelector'
import { channelTypes, gsmTypes } from '../../../../consts/common'
import { formatDt } from '../../../../utils/functions/formatDt'
import { Card, CardTheme } from '../../../../components/UI/Card'

export const PLSConnection = () => {
  const plcId = useAppSelector(uiSelector.getClickedPlcId)
  const currentPlc = useAppSelector((state) => getPLSById(state, plcId))

  return (
    <Card theme={CardTheme.OUTLINED} max>
      <VStack>
        <Text text="Общие сведения" isBold />
        <RowInfo
          type={ControlInfoType.TEXT}
          label="Канал связи"
          value={channelTypes[currentPlc?.connectionState] ? channelTypes[currentPlc?.connectionState] : 'отсутствует'}
          isBorder={false}
        />
        <RowInfo
          type={ControlInfoType.TEXT}
          label="Качество GSM связи"
          value={`${
            currentPlc.gsmConnectionQuality ?? currentPlc.gsmConnectionQuality === 0 ? currentPlc.gsmConnectionQuality : ' --- '
          }% ${gsmTypes[currentPlc.gsmType] ? gsmTypes[currentPlc.gsmType] : ' --- '}`}
          isBorder={false}
        />
        <RowInfo
          type={ControlInfoType.TEXT}
          label="Последнее обновление"
          value={formatDt(currentPlc.currentTime)}
          isBorder={false}
        />
      </VStack>
    </Card>
  )
}
