import { MDBIcon } from 'mdb-react-ui-kit'
import React, { ChangeEvent, useState } from 'react'
import cls from './NavLightPanel.module.scss'
import { useAppSelector } from '../../../store/hooks'
import { getLightById, getLightsByIds } from '../../../store/slices/lights/lightsSelector'
import { Light } from '../../../types/light'

interface NavLightPanelProps {
  padLeft: number
  allLightsIds: number[]
  currentLightsIds: number[]
  onChange: (ids: number[], type?: 'none') => void
}

export const NavLightPanel = (props: NavLightPanelProps) => {
  const { padLeft, allLightsIds, currentLightsIds, onChange } = props
  const [searchValue, setSearchValue] = useState<string>('')
  const [idOrderDir, setIdOrderDir] = useState<string>('none')
  const [commentOrderDir, setCommentOrderDir] = useState<string>('none')
  const currentLights = useAppSelector((state) => getLightsByIds(state, currentLightsIds))

  const onChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)

    if (value.trim().length > 0) {
      const lightsIdsToShow: number[] = currentLights.reduce((acc: number[], light: Light) => {
        if (String(parseInt(light.lampRecID, 16)).indexOf(value) != -1 || light.comment.indexOf(value) != -1) {
          acc.push(light.id)
        }

        return acc
      }, [])
      onChange(lightsIdsToShow)
    } else {
      onChange(allLightsIds)
    }
  }

  const onChangeIdOrderDown = () => {
    setIdOrderDir((prev) => {
      if (prev === 'down') {
        onChange(currentLightsIds, 'none')

        return 'none'
      }
      const sortedLights = currentLights
        .sort((light1, light2) => {
          const lampRecID1 = parseInt(light1.lampRecID, 16).toString()
          const lampRecID2 = parseInt(light2.lampRecID, 16).toString()
          if (lampRecID1 == lampRecID2) return 0

          return lampRecID1 > lampRecID2 ? 1 : -1
        })
        .map((light) => light.id)

      onChange(sortedLights)

      return 'down'
    })
  }

  const onChangeIdOrderUp = () => {
    setIdOrderDir((prev) => {
      if (prev === 'up') {
        onChange(currentLightsIds, 'none')

        return 'none'
      }
      const sortedLights = currentLights
        .sort((light1, light2) => {
          const lampRecID1 = parseInt(light1.lampRecID, 16).toString()
          const lampRecID2 = parseInt(light2.lampRecID, 16).toString()
          if (lampRecID1 == lampRecID2) return 0

          return lampRecID1 < lampRecID2 ? 1 : -1
        })
        .map((light) => light.id)

      onChange(sortedLights)

      return 'up'
    })
  }

  const onChangeCommentOrderDown = () => {
    setCommentOrderDir((prev) => {
      if (prev === 'down') {
        onChange(currentLightsIds, 'none')

        return 'none'
      }
      const sortedLights = currentLights
        .sort((light1, light2) => {
          const lampRecID1 = light1.comment
          const lampRecID2 = light2.comment
          if (lampRecID1 == lampRecID2) return 0

          return lampRecID1 > lampRecID2 ? 1 : -1
        })
        .map((light) => light.id)

      onChange(sortedLights)

      return 'down'
    })
  }

  const onChangeCommentOrderUp = () => {
    setCommentOrderDir((prev) => {
      if (prev === 'up') {
        onChange(currentLightsIds, 'none')

        return 'none'
      }
      const sortedLights = currentLights
        .sort((light1, light2) => {
          const lampRecID1 = light1.comment
          const lampRecID2 = light2.comment
          if (lampRecID1 == lampRecID2) return 0

          return lampRecID1 < lampRecID2 ? 1 : -1
        })
        .map((light) => light.id)

      onChange(sortedLights)

      return 'up'
    })
  }

  return (
    <div className={cls.navGroupLights} style={{ paddingLeft: `${padLeft + 15}px` }}>
      <div className={cls.navGroupLightSearch}>
        <span className={cls.navLightOrderMarker}>
          <MDBIcon icon="search" size="x" />
        </span>
        <input
          type="text"
          style={{ width: '100%', height: '24px', paddingLeft: '20px', minWidth: '60px' }}
          value={searchValue}
          onChange={onChangeSearchValue}
        />
      </div>
      <div className="nav-group-lights-head-id">ID</div>
      <div className="nav-group-lights-head-id-up">
        <span
          className="navLightOrderMarker"
          onClick={onChangeIdOrderUp}
          style={{ color: idOrderDir === 'up' ? '#3882a7' : '#fff' }}
        >
          <MDBIcon icon="angle-up" size="x" />
        </span>
      </div>
      <div className="nav-group-lights-head-id-down">
        <span
          className="navLightOrderMarker"
          onClick={onChangeIdOrderDown}
          style={{ color: idOrderDir == 'down' ? '#3882a7' : '#fff' }}
        >
          <MDBIcon icon="angle-down" size="x" />
        </span>
      </div>
      <div className="nav-group-lights-head-comment">комментарий</div>
      <div className="nav-group-lights-head-comment-up">
        <span
          className="navLightOrderMarker"
          onClick={onChangeCommentOrderUp}
          style={{ color: commentOrderDir === 'up' ? '#3882a7' : '#fff' }}
        >
          <MDBIcon icon="angle-up" size="x" />
        </span>
      </div>
      <div className="nav-group-lights-head-comment-down">
        <span
          className="navLightOrderMarker"
          onClick={onChangeCommentOrderDown}
          style={{ color: commentOrderDir == 'down' ? '#3882a7' : '#fff' }}
        >
          <MDBIcon icon="angle-down" size="x" />
        </span>
      </div>
    </div>
  )
}
