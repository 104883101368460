import React, { useCallback } from 'react'
import Swal from 'sweetalert2'
import { Button, ButtonTheme } from '../../UI/Button'
import { Icon } from '../../UI/Icon'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-icon.svg'
import { socket } from '../../../utils'
import { useAppSelector } from '../../../store/hooks'
import { getLightById } from '../../../store/slices/lights/lightsSelector'

interface LightDeleteBtnProps {
  lightId: number
}

export const LightDeleteBtn = (props: LightDeleteBtnProps) => {
  const { lightId } = props
  const currentLight = useAppSelector((state) => getLightById(state, lightId))
  const removeLight = useCallback(() => {
    Swal.fire({
      title: 'Вы уверены?',
      text: `Данная операция удалит светильник из списка контроллера.`,
      icon: 'question',
      showLoaderOnConfirm: true,
      showCancelButton: true,
      cancelButtonColor: '#00b74a',
      cancelButtonText: 'Отменить',
      confirmButtonColor: '#f93154',
      confirmButtonText: 'Да!',
      reverseButtons: true,

      preConfirm: () => {
        socket?.emit('/groups/remove', {
          groupId: currentLight.groupId,
          unbindFromServer: false,
          query: { serverID: currentLight.serverID, lampID: currentLight.lampID },
        })
      },
    })
  }, [currentLight?.groupId, currentLight?.lampID, currentLight?.serverID])

  return (
    <Button theme={ButtonTheme.CLEAR} onClick={removeLight}>
      <Icon Svg={DeleteIcon} />
    </Button>
  )
}
