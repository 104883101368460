import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { config } from '../../../config'
import { Status } from '../../../types/status'
import { User } from '../../../types/user'

export interface IUsersState {
  status_get_users: Status
  status_save_user: Status
  status_change_user_role: Status
  users: User[]
  error: any
}

const initialState: IUsersState = {
  status_get_users: 'idle',
  status_save_user: 'idle',
  status_change_user_role: 'idle',
  users: [],
  error: '',
}

const getUsers = createAsyncThunk('users/get', async (body: { orgId: number }, { rejectWithValue }) => {
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  try {
    const response = await axios.get(`${config.apiUrl}/api/users/list/${body.orgId}`)

    return response.data.list
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return rejectWithValue(error?.response?.statusText || error?.message)
    }

    return rejectWithValue(error)
  }
})

const createSaveUser = createAsyncThunk(
  'user/save',
  async (body: { login: string; password: string; organisationId: number; roleId: number }, { rejectWithValue }) => {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    try {
      const response = await axios.post(`${config.apiUrl}/api/auth/register`, body)
      console.log(response.data)

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.statusText || error?.message)
      }

      return rejectWithValue(error)
    }
  }
)

const changeUserRole = createAsyncThunk(
  'user/changeRole',
  async (body: { userId: number; roleId: number }, { rejectWithValue }) => {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    try {
      const response = await axios.post(`${config.apiUrl}/api/users/changeRole`, body)
      console.log(response.data)

      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error?.response?.statusText || error?.message)
      }

      return rejectWithValue(error)
    }
  }
)

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = ''
    },
    resetGetUsersStatus: (state) => {
      state.status_get_users = 'idle'
    },
    resetSaveUserStatus: (state) => {
      state.status_save_user = 'idle'
    },
    resetChangeUserRoleStatus: (state) => {
      state.status_change_user_role = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.status_get_users = 'loading'
        state.error = ''
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status_get_users = 'error'
        state.error = action.payload
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.status_get_users = 'success'
        state.users = action.payload
        state.error = ''
      })

      .addCase(createSaveUser.pending, (state) => {
        state.status_save_user = 'loading'
        state.error = ''
      })
      .addCase(createSaveUser.rejected, (state, action) => {
        state.status_save_user = 'error'
        state.error = action.payload
      })
      .addCase(createSaveUser.fulfilled, (state) => {
        state.status_save_user = 'success'
        state.users = []
        state.error = ''
      })

      .addCase(changeUserRole.pending, (state) => {
        state.status_change_user_role = 'loading'
        state.error = ''
      })
      .addCase(changeUserRole.rejected, (state, action) => {
        state.status_change_user_role = 'error'
        state.error = action.payload
      })
      .addCase(changeUserRole.fulfilled, (state) => {
        state.status_change_user_role = 'success'
        state.error = ''
      })
  },
})

export const usersActions = { ...usersSlice.actions, getUsers, createSaveUser, changeUserRole }

export default usersSlice.reducer
