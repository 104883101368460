import axios from 'axios'
import { config } from '../../config'

export const $api = axios.create({
  baseURL: config.apiUrl,
})

$api.interceptors.request.use((config) => {
  if (config.headers) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.token) {
      config.headers.Authorization = `Bearer ${user.token}`
    }
    // @ts-ignore
    config.headers.common['Access-Control-Allow-Origin'] = '*'
  }

  return config
})
