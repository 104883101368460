/**
 * Created by a.osipov on 13.03.2020.
 */
const config = {
  apiUrl: process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.host}`,
  isRecon: window.location.origin === 'https://rekon.pandora-asu.ru',
}
console.log('🚀 ~ file: config.js apiUrl', config.apiUrl)
console.log('🚀 ~ file: config.js isRecon', config.isRecon)

export { config }
