import React, { ReactNode } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Card, CardTheme } from '../Card'
import { VStack } from '../Stack'
import { Text, TextAlign, TextSize } from '../Text'
import { classNames } from '../../../utils/classNames/classNames'
import cls from './VerticalCard.module.scss'

interface VerticalCardProps {
  title: string
  content: ReactNode
  isActive?: boolean
}

export const VerticalCard = (props: VerticalCardProps) => {
  const { title, content, isActive } = props

  return (
    <Card theme={isActive ? CardTheme.ACTIVE : CardTheme.OUTLINED} className={cls.verticalCard}>
      <VStack align="center">
        {title.length > 9 ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-control-entrance-name">{title}</Tooltip>}>
            <div className={classNames(cls.title, { [cls.active]: isActive })}>{title}</div>
          </OverlayTrigger>
        ) : (
          <Text
            align={TextAlign.CENTER}
            text={title}
            className={classNames(cls.title, { [cls.active]: isActive })}
            size={TextSize.S}
          />
        )}
        {content}
      </VStack>
    </Card>
  )
}
