import axios from 'axios'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import { config } from '../../config.js'
import { plcsActions } from '../../store/slices/plcs/plcsSlice'
import { getUser } from '../../store/slices/user/userSelectors'

class ManagePLSServerDocs extends React.Component {
  constructor(props) {
    super(props)
    this.handleDocChange = this.handleDocChange.bind(this)
    this.handleLoadDoc = this.handleLoadDoc.bind(this)
    this.state = {
      reportMessage: { color: 'black', message: '' },
      docs: this.props.plc.serverDocs,
      file: '',
      docPreviewUrl: '',
      docDescription: '',
      plc: this.props.plc,
      allowedExtensions: '.jpg, .jpeg, .png, .gif, .tiff, .tif, .bmp, .webp, .pdf, .doc, .docx, .xls, .xlsx',
      fileInputKey: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.plc !== prevProps.plc) this.setState({ plc: this.props.plc })
  }

  handleDocChange(e) {
    e.preventDefault()
    const file = e.target.files[0]

    if (!file) {
      this.setState({ file: '', imagePreviewUrl: '' })

      return
    }

    if (file.size > 5242880) {
      this.setState({ file: '', imagePreviewUrl: '', docDescription: '', fileInputKey: Date.now() })
      Swal.fire({
        title: 'Размер файла больше 5 мегабайт!',
        html: 'Загрузите файл размером 5 или меньше мегабайт!',
        icon: 'error',
        timer: 10000,
        timerProgressBar: true,
      })

      return
    }

    const hasInvalidFiles = !this.state.allowedExtensions.split(/\s*,\s*/).some((ext) => file?.name.endsWith(ext))
    const reader = new FileReader()
    if (hasInvalidFiles) {
      this.setState({ file: '', imagePreviewUrl: '', fileInputKey: Date.now() })

      Swal.fire({
        title: 'Неправильный тип файла!',
        html: `Допустимые типы файлов: <br/>${this.state.allowedExtensions}`,
        icon: 'error',
        timer: 10000,
        timerProgressBar: true,
      })
    } else {
      reader.onloadend = () => {
        this.setState({
          file,
          imagePreviewUrl: reader.result,
        })
      }
      reader.readAsDataURL(file)
    }
  }

  handleLoadDoc() {
    const formData = new FormData()
    formData.append('groupId', this.props.plc.id)
    formData.append('title', this.state.docDescription.trim())
    formData.append('docFile', this.state.file)
    axios
      .post(`${config.apiUrl}/api/groups/updateDoc`, formData, {
        headers: {
          Authorization: `Bearer ${this.props.user.token}`,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          Swal.fire({
            title: 'Ошибка!',
            html: `Ошибка добавления документации`,
            icon: 'error',
            timer: 10000,
            timerProgressBar: true,
          })
        }
        // обновляем документацию для контроллера
        this.props.editPLC({
          ...this.props.plc,
          serverDocs: this.props.plc.serverDocs.concat([
            { id: response.data.id, title: response.data.title, path: response.data.path, serverId: this.props.plc.id },
          ]),
        })
        this.setState({ reportMessage: { color: 'green', message: 'Файл документации добавлен в базу данных' } })
      })
      .catch((err) => {
        Swal.fire({
          title: 'Ошибка!',
          html: `Ошибка добавления документации <br/><br/> ${err}`,
          icon: 'error',
          timer: 10000,
          timerProgressBar: true,
        })
      })
    this.setState({ file: '', imagePreviewUrl: '', docDescription: '', fileInputKey: Date.now() })
  }

  render() {
    let $imagePreview = null
    if (this.state.imagePreviewUrl != '') {
      $imagePreview = <img src={this.state.imagePreviewUrl} />
    }

    return (
      <Modal show={this.props.isShow} onHide={this.props.handleClose} dialogClassName="modalSD" scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Документация на контроллер</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          {this.state.plc.serverDocs?.length != 0 ? (
            this.state.plc.serverDocs?.map((doc) => (
              <div key={doc.id}>
                <span className="SD_file" onClick={() => window.open(`${apiUrl}/map/${doc.path}`, '_blank')}>
                  <MDBIcon icon="file" size="lg" />
                </span>
                {doc.title ? doc.title : 'Описание документа отсутствует'}
              </div>
            ))
          ) : (
            <div />
          )}
        </Modal.Body> */}
        <Modal.Footer style={{ flexDirection: 'column', alignItems: 'normal' }}>
          <Form style={{ marginTop: '25px' }}>
            <h5 style={{ textAlign: 'center', marginBottom: '15px' }}>Добавить новый документ</h5>
            <Form.Group controlId="inputDesc" className="mb-3">
              <Form.Label>Описание документа</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => {
                  this.setState({ docDescription: e.target.value })
                }}
                value={this.state.docDescription}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3" style={{ marginTop: '10px' }}>
              <Form.Label>Файл</Form.Label>
              <Form.Control
                type="file"
                onChange={this.handleDocChange}
                accept={this.state.allowedExtensions}
                key={this.state.fileInputKey}
              />
            </Form.Group>

            <Button
              variant="outline-primary"
              onClick={this.handleLoadDoc}
              size="sm"
              disabled={!(this.state.docDescription.trim() && this.state.file)}
            >
              Добавить
            </Button>
          </Form>
          <div style={{ color: this.state.reportMessage.color, width: '100%' }}>{this.state.reportMessage.message}</div>
        </Modal.Footer>
      </Modal>
    )
  }
}
const mapStateToProps = (state) => ({
  user: getUser(state),
})

const mapDispatchToProps = (dispatch) => ({
  editPLC: (plc) => dispatch(plcsActions.editPLC(plc)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManagePLSServerDocs)
