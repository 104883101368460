/* eslint-disable no-unsafe-optional-chaining */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getFeatureFlag } from '../../utils/features'

const channelTypes = {
  1: 'GSM',
  2: 'ETHERNET',
}

const PLSMnemo = (props) => {
  const [showServerDocs, setShowServerDocs] = useState(false)

  let pos = [true, false, false, false]
  if (props.plc?.controlEntrance.length != 0) {
    if (
      !props.plc?.controlEntrance[0]?.hasVoltage &&
      !props.plc?.controlEntrance[1]?.hasVoltage &&
      !props.plc?.controlEntrance[2]?.hasVoltage
    )
      pos = [true, false, false, false]
    if (
      props.plc?.controlEntrance[0]?.hasVoltage &&
      !props.plc?.controlEntrance[1]?.hasVoltage &&
      !props.plc?.controlEntrance[2]?.hasVoltage
    )
      pos = [false, true, false, false]
    if (
      !props.plc?.controlEntrance[0]?.hasVoltage &&
      props.plc?.controlEntrance[1]?.hasVoltage &&
      !props.plc?.controlEntrance[2]?.hasVoltage
    )
      pos = [false, false, true, false]
    if (
      !props.plc?.controlEntrance[0]?.hasVoltage &&
      !props.plc?.controlEntrance[1]?.hasVoltage &&
      props.plc?.controlEntrance[2]?.hasVoltage
    )
      pos = [false, false, false, true]
  }
  const connectionQuality =
    props.plc?.gsmConnectionQuality == 0
      ? 0
      : props.plc?.gsmConnectionQuality <= 25
      ? 25
      : props.plc?.gsmConnectionQuality <= 50
      ? 50
      : props.plc?.gsmConnectionQuality <= 75
      ? 75
      : 100

  const isOnceRele = !['1000000222', '10000004d5'].includes(props.plc?.serverRecID)

  return (
    <Modal show={props.isShow} onHide={props.handleClose} dialogClassName="modalMnemo">
      <Modal.Header closeButton>
        <Modal.Title>Мнемосхема</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mnemo_container">
          <div className={isOnceRele ? 'mnemo_back_once' : 'mnemo_back'} />
          <div className="mnemo_back1" />

          <div className="voltage l1">L1 {props.plc?.voltageOnPhase?.l1 || '-'} В</div>
          <div className="voltage l2">L2 {props.plc?.voltageOnPhase?.l2 || '-'} В</div>
          <div className="voltage l3">L3 {props.plc?.voltageOnPhase?.l3 || '-'} В</div>
          {isOnceRele && <div className="voltage batt">12 В</div>}

          <div className="meter p1">
            {props.plc?.electricityMeterInfo?.activePowerByPhase1 !== undefined
              ? (props.plc?.electricityMeterInfo?.activePowerByPhase1 / 100000).toFixed(3)
              : '---'}
          </div>
          <div className="meter p2">
            {props.plc?.electricityMeterInfo?.activePowerByPhase2 !== undefined
              ? (props.plc?.electricityMeterInfo?.activePowerByPhase2 / 100000).toFixed(3)
              : '---'}
          </div>
          <div className="meter p3">
            {props.plc?.electricityMeterInfo?.activePowerByPhase3 !== undefined
              ? (props.plc?.electricityMeterInfo?.activePowerByPhase3 / 100000).toFixed(3)
              : '---'}
          </div>
          <div className="meter psum">
            {props.plc?.electricityMeterInfo?.activeEnergyByTarifSum !== undefined
              ? props.plc?.electricityMeterInfo?.activeEnergyByTarifSum / 1000
              : '---'}
          </div>

          {pos[0] && <div className="mnemo_switch pos0" />}
          {pos[0] ? <div className="switch_title_pos0_on">OFF</div> : <div className="switch_title_pos0_off">OFF</div>}
          {pos[1] && <div className="mnemo_switch pos1" />}
          {pos[1] ? <div className="switch_title_pos1_on">AUTO</div> : <div className="switch_title_pos1_off">AUTO</div>}
          {pos[2] && <div className="mnemo_switch pos2" />}
          {pos[3] && <div className="mnemo_switch pos3" />}
          {pos[3] ? <div className="switch_title_pos3_on">ON</div> : <div className="switch_title_pos3_off">ON</div>}

          {props.plc?.externalEntrance[0]?.currentSetup && <div className="mnemo_door open" />}
          {!props.plc?.externalEntrance[0]?.currentSetup && <div className="mnemo_door closed" />}

          {props.plc?.externalEntrance[0]?.currentSetup && <div className="mnemo_signal signal_door red" />}
          {!props.plc?.externalEntrance[0]?.currentSetup && <div className="mnemo_signal signal_door green" />}

          {props.plc?.relay[0]?.currentSetup && (
            <div className={`mnemo_signal ${isOnceRele ? 'signal_rele_once' : 'signal_rele_1'} green`} />
          )}
          {!props.plc?.relay[0]?.currentSetup && (
            <div className={`mnemo_signal ${isOnceRele ? 'signal_rele_once' : 'signal_rele_1'} gray`} />
          )}

          {props.plc?.relay[1]?.currentSetup && (
            <div className={`mnemo_signal ${isOnceRele ? 'signal_rele_once' : 'signal_rele_2'} green`} />
          )}
          {!props.plc?.relay[1]?.currentSetup && (
            <div className={`mnemo_signal ${isOnceRele ? 'signal_rele_once' : 'signal_rele_2'} gray`} />
          )}

          {isOnceRele && <div className="mnemo_signal signal_battery green" />}

          <div className="mnemo_antenna ant1" />
          <div className={`mnemo_antenna_level level1 signal_${connectionQuality}`} />
          <div className="mnemo_antenna ant2" />
          <div className={`mnemo_antenna_level level2 signal_${connectionQuality}`} />

          {props.plc.connectionState == 2 ? <div className="ethernet_on" /> : <div className="ethernet_off" />}

          {props.plc?.controlEntrance[3]?.hasVoltage && <div className="mnemo_signal signal_auto4 green" />}
          {!props.plc?.controlEntrance[3]?.hasVoltage && <div className="mnemo_signal signal_auto4 gray" />}

          {props.plc?.controlEntrance[4]?.hasVoltage && <div className="mnemo_signal signal_auto5 green" />}
          {!props.plc?.controlEntrance[4]?.hasVoltage && <div className="mnemo_signal signal_auto5 gray" />}

          {props.plc?.controlEntrance[5]?.hasVoltage && <div className="mnemo_signal signal_auto6 green" />}
          {!props.plc?.controlEntrance[5]?.hasVoltage && <div className="mnemo_signal signal_auto6 gray" />}

          {props.plc?.controlEntrance[6]?.hasVoltage && <div className="mnemo_signal signal_auto7 green" />}
          {!props.plc?.controlEntrance[6]?.hasVoltage && <div className="mnemo_signal signal_auto7 gray" />}

          {props.plc?.controlEntrance[7]?.hasVoltage && <div className="mnemo_signal signal_auto8 green" />}
          {!props.plc?.controlEntrance[7]?.hasVoltage && <div className="mnemo_signal signal_auto8 gray" />}

          {props.plc?.controlEntrance[8]?.hasVoltage && <div className="mnemo_signal signal_auto9 green" />}
          {!props.plc?.controlEntrance[8]?.hasVoltage && <div className="mnemo_signal signal_auto9 gray" />}

          {!props.plc?.relay[0] && <div className="mnemo_rele_offline offline" />}

          {!props.plc?.relay[0]?.currentSetup && (
            <div
              className={`mnemo_rele ${isOnceRele ? 'once' : 'first'} ${
                !getFeatureFlag('ui_manage_outputs') && 'disabled'
              } rele_off`}
              onClick={() => {
                props.changeReleState(1, 1)
              }}
            />
          )}
          {props.plc?.relay[0]?.currentSetup && (
            <div
              className={`mnemo_rele ${isOnceRele ? 'once' : 'first'} ${
                !getFeatureFlag('ui_manage_outputs') && 'disabled'
              } rele_on`}
              onClick={() => {
                props.changeReleState(0, 1)
              }}
            />
          )}

          {!isOnceRele && !props.plc?.relay[1]?.currentSetup && (
            <div
              className="mnemo_rele second rele_off"
              onClick={() => {
                props.changeReleState(1, 2)
              }}
            />
          )}
          {!isOnceRele && props.plc?.relay[1]?.currentSetup && (
            <div
              className="mnemo_rele second rele_on"
              onClick={() => {
                props.changeReleState(0, 2)
              }}
            />
          )}
          <div className="info_mnemo_rele">
            <div className="info_mnemo_rele_block">
              <div className="rele_on info" />
              <div className="info_text">Включено</div>
            </div>
            <div className="info_mnemo_rele_block">
              <div className="rele_off info" />
              <div className="info_text">Выключено</div>
            </div>
            {/* <div className="info_mnemo_rele_block"> */}
            {/*  <div className="offline info" /> */}
            {/*  <div className="info_text">Нет информации</div> */}
            {/* </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PLSMnemo
