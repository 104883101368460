import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { Filter } from '../../../types/filter'

export type IFilter = 'alarm' | 'warning' | 'work'

export interface IUiState {
  filter: Filter[]
  clicked_group_id: number
  clicked_plc_id: number
  clicked_light_id: number
  filter_start_date: number
  filter_finish_date: number
  filter_current_page: number
  events_total_count: number
  menuSearchText: string
  isLoadingFilters: boolean
  isShowMessage: boolean
  errorFiltersText: string
  changedEventsId: number[]
  checkedEventsIds: number[]
  isLoadingGroups: boolean
}

function filterHandler(filter: IFilter) {
  let resultFilter: Array<IFilter> = ['alarm']

  switch (filter) {
    case 'warning':
      return (resultFilter = ['alarm', 'warning'])
    case 'work':
      return (resultFilter = ['alarm', 'warning', 'work'])
    default:
      return resultFilter
  }
}

const s = new Date()
const f = new Date()
s.setHours(0, 0)
s.setDate(s.getDate() - 1)
f.setHours(23, 59)

const initialState: IUiState = {
  filter: ['alarm', 'warning', 'work'],
  clicked_group_id: null,
  clicked_plc_id: null,
  clicked_light_id: null,
  filter_start_date: moment(s).utc(false).unix(),
  filter_finish_date: moment(f).utc(false).unix(),
  filter_current_page: 1,
  events_total_count: 0,
  menuSearchText: '',
  isLoadingFilters: false,
  isShowMessage: false,
  errorFiltersText: '',
  changedEventsId: [],
  checkedEventsIds: [],
  isLoadingGroups: true,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<{ filter: IFilter }>) => {
      state.filter = filterHandler(action.payload.filter)
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filter = action.payload
    },
    setFilterParams: (state, action: PayloadAction<{ startDate: number; finishDate: number; currentPage: number }>) => {
      state.filter_start_date = action.payload.startDate
      state.filter_finish_date = action.payload.finishDate
      state.filter_current_page = action.payload.currentPage
    },
    setClickedGroup: (state, action: PayloadAction<{ id: number }>) => {
      state.clicked_group_id = action.payload.id
    },
    setClickedPLC: (state, action: PayloadAction<{ id: number }>) => {
      state.clicked_plc_id = action.payload.id
    },
    setClickedLight: (state, action: PayloadAction<{ id: number }>) => {
      state.clicked_light_id = action.payload.id
    },
    setEventsTotalCount: (state, action: PayloadAction<{ count: number }>) => {
      state.events_total_count = action.payload.count
    },
    changeMenuSearchText: (state, action: PayloadAction<{ searchText: string }>) => {
      state.menuSearchText = action.payload.searchText
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.filter_current_page = payload
    },
    setIsLoadingFilter: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingFilters = payload
    },
    setChangedEventsId: (state, { payload }: PayloadAction<number[]>) => {
      state.changedEventsId = payload
    },
    setIsShowMessage: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowMessage = payload
    },
    setError: (state, { payload }: PayloadAction<string>) => {
      state.errorFiltersText = payload
    },
    setCheckedEventsId: (state, { payload }: PayloadAction<{ checked: boolean; id: number }>) => {
      const newCheckedArr = [...state.checkedEventsIds]
      if (payload.checked) {
        newCheckedArr.push(payload.id)
        state.checkedEventsIds = newCheckedArr
      } else {
        state.checkedEventsIds = newCheckedArr.filter((id) => id !== payload.id)
      }
    },
    setCheckedEventsIds: (state, { payload }: PayloadAction<{ checked: boolean; ids: number[] }>) => {
      if (payload.checked) {
        state.checkedEventsIds = payload.ids
      } else {
        state.checkedEventsIds = []
      }
    },
    setIsLoadingGroups: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGroups = action.payload
    },
  },
})

export const uiActions = { ...uiSlice.actions }

export default uiSlice.reducer
