import React, { memo, useState } from 'react'
import { Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { NavbarItemType } from '../../../types/navbar'
import { Icon } from '../../UI/Icon'
import cls from './NavBarItem.module.scss'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import { config } from '../../../config'

interface NavBarItemProps {
  item: NavbarItemType
}

export const NavBarItem = memo(({ item }: NavBarItemProps) => {
  const [isActiveLink, setIsActiveLink] = useState(false)

  const mods: Mods = {
    [cls.active]: isActiveLink,
    [cls.isRecon]: config.isRecon,
  }

  return (
    <Nav.Item className={`my-3 ${cls.NavBarItem}`}>
      <NavLink
        to={item.path}
        className={({ isActive }) => {
          setTimeout(() => setIsActiveLink(isActive), 0)
          // setIsActiveLink(isActive)

          return isActive ? 'activeMenuLink' : ''
        }}
      >
        <Icon className={classNames(cls.icon, mods)} inverted={isActiveLink} Svg={item.Icon} />
        <div className={classNames(cls.title, mods)}>{item.title}</div>
      </NavLink>
    </Nav.Item>
  )
})
