/**
 * Created by a.osipov on 12.02.2020.
 */

const plc_groups = (state = {}, action) => {
  switch (action.type) {
    case 'CHANGE_PLC_GROUP_POWER':
      const changedGroup = state[action.plc_group_id]
      changedGroup.power = action.value

      return { ...state, ...changedGroup }

    case 'ADD_PLC_SERVER_GROUP':
      let newServerGroup = state[action.group.plc_id]
      if (newServerGroup == undefined) newServerGroup = {}
      newServerGroup.power = action.group.power
      newServerGroup.serverGroup_id = action.group.serverGroup_id

      return { ...state, ...newServerGroup }

    default:
      return state
  }
}
export default plc_groups
