import { toast } from '@guilds/pandora-react-ui'
import { rtkApi } from './rtkApi'
import { socket } from '../socket-service'
import { RootState } from '../../store/store'
import { uiActions } from '../../store/actions'
import { Group } from '../../types/group'
import { Controller } from '../../types/controller'
import { eventsActions } from '../../store/slices/events/eventsSlice'
import { Light } from '../../types/light'
import { uiActions as uiActionsNew } from '../../store/slices/ui/uiSlice'
import { Event } from '../../types/event'
import { plsScriptsActions } from '../../store/actions/pls_scripts_actions'
import { Script } from '../../types/script'
import { Diagram } from '../../types/diagram'
import { Coordinates } from '../../types/coordinates'
import { groupsActions } from '../../store/slices/groups/groupsSlice'
import { lightsActions } from '../../store/slices/lights/lightsSlice'
import { plcsActions } from '../../store/slices/plcs/plcsSlice'
import { ElectricityMeter } from '../../types/electricityMeter'
import { plcReviewActions } from '../../store/slices/plcReview/plcReviewSlice'
import { userActions } from '../../store/slices/user/userSlice'

export const wsApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    subscribeToEvents: build.query<any, void>({
      queryFn: () => ({ data: [] }),
      async onCacheEntryAdded(_arg, { dispatch, updateCachedData, cacheEntryRemoved, getState }) {
        const state = getState() as RootState
        const { user, auth } = state

        if (!auth?.authData?.token) {
          dispatch(userActions.logout())
        }

        if (user.companyId && auth?.authData?.token) {
          socket?.emit('/groups/list', { groupId: user.companyId })
        }

        // socket?.on('/plc/server/info', (data) => {
        //   console.log(data)
        // })

        socket?.on('/plc/server/review', (data: { query: { plcId: number }; statusCode: number; error?: string }) => {
          // dispatch(plcReviewActions.addReview({ plcId: data.query.plcId }))
          const { plcId } = data.query
          const { error, statusCode } = data
          if (error) {
            dispatch(plcReviewActions.changeReviewIsLoading({ plcId, isLoading: false }))
            dispatch(plcReviewActions.changeReviewError({ plcId, error }))
          }
          if (statusCode === 33) {
            dispatch(plcReviewActions.changeReviewIsLoading({ plcId, isLoading: false }))
            dispatch(plcReviewActions.changeReviewError({ plcId, error: 'Контроллер находится в нерабочем режиме' }))
          }
          if (statusCode === 0) {
            dispatch(plcReviewActions.changeReviewIsLoading({ plcId, isLoading: false }))
            dispatch(plcReviewActions.changeReviewError({ plcId, error: 'Контроллер не найден' }))
          }
          if (statusCode === 201) {
            dispatch(plcReviewActions.addReviewLights({ plcId, light: data as unknown as Light }))
          }
          if (statusCode === 202) {
            dispatch(plcReviewActions.changeReviewIsLoading({ plcId, isLoading: false }))
            dispatch(plcReviewActions.changeReviewIsSuccess({ plcId, isSuccess: true }))
          }
        })

        socket?.on(
          '/groups/edit',
          (data: {
            statusCode: number
            query: { lampID: any; serverID: number }
            serverGroup: number
            coordinates: any
            comment: any
          }) => {
            if (data.statusCode == 200) {
              const state = getState() as RootState
              const plcs = state.plcs as Controller[]
              if (data.query.lampID && data.query.serverID) {
                dispatch(
                  lightsActions.changeLight({
                    id: data.query.lampID,
                    coordinates: data.coordinates,
                    serverGroup: data.serverGroup,
                    comment: data.comment ?? '',
                  } as Light)
                )
                dispatch(
                  plcsActions.editPLCServerGroup({ id: data.query.serverID, serverGroup: { id: data.serverGroup, power: 0 } })
                )
              }
              if (data.query.serverID && data.query.lampID === null) {
                dispatch(plcsActions.editPLC({ ...plcs[data.query.serverID], coordinates: data.coordinates }))
              }
            }
          }
        )

        socket?.on(
          '/plc/lamps/ir/set',
          (data: {
            statusCode: number
            query: { lampID: number; activePower: number; standbyPower: number; timeout: number }
          }) => {
            if (data.statusCode == 200) {
              dispatch(
                lightsActions.changeLightIRParams({
                  id: data.query.lampID,
                  value: {
                    activePower: parseInt(String(data.query.activePower / 100)),
                    standbyPower: parseInt(String(data.query.standbyPower / 100)),
                    timeout: data.query.timeout,
                  },
                })
              )
            }
          }
        )

        socket?.on('/plc/lamps/ir/on', (data: { statusCode: number; query: { lampID: any } }) => {
          if (data.statusCode == 200) {
            dispatch(lightsActions.changeLight({ id: data.query.lampID, irModeOn: true } as Light))
          }
        })

        socket?.on('/plc/lamps/ir/off', (data: { statusCode: number; query: { lampID: any } }) => {
          if (data.statusCode == 200) {
            dispatch(lightsActions.changeLight({ id: data.query.lampID, irModeOn: false } as Light))
          }
        })

        socket?.on('/groups/remove', (data: { query: { lampID: number; serverID: number }; statusCode: number }) => {
          if (data.query.lampID && data.query.serverID && data.statusCode == 200) {
            const state = getState() as RootState
            const { lights, ui } = state
            const plcs = state.plcs as Controller[]
            dispatch(uiActionsNew.setClickedGroup({ id: ui.clicked_group_id }))
            dispatch(uiActionsNew.setClickedPLC({ id: ui.clicked_plc_id }))
            dispatch(uiActionsNew.setClickedLight({ id: null }))
            dispatch(uiActionsNew.setEventsTotalCount({ count: 0 }))
            dispatch(
              plcsActions.removeLightFromPLC({
                id: data.query.serverID,
                light: lights[data.query.lampID],
                serverGroupRemoveFlag:
                  plcs[data.query.serverID].light_ids.filter((light_id) => lights[light_id]?.id == lights[data.query.lampID]?.id)
                    .length == 1,
              })
            )
            dispatch(lightsActions.removeLight(data.query.lampID))
          }
        })

        socket?.on(
          '/plc/lamps/power/set',
          (data: {
            statusCode: number
            query: {
              addressType: number
              lampID: any
              plcId: number
              groupNumber: number
              power: number
              lightsIds: number[]
              lampRecID: string
            }
            power: number
          }) => {
            if (data.statusCode == 200) {
              const power = Math.ceil(data.power * 100)
              let message = ''
              if (data.query.addressType === 1) {
                data.query.lightsIds.forEach((id) => {
                  dispatch(
                    lightsActions.changeLightPower({
                      id,
                      power: Math.ceil(data.power * 100),
                    } as Light)
                  )
                })
                message = `Уровень диммирования всех светильников установлен в ${power}%`
              }
              if (data.query.addressType === 2) {
                dispatch(
                  lightsActions.changeLightPower({
                    id: data.query.lampID,
                    power: Math.ceil(data.power * 100),
                  } as Light)
                )
                message = `Уровень диммирования светильника ${data.query.lampRecID} установлен в ${power}%`
              }
              if (data.query.addressType === 3) {
                dispatch(
                  plcsActions.changePLCLightGroupPower({
                    id: data.query.plcId,
                    lightGroupId: data.query.groupNumber,
                    power: data.query.power,
                  })
                )
                dispatch(
                  lightsActions.changeLightPowerByGroup({
                    plcId: data.query.plcId,
                    serverGroup: data.query.groupNumber,
                    power: data.query.power,
                  })
                )
                message = `Уровень диммирования светильников подгруппы установлен в ${power}%`
              }
              toast.warning(power !== 255 ? message : 'Команда на изменение приоритета управления выполнена')
            } else {
              console.log('/plc/lamps/power/set error statusCode: ', data.statusCode)
            }
          }
        )

        socket?.on(
          '/plc/lamps/info',
          (data: {
            statusCode: number
            query: { id: any }
            states: {
              power: string
              events: any
            }[]
            group: any
          }) => {
            if (data.statusCode == 200) {
              dispatch(
                lightsActions.changeLight({
                  id: data.query.id,
                  power: parseInt(data.states[0].power),
                  serverGroup: data.group,
                  states: [{ ...data.states[0], events: { ...data.states[0].events, failAnswer: false } }],
                } as unknown as Light)
              )
            } else {
              dispatch(lightsActions.changeLight({ id: data.query.id, states: [{ events: { failAnswer: true } }] } as Light))
            }
          }
        )

        socket?.on(
          '/plc/lamps/ir/get',
          (data: {
            statusCode: number
            query: { lampID: any }
            irModeOn: any
            activePower: string
            standbyPower: string
            timeout: any
          }) => {
            if (data.statusCode == 200) {
              console.log('/plc/lamps/ir/get data: ', data)
              dispatch(
                lightsActions.changeLight({
                  id: data.query.lampID,
                  enableIRMode: true,
                  irModeOn: data.irModeOn,
                  activePower: parseInt(String(parseFloat(data.activePower) * 100)),
                  standbyPower: parseInt(String(parseFloat(data.standbyPower) * 100)),
                  timeout: data.timeout,
                } as Light)
              )
            } else {
              console.log('/plc/lamps/ir/get error statusCode: ', data.statusCode)
            }
          }
        )

        socket?.on('/groups/list', (data: { statusCode: number; groups: any[] }) => {
          if (data.statusCode == 401) {
            dispatch(userActions.logout())
          } else if (data.statusCode == 200) {
            const { logicGroups, lights, plcGroups } = data.groups.reduce(
              (acc, group) => {
                if (group.type === 3) {
                  acc.logicGroups.push({
                    id: group.groupId,
                    name: group.name,
                    parent_id: group.branch_id === user.companyId ? 'root' : group.branch_id,
                    status: 'work',
                    alarm_count: 0,
                    warning_count: 0,
                  })
                }

                if (group.type === 2) {
                  acc.lights.push({
                    ...group,
                    power: parseInt(String(group.power * 100)),
                    activePower: parseInt(String(parseFloat(group?.activePower) * 100)),
                    standbyPower: parseInt(String(parseFloat(group?.standbyPower) * 100)),
                    enableIRMode: group.enableIRMode,
                    irModeOn: group.irModeOn,
                    timeout: parseInt(group.timeout),
                  })
                }

                if (group.type === 1) {
                  acc.plcGroups.push({ ...group })
                  acc.logicGroups.push({
                    id: group.groupId,
                    name: group.name,
                    parent_id: group.branch_id == user.companyId ? 'root' : group.branch_id,
                    plc_id: group.serverID,
                    isGeo: group.isGeo,
                    geoFileURL: group.geoFileURL ? group.geoFileURL : '',
                    status: 'work',
                    alarm_count: 0,
                    warning_count: 0,
                  })
                }

                return acc
              },
              { logicGroups: [], lights: [], plcGroups: [] }
            )

            dispatch(lightsActions.addLights(lights))
            dispatch(groupsActions.addGroups(logicGroups))

            let serverGroups: number[]
            // let groupLights

            const plcs: Controller[] = []
            plcGroups.forEach(
              (group: {
                groupId: any
                controlEntranceNames: string
                controlEntrance: any[]
                serverID: any
                serverRecID: any
              }) => {
                serverGroups = []
                const groupLights = lights.reduce((acc: Light[], val: Light) => {
                  if (val.branch_id == group.groupId) {
                    acc.push(val)
                    if (serverGroups.indexOf(parseInt(String(val.serverGroup))) == -1) {
                      serverGroups.push(parseInt(String(val.serverGroup)))
                      // this.props.addPLCServerGroup({
                      //   plc_id: group.serverID,
                      //   serverGroup_id: light.serverGroup,
                      //   power: 0,
                      // })
                    }
                  }

                  return acc
                }, [])
                serverGroups.sort((a, b) => a - b)
                let cen = group.controlEntranceNames.split(',')
                if (cen.length == 1) cen = group.controlEntrance.map((ce, i) => (i + 1).toString())
                plcs.push({
                  id: group.serverID,
                  name: group.serverRecID,
                  light_ids: groupLights.map((light: Light) => light.lampID),
                  status: 'work',
                  power: 0,
                  real: true,
                  serverGroups: serverGroups.map((id) => ({ id, power: 0 })),
                  ...group,
                  controlEntranceNames: cen,
                } as unknown as Controller)

                socket?.emit('/plc/server/scripts/list', {
                  groupId: group.groupId,
                  query: { groupId: group.groupId, serverID: group.serverID },
                })

                socket?.emit('/diagrams/list', {
                  groupId: group.groupId,
                  query: { groupId: group.groupId, serverID: group.serverID },
                })

                socket?.emit('/plc/server/schedule/get', {
                  groupId: group.groupId,
                  query: { groupId: group.groupId, serverID: group.serverID },
                })
              }
            )

            dispatch(plcsActions.addPLCs(plcs))

            const { ui } = getState() as RootState

            socket?.emit('/events/list', {
              groupId: user.companyId,
              from: ui.filter_start_date,
              to: ui.filter_finish_date,
              eventFilters: ['status-alarm', 'status-warning'],
              query: { groupId: user.companyId, type: 'notification' },
            })

            // dispatch(
            //   eventsActions.updateEvents({
            //     groupId: user.companyId,
            //     from: ui.filter_start_date,
            //     to: ui.filter_finish_date,
            //     count: 20,
            //   })
            // )
            // dispatch(
            //   eventsActions.updateEventsStatus({
            //     groupId: user.companyId,
            //     from: moment().utc(false).startOf('d').subtract(1, 'd').unix(),
            //     to: moment().utc(false).endOf('d').unix(),
            //   })
            // )
          }
          dispatch(uiActionsNew.setIsLoadingGroups(false))
        })

        socket?.on(
          '/plc/server/schedule/get',
          (data: { statusCode: number; query: { serverID: number }; base: any; override: any }) => {
            if (data.statusCode == 200) {
              const state = getState() as RootState
              const plcs = state.plcs as Controller[]
              dispatch(
                plcsActions.editPLC({
                  ...plcs[data.query.serverID],
                  shedule: { base: data.base, override: data.override },
                })
              )
            }
          }
        )

        socket?.on(
          '/plc/event',
          (data: {
            statusCode: number
            serverId: number
            appearTime: number
            type: number
            subtype: number
            groupId: number
          }) => {
            if (data.statusCode == 200) {
              const state = getState() as RootState
              const plcs = state.plcs as Controller[]
              const { groups, ui } = state
              // проверка на то, что пришедшее событие относится к данной организации
              // введено до исправления серверной части
              if (
                Object.keys(plcs)
                  .map((id) => parseInt(id))
                  .indexOf(data.serverId) != -1
              ) {
                // проверка на добавление в store для отображения в компоненте MainEvents
                if (
                  data.appearTime >= ui.filter_start_date &&
                  data.appearTime <= ui.filter_finish_date &&
                  ui.filter_current_page == 1
                ) {
                  console.log('add', { ...data, plc_id: data.serverId, groupId: plcs[data.serverId].groupId })
                  dispatch(
                    eventsActions.addEvent({
                      ...data,
                      plc_id: data.serverId,
                      groupId: plcs[data.serverId].groupId,
                      eventComments: [],
                      obj_name: Object.values(groups).find((group) => group.plc_id == data.serverId)?.name,
                    } as unknown as Event)
                  )
                }
                if (data.type === 16 && (data.subtype === 7 || data.subtype === 8)) {
                  socket?.emit('/plc/server/scripts/current', {
                    groupId: data.groupId,
                    query: {
                      groupId: data.groupId,
                      plcId: data.serverId,
                    },
                  })
                }
              }
            }
          }
        )

        socket?.on('/plc/objects/list/count', (data: { count: number; query: { groupId: any } }) => {
          if (data.count != 0) {
            socket?.emit('/plc/objects/list', {
              groupId: data.query.groupId,
              offset: 0,
              limit: data.count,
              query: data.query,
            })
          } else {
            // костыль. При добавлении контроллера не загружаются параметры. Вызываем повторно весь список, чтобы их получить.
            socket?.emit('/groups/list', { groupId: user.companyId })
          }
        })

        socket?.on('/plc/objects/list', (data: { query: { action: string; groupId: any }; objects: any[] }) => {
          if (data.query.action == 'add_lights') {
            const lights = data.objects.filter((el: { ID: number }) => el.ID !== undefined)
            lights.forEach(
              (v: { ID: number; serverID: number; group: any; coordinate: { latitude: number; longtitude: number } }) => {
                socket?.emit('/groups/add', {
                  root: data.query.groupId,
                  name: v.ID,
                  isAlreadyInList: 1,
                  plc: {
                    id: v.ID,
                    serverID: v.serverID,
                    group: v.group,
                    coordinates: { latitude: v.coordinate.latitude, longtitude: v.coordinate.longtitude },
                    modulation: 5,
                  },
                })
              }
            )
            // костыль. При добавлении контроллера не загружаются параметры. Вызываем повторно весь список, чтобы их получить.
            socket?.emit('/groups/list', { groupId: user.companyId })
          }
        })

        socket?.on(
          '/plc/server/scripts/list',
          (data: { statusCode: number; scripts: Script[]; query: { groupId: any; serverID: any } }) => {
            if (data.statusCode == 200) {
              const newScripts = data.scripts.map((script) => ({
                id: script.id,
                groupId: data.query.groupId,
                plc_id: data.query.serverID,
                serverScriptNumber: script.serverScriptNumber,
                name: script.name,
                commands: script.commands,
              }))
              dispatch(plsScriptsActions.addPLSScripts(newScripts))
              // data.scripts.forEach((script) => {
              //   plsScriptsActions.addPLSScript({
              //           id: script.id,
              //           groupId: data.query.groupId,
              //           plc_id: data.query.serverID,
              //           serverScriptNumber: script.serverScriptNumber,
              //           name: script.name,
              //           commands: script.commands,
              //         })
              //       })
            }
          }
        )

        socket?.on('/plc/diagram/list/count', (data: { count: number; query: { groupId: any } }) => {
          if (data.count != 0)
            socket?.emit('/plc/diagram/list', {
              groupId: data.query.groupId,
              offset: 0,
              limit: data.count,
              query: data.query,
            })
        })

        socket?.on(
          '/plc/diagram/list',
          (data: { statusCode: number; count: number; diagrams: Diagram[]; query: { groupId: number } }) => {
            if (data.statusCode == 200) {
              if (data.count != 0)
                data.diagrams.forEach((diag: Diagram) => {
                  socket?.emit('/diagrams/add', {
                    ...diag,
                    name: diag.number.toString(),
                    groupId: data.query.groupId,
                    query: data.query,
                  })
                })
            }
          }
        )

        socket?.on('/diagrams/add', (data: { statusCode: number; query: number }) => {
          if (data.statusCode == 200) {
            const newDiag = { ...data }
            delete newDiag.statusCode
            delete newDiag.query
            dispatch(uiActions.addPLSDiagram(newDiag))
          }
        })

        socket?.on(
          '/diagrams/list',
          (data: { statusCode: number; count: number; diagrams: Diagram[]; query: { serverID: number } }) => {
            if (data.statusCode == 200 && data.count != 0) {
              const newDiagrams = data.diagrams.map((diag: Diagram) => ({
                ...diag,
                serverID: data.query.serverID,
              }))
              dispatch(uiActions.addPLSDiagrams(newDiagrams))
            }
          }
        )

        socket?.on('/plc/server/relay/get', (data: { statusCode: number; query: { pls_id: number } }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            if (plcs[data.query.pls_id]) {
              const changedPLS = { ...plcs[data.query.pls_id], ...data }
              delete changedPLS.statusCode
              delete changedPLS.query
              dispatch(plcsActions.editPLC({ ...changedPLS, ...data }))
            }
          }
        })

        socket?.on('/plc/server/relay/set', (data: { statusCode: number; query: { pls_id: number } }) => {
          if (data.statusCode == 200) {
            const { groups } = getState() as RootState
            socket?.emit('/plc/server/relay/get', {
              groupId: Object.values(groups).find((gr) => gr.plc_id == data.query.pls_id).id,
              query: { pls_id: data.query.pls_id, showConfirm: true },
            })
          }
        })

        socket?.on('/plc/server/schedule/on', (data: { statusCode: number; query: { pls_id: number } }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            const { groups } = state
            const changedPLS = plcs[data.query.pls_id]
            dispatch(plcsActions.editPLC({ ...changedPLS, workingAlgorithm: changedPLS?.workingAlgorithm == 1 ? 0 : 1 }))

            socket?.emit('/plc/server/relay/get', {
              groupId: Object.values(groups).find((gr) => gr.plc_id == data.query.pls_id).id,
              query: { pls_id: data.query.pls_id, showConfirm: false },
            })
          }
        })

        socket?.on('/plc/server/schedule/off', (data: { statusCode: number; query: { pls_id: number } }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            const { groups } = state
            const changedPLS = plcs[data.query.pls_id]
            dispatch(plcsActions.editPLC({ ...changedPLS, workingAlgorithm: changedPLS?.workingAlgorithm == 1 ? 0 : 1 }))

            socket?.emit('/plc/server/relay/get', {
              groupId: Object.values(groups).find((gr) => gr.plc_id == data.query.pls_id).id,
              query: { pls_id: data.query.pls_id, showConfirm: false },
            })
          }
        })

        socket?.on('/server/relay/info', (data: { statusCode: number; serverRecID: string; query: any }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            const changedPLS = Object.values(plcs).find((plc) => plc.serverRecID == data.serverRecID)
            if (changedPLS) {
              delete data.statusCode
              delete data.query
              dispatch(plcsActions.editPLC({ ...changedPLS, ...data }))
            }
          }
        })

        socket?.on('/server/state/extended', (data: { statusCode: number; serverRecID: string; query: any }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            const changedPLS = Object.values(plcs).find((plc) => plc.serverRecID == data.serverRecID)
            if (changedPLS) {
              delete data.statusCode
              delete data.query
              dispatch(plcsActions.editPLC({ ...changedPLS, ...data }))
            }
          }
        })

        socket?.on('/server/electricity', (data: { statusCode: number; serverRecID: string; query: any }) => {
          if (data.statusCode == 200) {
            const state = getState() as RootState
            const plcs = state.plcs as Controller[]
            const changedPLS = Object.values(plcs).find((plc) => plc.serverRecID == data.serverRecID)
            if (changedPLS) {
              delete data.serverRecID
              delete data.statusCode
              delete data.query
              dispatch(plcsActions.editPLC({ ...changedPLS, electricityMeterInfo: data as ElectricityMeter }))
            }
          }
        })

        socket?.on('/lamp/statistic/info', (data: { statusCode: number; lampRecID: string; query: any }) => {
          if (data.statusCode == 200) {
            const { lights } = getState() as RootState
            const changedLight = Object.values(lights).find((light) => light.lampRecID == data.lampRecID)
            if (changedLight) {
              delete data.statusCode
              delete data.query
              dispatch(lightsActions.changeLight({ ...changedLight, ...data }))
            }
          }
        })

        socket?.on(
          '/plc/server/params/get',
          (data: {
            statusCode: number
            query: { serverID: number }
            control: string
            mac: string
            mode: string
            net: string
            ntp: string
            port: number
            smooth: string
            timeout: number
            coordinates: Coordinates
          }) => {
            if (data.statusCode == 200) {
              const state = getState() as RootState
              const plcs = state.plcs as Controller[]
              const changedPLC = plcs[data.query.serverID]
              if (changedPLC) {
                plcsActions.editPLC({
                  ...plcs[data.query.serverID],
                  control: data.control,
                  mac: data.mac,
                  mode: data.mode,
                  net: data.net,
                  ntp: data.ntp,
                  port: data.port,
                  smooth: data.smooth,
                  timeout: data.timeout,
                  coordinates: data.coordinates,
                })
              }
            }
          }
        )

        socket?.on(
          '/plc/server/scripts/current',
          (data: { statusCode: number; query: { plcId: number }; currentScript: number }) => {
            if (data.statusCode == 200) {
              dispatch(plcsActions.editPLCScriptNumber({ id: data.query.plcId, scriptNumber: data.currentScript }))
            }
          }
        )

        socket?.on(
          '/groups/add',
          (data: {
            statusCode: number
            type: number
            groupId: any
            name: any
            branch_id: number
            serverID: any
            serverRecID: any
            scriptNumber: number
            isAntennaPlugged: boolean
            isNeedReboot: boolean
            coordinates: { latitude: any; longtitude: any }
            lampID: any
            lampRecID: any
            serverGroup: any
            submitted: any
            power: string
            state: any
            lastConnected: any
            comment: any
            enableIRMode: any
            irModeOn: any
            activePower: string
            standbyPower: string
            timeout: string
            query?: { id: string; plcId?: number }
          }) => {
            if (data.statusCode == 200) {
              if (data.type == 3) {
                dispatch(
                  groupsActions.addGroup({
                    id: data.groupId,
                    name: data.name,
                    parent_id: data.branch_id == user.companyId ? 'root' : data.branch_id,
                    status: 'work',
                    alarm_count: 0,
                    warning_count: 0,
                    isMenuOpen: false,
                  } as Group)
                )
              }
              if (data.type == 1) {
                dispatch(
                  plcsActions.addPLC({
                    id: data.serverID,
                    name: data.serverRecID,
                    serverRecID: data.serverRecID,
                    scriptNumber: data.scriptNumber,
                    isAntennaPlugged: data.isAntennaPlugged,
                    isNeedReboot: data.isNeedReboot,
                    coordinates: data.coordinates,
                    serverDocs: [],
                    light_ids: [],
                    relay: [],
                    controlEntrance: [],
                    guardCircuit: [],
                    externalEntrance: [],
                    voltageOnEntrance: [],
                    status: 'work',
                    power: 0,
                    real: true,
                    serverGroups: [],
                  } as Controller)
                )
                dispatch(
                  groupsActions.addGroup({
                    id: data.groupId,
                    name: data.name,
                    parent_id: data.branch_id == user.companyId ? 'root' : data.branch_id,
                    plc_id: data.serverID,
                    status: 'work',
                    alarm_count: 0,
                    warning_count: 0,
                    isMenuOpen: false,
                  } as Group)
                )

                socket?.emit('/plc/objects/list/count', {
                  groupId: data.groupId,
                  query: { groupId: data.groupId, serverID: data.serverID, action: 'add_lights' },
                })

                socket?.emit('/plc/server/params/get', {
                  groupId: data.groupId,
                  parameter: 1,
                  query: { groupId: data.groupId, serverID: data.serverID },
                })

                socket?.emit('/plc/diagram/list/count', {
                  groupId: data.groupId,
                  query: { groupId: data.groupId, serverID: data.serverID },
                })

                socket?.emit('/plc/server/schedule/get', {
                  groupId: data.groupId,
                  query: { groupId: data.groupId, serverID: data.serverID },
                })
              }
              if (data.type == 2) {
                const light = {
                  groupId: data.groupId,
                  name: data.name,
                  lampID: data.lampID,
                  lampRecID: data.lampRecID,
                  serverID: data.serverID,
                  serverRecID: data.serverRecID,
                  serverGroup: data.serverGroup,
                  type: data.type,
                  submitted: data.submitted,
                  power: parseInt(data.power),
                  coord: [data.coordinates.latitude, data.coordinates.longtitude],
                  state: data.state,
                  lastConnected: data.lastConnected ? data.lastConnected : 0,
                  comment: data.comment,
                  coordinates: data.coordinates,
                  enableIRMode: data.enableIRMode,
                  irModeOn: data.irModeOn,
                  activePower: parseInt(String(parseFloat(data.activePower) * 100)),
                  standbyPower: parseInt(String(parseFloat(data.standbyPower) * 100)),
                  timeout: parseInt(data.timeout),
                } as unknown as Light
                dispatch(lightsActions.addLight(light))
                dispatch(plcsActions.addLightToPLC({ id: data.serverID, light }))

                if (data.query?.plcId) {
                  dispatch(plcReviewActions.deleteReviewLights({ plcId: data.query?.plcId, lightName: data.query?.id }))
                }

                // if(!this.props.plcs[data.serverID].serverGroups.find((group)=>group.id==data.serverGroup))
                //     this.props.editPLC({...this.props.plcs[data.serverID], serverGroups: this.props.plcs[data.serverID].serverGroups.concat([{id:data.serverGroup, power:0}])})
              }
            } else {
              console.log('group add error')
            }
          }
        )
        socket?.on('/users/settings', (data: { statusCode: number; query: any; settings: any }) => {
          if (data.statusCode == 200) {
            dispatch(userActions.saveUser(data.settings))
          }
        })

        await cacheEntryRemoved
        socket.close()
      },
    }),
  }),
  overrideExisting: false,
})

export const { useSubscribeToEventsQuery } = wsApi
