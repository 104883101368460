import { ButtonHTMLAttributes, forwardRef, memo, ReactNode, RefObject } from 'react'
import { ConnectableElement } from 'react-dnd'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import { Loader } from '../Loader'
import { HStack } from '../Stack'
import cls from './Button.module.scss'

export enum ButtonTheme {
  CLEAR = 'clear',
  CLEAR_INVERTED = 'clearInverted',
  OUTLINE = 'outline',
  OUTLINE_RED = 'outline_red',
  BACKGROUND = 'background',
  BACKGROUND_INVERTED = 'background_inverted',
}

export enum ButtonSize {
  M = 'size_m',
  L = 'size_l',
  XL = 'size_xl',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  /**
   * Тема кнопки. Отвечает за визуал (в рамке, без стилей, противоположный теме приложения цвет и тд)
   */
  theme?: ButtonTheme
  /**
   * Флаг, делающий кнопку квадратной
   */
  square?: boolean
  /**
   * Размер кнопки в соответствии с дизайн системой
   */
  size?: ButtonSize
  /**
   * Флаг, отвечающий за работу кнопки
   */
  disabled?: boolean
  /**
   * Содержимое кнопки
   */
  children?: ReactNode
  /**
   * Увеличивает кнопку на всю свободную ширину
   */
  fullWidth?: boolean
  /**
   * Кастомный ref для навешивания на кнопку элемента управления переносом (drag and drop)
   */
  customRef?: (node: ConnectableElement) => void

  /**
   * меняет курсор на grabbing (TO-DO не получилось реализовать из-за web api)
   */
  isGrabbing?: boolean

  /**
   * обрезает многоточием текст
   */
  isEllipsis?: boolean

  /**
   * состояние загрузки
   */
  isLoading?: boolean
}

export const Button = memo(
  forwardRef((props: ButtonProps, ref) => {
    const {
      className,
      children,
      theme = ButtonTheme.OUTLINE,
      square,
      disabled,
      fullWidth,
      size = ButtonSize.M,
      customRef,
      isGrabbing,
      isEllipsis,
      isLoading = false,
      ...otherProps
    } = props

    const mods: Mods = {
      [cls[theme]]: true,
      [cls.square]: square,
      [cls[size]]: true,
      [cls.disabled]: disabled || isLoading,
      [cls.fullWidth]: fullWidth,
      [cls.isGrabbing]: isGrabbing,
      [cls.ellipsis]: isEllipsis,
    }

    const modsWrapper: Mods = {
      [cls.fullWidth]: fullWidth,
      [cls.ellipsis]: isEllipsis,
    }

    return (
      <div className={classNames(cls.btnWrapper, modsWrapper)} ref={ref as RefObject<HTMLDivElement>}>
        <button
          ref={customRef}
          type="button"
          className={classNames(cls.Button, mods, [className])}
          disabled={disabled || isLoading}
          {...otherProps}
        >
          <HStack justify="around" max>
            {children}
            {isLoading && <Loader />}
          </HStack>
        </button>
      </div>
    )
  })
)
