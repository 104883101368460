import { memo } from 'react'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import cls from './Text.module.scss'

export enum TextTheme {
  PRIMARY = 'primary',
  INVERTED = 'inverted',
  ERROR = 'error',
  LIGHT = 'light',
}

export enum TextAlign {
  RIGHT = 'right',
  LEFT = 'left',
  CENTER = 'center',
}

export enum TextSize {
  XS = 'size_xs',
  S = 'size_s',
  M = 'size_m',
  L = 'size_l',
}

interface TextProps {
  className?: string
  title?: string
  text?: string
  theme?: TextTheme
  align?: TextAlign
  size?: TextSize
  isBold?: boolean
  /**
   * обрезает многоточием текст
   */
  isEllipsis?: boolean
}

type HeaderTagType = 'h1' | 'h2' | 'h3' | 'h4'

const mapSizeToHeaderTag: Record<TextSize, HeaderTagType> = {
  [TextSize.XS]: 'h4',
  [TextSize.S]: 'h3',
  [TextSize.M]: 'h2',
  [TextSize.L]: 'h1',
}

export const Text = memo((props: TextProps) => {
  const { className, text, title, theme, align = TextAlign.LEFT, size = TextSize.M, isBold, isEllipsis = false } = props
  const HeaderTag = mapSizeToHeaderTag[size]

  const mods: Mods = {
    [cls[theme]]: true,
    [cls[align]]: true,
    [cls[size]]: true,
    [cls.ellipsis]: isEllipsis,
  }

  return (
    <div className={classNames('', mods, [className])}>
      {title && <HeaderTag className={cls.title}>{title}</HeaderTag>}
      {text && <p className={classNames(cls.text, { [cls.bold]: isBold, [cls.ellipsis]: isEllipsis })}>{text}</p>}
    </div>
  )
})
