/**
 * Created by a.osipov on 30.10.2019.
 */

const initialState = {
  isFetching: false,
  companyId: 0,
  companyName: '',
}

const info = (state = initialState, action) => {
  switch (action.type) {
    case 'EDIT_INFO':
      return {
        ...state,
        isFetching: false,
        companyId: action.id,
        companyName: action.name,
      }

    case 'REQUEST_INFO':
      return {
        ...state,
        isFetching: true,
      }

    case 'RECEIVE_INFO':
      return {
        ...state,
        isFetching: false,
        companyId: action.data.id,
        companyName: action.data.name,
      }

    default:
      return state
  }
}

export default info
