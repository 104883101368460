import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Button, ButtonTheme } from '../../../../components/UI/Button'
import { Icon } from '../../../../components/UI/Icon'
import { Text } from '../../../../components/UI/Text'
import { Checkbox } from '../../../../components/UI/Checkbox'
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-icon.svg'
import { HStack } from '../../../../components/UI/Stack'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { socket } from '../../../../utils'
import { uiActions as uiActionsNew } from '../../../../store/slices/ui/uiSlice'
import { groupsActions } from '../../../../store/slices/groups/groupsSlice'

export const DeleteGroupModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isClearList, setIsClearList] = useState<boolean>(false)
  const groupId = useAppSelector(uiSelector.getClickedGroupId)
  const dispatch = useAppDispatch()

  const toggleModal = useCallback(() => setIsOpen((prev) => !prev), [])

  const onChangeCheckbox = useCallback(() => setIsClearList((prev) => !prev), [])

  const onSubmit = useCallback(() => {
    socket?.emit('/groups/remove', {
      groupId,
      clearList: isClearList,
      unbindFromServer: false,
    })
    dispatch(groupsActions.removeGroup(groupId))
    dispatch(uiActionsNew.setClickedGroup({ id: null }))
    dispatch(uiActionsNew.setClickedPLC({ id: null }))
    dispatch(uiActionsNew.setClickedLight({ id: null }))
  }, [dispatch, groupId, isClearList])

  return (
    <>
      <Button theme={ButtonTheme.CLEAR} onClick={toggleModal}>
        <Icon width="18" height="18" Svg={DeleteIcon} />
      </Button>

      <Modal show={isOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Удалить группу</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Text text="Удаление группы приведет к удалению всех подгрупп и привязанных к ней контроллеров" />
          <HStack gap="8">
            <Checkbox label="Очистить список контроллера" isChecked={isClearList} onChange={onChangeCheckbox} />
            {/* <Text text="Очистить список контроллера" /> */}
          </HStack>
        </Modal.Body>
        <Modal.Footer>
          <HStack gap="8">
            <Button theme={ButtonTheme.BACKGROUND} onClick={onSubmit}>
              Удалить
            </Button>
            <Button theme={ButtonTheme.BACKGROUND_INVERTED} onClick={toggleModal}>
              Не удалять
            </Button>
          </HStack>
        </Modal.Footer>
      </Modal>
    </>
  )
}
