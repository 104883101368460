import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Group } from '../../../types/group'
import { Notification } from '../../../types/notification'
import { eventTypes } from '../../../consts/common'
import { Event } from '../../../types/event'

const initialState: Notification = {
  alarmCount: 0,
  warningCount: 0,
  events: {},
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotificationEvent: (state, { payload }: PayloadAction<Event>) => {
      const new_event = {}
      if (eventTypes.hasOwnProperty(payload.type)) {
        const new_id = payload.eventID ?? payload.eventId
        let evt_name = eventTypes[payload.type][payload.subtype] || '[Неизвестный тип события]'
        evt_name += payload.type === 14 ? `. ID светильника ${parseInt(payload.lampID, 16)}` : ''

        Object.defineProperty(new_event, new_id, {
          enumerable: true,
          value: {
            id: new_id,
            name: evt_name,
            date: new Date(payload.appearTime * 1000).toLocaleString('ru', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            }),
            time: new Date(payload.appearTime * 1000).toLocaleString('ru', { hour: '2-digit', minute: '2-digit' }),
            status: payload.emergencyStatus == 2 ? 'alarm' : payload.emergencyStatus == 1 ? 'warning' : 'work',
            work_status: 'comment',
            lampID: payload.type === 14 ? payload.lampID : null,
            eventID: new_id,
            plc_id: payload.serverId,
            ...payload,
          },
        })
      }

      return { ...state, events: { ...state.events, ...new_event } }
    },
    setAlarmCount: (state, action: PayloadAction<Group[]>) => {
      state.alarmCount = action.payload.find((f) => !f.parent_id)?.alarm_count
    },
    setWarningCount: (state, action: PayloadAction<Group[]>) => {
      state.warningCount = action.payload.find((f) => !f.parent_id)?.warning_count
    },
  },
})

export const notificationActions = { ...notificationSlice.actions }
export default notificationSlice.reducer
