import { memo } from 'react'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import cls from './Tag.module.scss'
import { Text, TextSize, TextTheme } from '../Text/Text'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-icon.svg'
import { Icon } from '../Icon'
import { HStack } from '../Stack'

interface TagProps {
  className?: string
  label: string | number
  theme?: 'default' | 'lightDefault' | 'error' | 'warning'
  isBorder?: boolean
  onDelete?: () => void
}

export const Tag = memo((props: TagProps) => {
  const { className, label, theme = 'default', isBorder = false, onDelete } = props

  const mods: Mods = {
    [cls[theme]]: true,
    [cls.border]: isBorder,
  }

  return (
    <HStack className={classNames(cls.Tag, mods, [className])} gap="4">
      <Text text={label.toString()} size={TextSize.XS} theme={theme !== 'lightDefault' && TextTheme.INVERTED} />
      {onDelete && <Icon Svg={CloseIcon} className={cls.icon} onClick={onDelete} />}
    </HStack>
  )
})
