import React, { memo, useMemo } from 'react'
import { useAppSelector } from '../../../store/hooks'
import { RootState } from '../../../store/store'
import { Group } from '../../../types/group'
import { PageLoader } from '../../UI/PageLoader'
import { HStack } from '../../UI/Stack'
import { NavAddressGroup } from '../NavAddressGroup/NavAddressGroup'
import { classNames } from '../../../utils/classNames/classNames'
import NavAddressGroupManage from '../../NavAddressGroupManage'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import { ToggleFeatures } from '../../../utils/features'
import { AddGroupModal } from '../../../pages/ManagePage/ui/AddGroupModal/AddGroupModal'
import { getIsAscSort } from '../../../store/slices/user/userSelectors'
import cls from './RootHomeGroup.module.scss'

interface RootHomeGroupProps {
  onGroupClick: (group_id: number, plc_id: number, light_id: number) => void
  isFullHeight?: boolean
  isManage?: boolean
}

export const RootHomeGroup = memo((props: RootHomeGroupProps) => {
  const { onGroupClick, isFullHeight = false, isManage = false } = props
  const isAscSort = useAppSelector(getIsAscSort)
  const groups = useAppSelector((state: RootState) => state.groups) as Group[]
  const isLoadingGroups = useAppSelector(uiSelector.getIsLoadingGroups)

  const sortedGroups = useMemo(() => {
    return isAscSort
      ? Object.values(groups).sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
      : Object.values(groups)
  }, [groups, isAscSort])

  if (isLoadingGroups) {
    return (
      <HStack align="center" justify="center">
        <PageLoader />
      </HStack>
    )
  }

  return (
    <div className={classNames(cls.navGroups, { [cls.fullHeight]: isFullHeight })}>
      {sortedGroups?.map((item) => {
        return (
          item.parent_id == 'root' &&
          (isManage ? (
            <NavAddressGroupManage
              padLeft={15}
              key={item.id}
              isUserAdmin
              currentGroup={item}
              sortedGroups={sortedGroups}
              parent_id={item.id}
              onGroupClick={onGroupClick}
            />
          ) : (
            <NavAddressGroup
              padLeft={15}
              currentGroup={item}
              key={item.id}
              sortedGroups={sortedGroups}
              parent_id={item.id}
              onGroupClick={onGroupClick}
            />
          ))
        )
      })}
      {isManage && (
        <ToggleFeatures feature="ui_manage_groups" on={<AddGroupModal padLeft={15} parentId="root" isBigBtn />} off={<></>} />
      )}
    </div>
  )
})
