import { Middleware } from 'redux'
import { socket } from '../../../utils'
import { uiActions } from '../../actions'
import { uiActions as uiActionsNew } from '../ui/uiSlice'
import { Group } from '../../../types/group'
import { eventsActions } from './eventsSlice'
import { notificationActions } from '../notification/notificationSlice'
import { Filter } from '../../../types/filter'
import { groupsActions } from '../groups/groupsSlice'
import { lightsActions } from '../lights/lightsSlice'

const eventsMiddleware: Middleware = (store) => {
  return (next) => (action) => {
    if (eventsActions.getEvents.match(action)) {
      socket?.on('/events/list', (data: any) => {
        store.dispatch(uiActionsNew.setIsLoadingFilter(false))
        if (data.statusCode == 200) {
          const { plcs } = store.getState()
          const groups = store.getState().groups as Group[]
          data.events.forEach((evt: any) => {
            evt.plc_id = evt.serverId
            evt.group_id = plcs[evt.serverId].groupId
            evt.obj_name = Object.values(groups).find((group: Group) => group?.plc_id == evt.plc_id)?.name
          })
          if (data.query.type == 'notification') {
            data.events.forEach((evt: any) => {
              if (evt.emergencyStatus) store.dispatch(notificationActions.addNotificationEvent(evt))
            })
          } else {
            store.dispatch(eventsActions.addEvents(data.events))
            store.dispatch(uiActionsNew.setEventsTotalCount({ count: data.totalCount }))
          }
        }
        if (data.error) {
          store.dispatch(eventsActions.addEvents([]))
          store.dispatch(uiActionsNew.setEventsTotalCount({ count: 0 }))
        }
      })

      socket?.on('/plc/event', (data: any) => {
        if (data.statusCode == 200) {
          const { plcs } = store.getState()
          const groups = store.getState().groups as Group[]
          if (data.emergencyStatus) {
            store.dispatch(
              notificationActions.addNotificationEvent({
                ...data,
                plc_id: data.serverId,
                group_id: plcs[data.serverId].groupId,
                eventComments: [],
                obj_name: Object.values(groups).find((group: Group) => group.plc_id == data.serverId)?.name,
              })
            )
          }
        }
      })

      socket?.on('/events/status', (data: any) => {
        if (data.statusCode == 200) {
          store.dispatch(groupsActions.setGroupsStatus(data.groups))
          store.dispatch(lightsActions.setLightsStatus(data.groups.filter((gr: Group) => gr.groupType === 2)))
        }
      })

      socket?.on('/events/add', (data: any) => {
        if (data.statusCode === 200 && data.eventComments) {
          const { events } = store.getState()
          for (const comment of data.eventComments) {
            store.dispatch(
              eventsActions.setEventComments({
                id: comment.system_event.id,
                comments: [
                  ...events[comment.system_event.id].eventComments.filter((ec: { id: number }) => ec.id != comment.id),
                  comment,
                ],
              })
            )
          }
        }
        if (data.eventComments[0].status == 'order') {
          if (data.statusCode == 200 && !data.error) {
            store.dispatch(uiActionsNew.setIsShowMessage(true))
          } else {
            store.dispatch(uiActionsNew.setIsShowMessage(true))
            store.dispatch(uiActionsNew.setError(data.error))
          }
        }
      })
    }

    if (eventsActions.updateEvents.match(action)) {
      store.dispatch(uiActionsNew.setIsLoadingFilter(true))
      const params = structuredClone(action.payload)
      const eventFilters = {
        connection: 'connection-events',
        work: 'status-work',
        alarm: 'status-alarm',
        warning: 'status-warning',
        'order-opened': 'order-opened',
        'order-closed': 'order-closed',
      }
      const filter = store.getState().ui.filter as Filter[]

      const currentFilters = filter.map((f) => eventFilters[f])
      params.eventFilters = currentFilters
      if (params.query) {
        params.query.eventFilters = currentFilters
      }

      socket?.emit('/events/list', params)
    }

    if (eventsActions.updateEventsStatus.match(action)) {
      socket?.emit('/events/status', action.payload)
    }

    if (eventsActions.addEventParam.match(action)) {
      socket?.emit('/events/add', action.payload)
    }

    next(action)
  }
}

export default eventsMiddleware
