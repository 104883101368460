import React, { memo, useCallback, useMemo } from 'react'
import { RowInfo, ControlInfoType } from '../../../../../components/UI/RowInfo'
import { formatDt } from '../../../../../utils/functions/formatDt'
import { VStack } from '../../../../../components/UI/Stack'
import { Light } from '../../../../../types/light'
import { classNames } from '../../../../../utils/classNames/classNames'
import { Icon } from '../../../../../components/UI/Icon'
import { ReactComponent as ControllerIcon } from '../../../../../assets/icons/controller-icon.svg'
import { ReactComponent as ManualIcon } from '../../../../../assets/icons/manual-icon.svg'
import cls from './LightInfo.module.scss'

interface LightInfoProps {
  light: Light
  className?: string
  isFullInfo?: boolean
}

export const LightInfo = memo((props: LightInfoProps) => {
  const { light, className, isFullInfo = false } = props

  const connectionState = useCallback((value) => {
    switch (value) {
      case undefined:
        return 'опрос еще не проводился'
      case 0:
        return 'не отвечает'
      case 1:
        return 'опрашивается успешно'
      case 255:
        return 'опрос еще не проводился'
      default:
        return ''
    }
  }, [])

  const amperage = useCallback((state) => {
    let amperage = '0'
    if (state[0] && !state[1]) {
      amperage = (+state[0].amperage / 1000).toFixed(3)
    } else if (state[0] && state[1]) {
      amperage = (+state[0].amperage / 1000 + +state[1].amperage / 1000).toFixed(3)
    }

    return amperage
  }, [])

  const power = useCallback((state) => {
    let power

    if (state[0] && !state[1]) {
      power = (+state[0].amperage / 1000) * (+state[0].voltage / 1000)
    } else if (state[0] && state[1]) {
      power = (+state[0].amperage / 1000) * (+state[0].voltage / 1000) + (+state[1].amperage / 1000) * (+state[1].voltage / 1000)
    } else {
      power = 0
    }

    return +power.toFixed(3)
  }, [])

  const voltage = useCallback(
    (state) => {
      let voltage = 0

      if (parseInt(state[0]?.amperage) / 1000 + parseInt(state[1]?.amperage) / 1000 == 0) {
        voltage = 0

        return +voltage.toFixed(3)
      }

      if (state[0] && !state[1]) {
        voltage = +state[0].voltage / 1000
      } else if (state[0] && state[1]) {
        voltage = power(state) / (+state[0].amperage / 1000 + +state[1].amperage / 1000)
      }

      return +voltage.toFixed(3)
    },
    [power]
  )

  const temp = useCallback((state) => {
    let temp = 0

    if (state[0] && !state[1]) {
      temp = +state[0].temp
    } else if (state[0] && state[1]) {
      temp = Math.max(+state[0].temp, +state[1].temp)
    }

    return temp
  }, [])

  const alarmEvents = useCallback((state) => {
    const alarmEvents = []

    if (state[0] && !state[1]) {
      if (state && state?.[0].events) {
        if (state?.[0].events.lowVoltage) alarmEvents.push('Обрыв матриц (пониженный ток на выходе БП)')
        if (state?.[0].events.deviationVoltage) alarmEvents.push('Неисправность БП (пониженное напряжение на выходе БП)')
        if (state?.[0].events.overheat) alarmEvents.push('Перегрев БП')
        if (state?.[0].events.stuck) alarmEvents.push('Отказ подчиненного БП')
      }
    } else if (state[0] && state[1]) {
      if (state && (state?.[0].events || state?.[0].events)) {
        if (state?.[0].events.lowVoltage || state?.[1].events.lowVoltage)
          alarmEvents.push('Обрыв матриц (пониженный ток на выходе БП)')
        if (state?.[0].events.deviationVoltage || state?.[1].events.deviationVoltage)
          alarmEvents.push('Неисправность БП (пониженное напряжение на выходе БП)')
        if (state?.[0].events.overheat || state?.[1].events.overheat) alarmEvents.push('Перегрев БП')
        if (state?.[0].events.stuck || state?.[1].events.stuck) alarmEvents.push('Отказ подчиненного БП')
      }
    }

    return alarmEvents
  }, [])

  const workMode = useMemo(() => {
    if (
      (light.enableIRMode ? (light.irModeOn ? 'ir' : 'plc') : 'plc') == 'plc' &&
      light.state[0].highPriorityPower &&
      light.state[0].highPriorityPower != 255
    ) {
      return <Icon Svg={ManualIcon} width="16px" />
    }

    return <Icon Svg={ControllerIcon} width="16px" />
  }, [light])

  return (
    <VStack max gap="8" className={classNames(cls.infoWrapper, {}, [className])}>
      <RowInfo type={ControlInfoType.TEXT} label="Последнее обновление" value={formatDt(light.updatedTime)} />
      {isFullInfo && (
        <>
          <RowInfo label="Подгруппа управления" value={light.serverGroup} />
          <RowInfo label="Режим работы" type={ControlInfoType.NODE} value={workMode} />
        </>
      )}
      <RowInfo type={ControlInfoType.TEXT} label="Состояние связи" value={connectionState(light.connectionState)} />
      <RowInfo type={ControlInfoType.TEXT} label="Сила тока, А" value={amperage(light.state)} />
      <RowInfo type={ControlInfoType.TEXT} label="Напряжение на выходе, В" value={voltage(light.state)} />
      <RowInfo type={ControlInfoType.TEXT} label="Потребляемая мощность, Вт" value={power(light.state)} />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Потребленное кол-во энергии, кВт×ч"
        value={(light.consumedEnergy / 10000 || 0).toFixed(3)}
      />
      <RowInfo type={ControlInfoType.TEXT} label="Температура, °C" value={temp(light.state)} />
      <RowInfo type={ControlInfoType.TEXT} label="Время горения, ч" value={(light.workTime / 60 || 0).toFixed(1)} />
      <RowInfo
        type={ControlInfoType.TEXT}
        label="Аварийные события"
        value={
          alarmEvents(light.state).length == 0
            ? 'отсутствуют'
            : alarmEvents(light.state).map((v, k) => <div key={`${k}-${v}`}>{v}</div>)
        }
      />
      {isFullInfo && (
        <>
          <RowInfo
            type={ControlInfoType.OBJECT}
            label="Координаты"
            value={[`Широта ${light.coordinates.latitude}`, `Долгота ${light.coordinates.longtitude}`]}
          />
          <RowInfo type={ControlInfoType.TEXT} label="Описание места установки (номер опоры)" value={light.comment} />
        </>
      )}
    </VStack>
  )
})
