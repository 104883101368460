import { ReactElement } from 'react'
import { FeatureFlag } from '../../../types/feature'
import { getFeatureFlag } from '../lib/getFeatures'

interface ToggleFeaturesProps {
  feature: keyof FeatureFlag
  on: ReactElement
  off: ReactElement
}

export const ToggleFeatures = (props: ToggleFeaturesProps) => {
  const { on, off, feature } = props

  if (getFeatureFlag(feature)) {
    return on
  }

  return off
}
