import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { getRouteLogin, getRouteForbidden, getRouteLicense, getRouteScripts } from '../../consts/router'
import { getFeatureFlag } from '../../utils/features'
import { PageTypes } from '../../types/pageTypes'
import { getUser } from '../../store/slices/user/userSelectors'

interface RequireAuthProps {
  // eslint-disable-next-line no-undef
  children: JSX.Element
  scriptsOnly: boolean
  licenseOnly: boolean
  pageTypes: PageTypes
}

export const RequireAuth = ({ children, scriptsOnly, licenseOnly, pageTypes }: RequireAuthProps) => {
  const user = useSelector(getUser)
  const location = useLocation()

  if (!user) {
    return <Navigate to={getRouteLogin()} state={{ from: location }} replace />
  }

  if (user.blocked) {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasRequiredLicense = useMemo(() => {
    const lic = user.organisationLicence
    if (!lic?.isLicensed || lic?.licenseState) {
      return true
    }

    return false
  }, [user.organisationLicence])

  if (!hasRequiredLicense && licenseOnly) {
    return <Navigate to={getRouteLicense()} state={{ from: location }} replace />
  }

  if (getFeatureFlag('ui_script_interface') && !scriptsOnly) {
    return <Navigate to={getRouteScripts()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_events') && pageTypes === 'events') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_status') && pageTypes === 'status') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_manage') && pageTypes === 'manage') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_map') && pageTypes === 'map') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_reports') && pageTypes === 'reports') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  if (!getFeatureFlag('ui_profile') && pageTypes === 'user') {
    return <Navigate to={getRouteForbidden()} state={{ from: location }} replace />
  }

  return children
}
