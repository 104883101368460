export const monthRusName: { [key: number]: string } = {
  1: 'Январь',
  2: 'Февраль',
  3: 'Март',
  4: 'Апрель',
  5: 'Май',
  6: 'Июнь',
  7: 'Июль',
  8: 'Август',
  9: 'Сентябрь',
  10: 'Октябрь',
  11: 'Ноябрь',
  12: 'Декабрь',
}

export const actionTypes: string[] = ['Управление реле', 'Диммирование светильников']

export const workingAlgorithmTypes: string[] = ['Диаграммы работы', 'Годовое расписание']

export const actionWhenMessages = [
  'по времени включения по базовому расписанию,с учетом поправки в полях «Час», «Минута»',
  'по времени отключения по базовому расписанию,с учетом поправки в полях «Час», «Минута»',
  'по времени, указанному в полях «Час», «Минута»',
]

export const overrideTypes: string[] = ['Дата', 'Интервал', 'Дни недели']

export const overrideWeekDaysName: {
  isMonday: string
  isTuesday: string
  isWednesday: string
  isThursday: string
  isFriday: string
  isSaturday: string
  isSunday: string
} = {
  isMonday: 'Пн',
  isTuesday: 'Вт',
  isWednesday: 'Ср',
  isThursday: 'Чт',
  isFriday: 'Пт',
  isSaturday: 'Сб',
  isSunday: 'Вс',
}

export const eventTypes: { [key: number]: { [key: number]: string } } = {
  1: {
    1: 'Нет сетевого питания, используется внешний резервный источник',
    2: 'Нет сетевого питания, используется встроенный резервный источник',
    3: 'Есть сетевое питание',
    4: 'Перезапуск после WD',
    5: 'Включение',
    6: 'Программный перезапуск',
    7: 'Перезапуск из-за сбоя питания MCU',
  },
  2: {
    1: 'Напряжение на фазе в норме',
    2: 'Перенапряжение на фазе',
    3: 'Провал напряжения на фазе',
    4: 'Критическое перенапряжение на фазе',
    5: 'Критический провал напряжения на фазе',
  },
  3: {
    1: 'Напряжение на измерительном входе в норме',
    2: 'Перенапряжение на измерительном входе',
    3: 'Провал напряжения на измерительном входе',
    4: 'Критическое перенапряжение на измерительном входе',
    5: 'Критический провал напряжения на измерительном входе',
  },
  4: {
    1: 'Есть напряжение на контрольном входе',
    2: 'Нет напряжения на контрольном входе',
    3: 'Есть напряжение на измерительном входе',
    4: 'Нет напряжения на измерительном входе',
    5: 'Изменение положения переключателя ШУО',
  },
  5: {
    1: 'Сим карта установлена',
    2: 'Нет или неисправна сим карта',
  },
  6: {
    1: 'Соединение GSM установлено',
    2: 'Соединение GSM разорвано',
    3: 'Получено SMS-сообщение',
    4: 'Отправлено SMS-сообщение',
  },
  7: {
    1: 'Соединение Ethernet установлено',
    2: 'Соединение Ethernet разорвано',
  },
  8: {
    1: 'Время синхронизировано',
  },
  9: {
    1: 'Получены координаты',
  },
  10: {
    1: 'Потеря связи с модулем',
    2: 'Восстановление связи с модулем',
  },
  11: {
    1: 'Потеря связи с модулем',
    2: 'Восстановление связи с модулем',
  },
  12: {
    1: 'Включение реле',
    2: 'Отключение реле',
    3: 'Замыкание дискретного входа',
    4: 'Размыкание дискретного входа',
    5: 'Нарушение охранного контура',
    6: 'Восстановление охранного контура',
    7: 'Нажатие кнопки вызова скрипта',
  },
  13: {
    1: 'Напряжение на фазе в норме',
    2: 'Перенапряжение на фазе',
    3: 'Провал напряжения на фазе',
    4: 'Критическое перенапряжение на фазе',
    5: 'Критический провал напряжения на фазе',
  },
  14: {
    1: 'Потеря связи со светильником',
    2: 'Восстановление связи со светильником',
    3: 'Перегрев',
    4: 'Нет перегрева',
    5: 'Отклонение тока на выходе БП (ток через матрицы ниже ожидаемого, вероятен обрыв нитей матриц)',
    6: 'Нет отклонения тока',
    7: 'Отклонение напряжения на выходе БП (низкое выходное напряжение, вероятна неисправность в блоке питания)',
    8: 'Нет отклонения напряжения',
    9: 'Ошибка связи со Slave-БП светильника',
    10: 'Нет ошибки связи со Slave-БП',
  },
  15: {
    1: 'Годовое расписание активно',
    2: 'Ошибка чтения годового расписания',
    3: 'Нет файла годового расписания',
    4: 'Файл годового расписания записан',
  },
  16: {
    1: 'Общее назначение уровня диммирования',
    2: 'Общее назначение уровня диммирования канала',
    3: 'Групповое назначение уровня диммирования',
    4: 'Групповое назначение уровня диммирования канала',
    5: 'Адресное назначение уровня диммирования',
    6: 'Адресное назначение уровня диммирования канала',
    7: 'Активирован сценарий',
    8: 'Сценарии не применены',
  },
  17: {
    1: 'Активация сеанса Bluetooth-идентификации',
    2: 'Завершение сеанса Bluetooth-идентификации',
    3: 'Запуск фиксации результатов Bluetooth-идентификации',
    4: 'Завершение фиксации результатов Bluetooth-идентификации',
    5: 'Прерывание фиксации результатов Bluetooth-идентификации',
  },
}

export enum tagStatus {
  work = 'lightDefault',
  alarm = 'error',
  warning = 'warning',
}

export const channelTypes: { [key: number]: string } = {
  1: 'GSM',
  2: 'ETHERNET',
}

export const gsmTypes: { [key: number]: string } = {
  0: 'GSM (2G)',
  2: `UTRAN (3G)`,
  3: 'GSM W/EGPRS (2G)',
  4: 'UTRAN W/HSDPA (3G)',
  5: 'UTRAN W/HSUPA (3G)',
  6: 'UTRAN W/HSDPA and HSUPA (3G)',
  7: 'E-UTRAN (4G)',
  100: 'CDMA (2G)',
}
