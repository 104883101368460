import { memo, useEffect, useMemo } from 'react'
import moment from 'moment'
import { VStack } from '../../UI/Stack'
import { socket } from '../../../utils'
import { useAppSelector } from '../../../store/hooks'
import { RootState } from '../../../store/store'
import { Event } from '../../../types/event'
import { NotificationItem } from '../NotificationItem/NotificationItem'
import cls from './NotificationList.module.scss'
import { classNames } from '../../../utils/classNames/classNames'

interface NotificationListProps {
  className?: string
}

export const NotificationList = memo((props: NotificationListProps) => {
  const { className } = props
  const events = useAppSelector((state: RootState) => state.notification.events) as Event[]

  const currentEvents = useMemo(() => {
    return Object.values(events).sort((ev1,ev2)=> ev1.appearTime<ev2.appearTime?1:-1)
  }, [events])

  return (
    <VStack gap="16" max className={classNames(cls.notificationList, {}, [className])}>
      {currentEvents?.map((item) => (
        <NotificationItem key={item.id} event={item} />
      ))}
    </VStack>
  )
})
