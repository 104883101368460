import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../config'

export const rtkApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers) => {
      const user = JSON.parse(localStorage.getItem('user'))
      if (user && user.token) {
        headers.set('Authorization', `Bearer ${user.token}`)
      }

      return headers
    },
  }),
  endpoints: (builder) => ({}),
})
