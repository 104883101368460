export enum AppRoutes {
  LOGIN = 'login',
  STATUS = 'status',
  EVENTS = 'events',
  MANAGE = 'manage',
  MAP = 'map',
  GRAPH = 'graph',
  USER = 'user',
  FORBIDDEN = 'forbidden',
  YEAR_SCHEDULE = 'year-schedule',
  DIAGRAMS = 'diagrams',
  SCRIPTS = 'scripts',
  MANAGE_SETTINGS = 'manage_settings',
  LICENSE = 'license',
  // last
  NOT_FOUND = 'not_found',
}

export const getRouteLogin = () => '/login'
export const getRouteStatus = () => '/status'
export const getRouteEvents = () => '/events'
export const getRouteManage = () => '/manage'
export const getRouteYearSchedule = () => '/manage/year-schedule'
export const getRouteDiagrams = () => '/manage/diagrams'
export const getRouteScripts = () => '/manage/scripts'
export const getRouteManageSettings = () => '/manage/settings'
export const getRouteMap = () => `/map`
export const getRouteGraph = () => '/graph'
export const getRouteUser = () => '/user'
export const getRouteLicense = () => '/license'
export const getRouteForbidden = () => '/forbidden'
