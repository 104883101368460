import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'

export const getLights = (state: RootState) => state.lights

export const getLightById = createSelector([getLights, (state: RootState, id: number) => id], (lights, id: number) => lights[id])
export const getLampRecIds = createSelector([getLights, (state: RootState, ids: number[]) => ids], (lights, ids: number[]) =>
  ids?.map((id) => lights[id]?.lampRecID)
)
export const getLightsByIds = createSelector([getLights, (state: RootState, ids: number[]) => ids], (lights, ids: number[]) =>
  ids.map((id) => lights[id])
)

export const getLightsByServerId = createSelector(
  [getLights, (state: RootState, serverId: number) => serverId],
  (lights, serverId: number) => Object.values(lights).filter((f) => f.serverID === serverId)
)
