import { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Toaster } from '@guilds/pandora-react-ui'
import HomePage from './components/HomePage.jsx'
import { LoginPage } from './pages/LoginPage'
import PrivateRoute from './components/PrivateRoute.jsx'
import './style.scss'
import './styles/index.scss'
import { config } from './config'
import { PageLoader } from './components/UI/PageLoader'

const App = () => {
  useEffect(() => {
    if (config.isRecon) {
      document.title = 'Рекон Энерго'
      const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      link.href = '/recon_favicon.png'
    }
  }, [])

  return (
    <div className="app">
      <Toaster duration={5000} position="top-right" closeButton />
      <Routes>
        <Route
          path="/login"
          element={
            <Suspense fallback={<PageLoader />}>
              <LoginPage />
            </Suspense>
          }
        />
        <Route path="*" element={<PrivateRoute component={HomePage} />} />
      </Routes>
    </div>
  )
}

export default App
