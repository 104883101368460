// ui reducer (move to RTK into uiSlice)
// const setFilter = (filter) => ({ type: 'SET_ALARM_FILTER', filter })
// const setClickedGroup = (id) => ({ type: 'SET_CLICKED_GROUP', id })
// const setClickedPLC = (id) => ({ type: 'SET_CLICKED_PLC', id })
// const setClickedLight = (id) => ({ type: 'SET_CLICKED_LIGHT', id })

// pls_scripts reducer (move to actions into pls_scripts_actions)
// const addPLSScript = (script) => ({ type: 'ADD_PLS_SCRIPT', script })
// const editPLSScript = (plc_id, script) => ({ type: 'EDIT_PLS_SCRIPT', plc_id, script })
// const delPLSScripts = (plc_id) => ({ type: 'DEL_PLS_SCRIPT', plc_id })

// pls_diagrams reducer
const addPLSDiagram = (diag) => ({ type: 'ADD_PLS_DIAGRAM', diag })
const addPLSDiagrams = (diags) => ({ type: 'ADD_PLS_DIAGRAMS', diags })
const editPLSDiagram = (diag) => ({ type: 'EDIT_PLS_DIAGRAM', diag })
const delPLSDiagram = (diag_id) => ({ type: 'DEL_PLS_DIAGRAM', diag_id })

// plcs reducer
const changePLCPower = (plc_id, value) => ({ type: 'CHANGE_PLC_POWER', plc_id, value })
// prettier-ignore
const removeLightFromPLC = (plc_id, light, serverGroupRemoveFlag) => ({ type: 'REMOVE_LIGHT_FROM_PLC',  plc_id,  light,  serverGroupRemoveFlag})
const addLightToPLC = (plc_id, light) => ({ type: 'ADD_LIGHT_TO_PLC', plc_id, light })
const editPLC = (plc) => ({ type: 'EDIT_PLC', plc })
const addPLC = (plc) => ({ type: 'ADD_PLC', plc })
const addPLCs = (plcs) => ({ type: 'ADD_PLCs', plcs })

// plc_groups reducer
const changePLCGroupPower = (plc_group_id, value) => ({ type: 'CHANGE_PLC_GROUP_POWER', plc_group_id, value })
const addPLCServerGroup = (group) => ({ type: 'ADD_PLC_SERVER_GROUP', group })

// lights reducer
const changeLightPower = (light_id, value) => ({ type: 'CHANGE_LIGHT_POWER', light_id, value })
const changeLightIRModeParams = (light_id, value) => ({ type: 'CHANGE_LIGHT_IRMODE_PARAMS', light_id, value })
const addLight = (light) => ({ type: 'ADD_LIGHT', light })
const addLights = (lights) => ({ type: 'ADD_LIGHTS', lights })
const removeLight = (light_id) => ({ type: 'REMOVE_LIGHT', light_id })
const changeLight = (light) => ({ type: 'CHANGE_LIGHT', light })
const setLightsStatus = (lights) => ({ type: 'SET_LIGHTS_STATUS', lights })

// info reducer?

// groups reducer
const editGroup = (group) => ({ type: 'EDIT_GROUP', group })
const removeGroup = (group_id) => ({ type: 'REMOVE_GROUP', group_id })
const addGroup = (group) => ({ type: 'ADD_GROUP', group })
const addGroups = (groups) => ({ type: 'ADD_GROUPS', groups })
const toggleMenu = (group, isMenuOpen) => ({ type: 'TOGGLE_MENU', group, isMenuOpen })
const closeMenuChild = (group) => ({ type: 'CLOSE_MENU_CHILD', group })
const openMenu = (group) => ({ type: 'OPEN_MENU', group })
const setGroupsStatus = (groups) => ({ type: 'SET_GROUPS_STATUS', groups })

// events reducer
const addEvent = (event) => ({ type: 'ADD_EVENT', event })
const addEvents = (events) => ({ type: 'ADD_EVENTS', events })
const clearEvents = () => ({ type: 'CLEAR_EVENTS' })
const addGeneratedEvents = (event) => ({ type: 'ADD_GEN_EVENTS', event })
const setEventWorkStatus = (id, work_status) => ({ type: 'SET_EVENT_WORK_STATUS', id, work_status })
const setEventComments = (id, comments) => ({ type: 'SET_EVENT_COMMENTS', id, comments })

export const uiActions = {
  setGroupsStatus,
  setEventWorkStatus,
  setEventComments,
  addEvent,
  addEvents,
  clearEvents,
  addGeneratedEvents,
  addPLC,
  addPLCs,
  editPLC,
  addGroup,
  addGroups,
  toggleMenu,
  closeMenuChild,
  openMenu,
  editGroup,
  removeGroup,
  addLight,
  addLights,
  removeLight,
  changeLight,
  setLightsStatus,
  addLightToPLC,
  removeLightFromPLC,
  addPLCServerGroup,
  changePLCPower,
  changePLCGroupPower,
  changeLightPower,
  changeLightIRModeParams,
  addPLSDiagram,
  addPLSDiagrams,
  editPLSDiagram,
  delPLSDiagram,
}
