import { createAsyncThunk } from '@reduxjs/toolkit'
import { AuthData } from '../../../types/authData'
import { ThunkConfig } from '../../store'
import { User } from '../../../types/user'
import { userActions } from '../user/userSlice'

interface AuthBody {
  login: string
  password: string
}

export const authService = createAsyncThunk<AuthData, AuthBody, ThunkConfig<string>>(
  'auth/authService',
  async (body: AuthBody, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi

    try {
      const response = await extra.api.post<User>('/api/auth', body)

      if (!response.data) {
        throw new Error()
      }

      localStorage.setItem('user', JSON.stringify(response.data))

      const userData = structuredClone(response.data)
      delete userData.token
      delete userData.refreshToken

      dispatch(userActions.setUserData(userData))

      return { token: response.data.token, refreshToken: response.data.refreshToken }
    } catch (e) {
      console.log('error auth service', e)

      return rejectWithValue(`error auth service ${e}`)
    }
  }
)
