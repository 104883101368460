import { createSlice } from '@reduxjs/toolkit'
import { mapDataParsing } from './mapDataService'
import { IMap } from '../../../types/map'

const initialState: IMap = {
  center: [54.546101, 36.254053],
  zoom: 16,
  light_ids: [],
  clicked_plc_id: null,
  clicked_light_id: null,
}

const mapDataSlice = createSlice({
  name: 'mapData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mapDataParsing.fulfilled, (state, { payload }) => {
      state = payload

      return state
    })
  },
})

export const { actions: mapDataActions, reducer: mapDataReducer } = mapDataSlice
