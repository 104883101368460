import React, { memo, useCallback } from 'react'
import { VStack } from '../../UI/Stack'
import { Controller } from '../../../types/controller'
import { ControlInfoType, RowInfo } from '../../UI/RowInfo'
import { formatDt } from '../../../utils/functions/formatDt'
import { ReactComponent as ControllerIcon } from '../../../assets/icons/controller-icon.svg'
import { Icon } from '../../UI/Icon'

interface ControllerInfoProps {
  controller: Controller
}

export const ControllerInfo = memo((props: ControllerInfoProps) => {
  const { controller } = props

  const workingAlgorithm = useCallback((alg: number) => {
    if (alg === 0) {
      return 'Cуточные диаграммы'
    }

    return 'Годовое расписание'
  }, [])

  const power = useCallback((powerState: number) => {
    switch (powerState) {
      case 0:
        return 'от сети'
      case 1:
        return 'от внешнего источника резервного питания'
      case 2:
        return 'от встроенного источника резервного питания'
      default:
        return ''
    }
  }, [])

  return (
    <VStack max gap="8">
      <RowInfo type={ControlInfoType.TEXT} label="ID контроллера" value={controller.serverRecID} />
      <RowInfo type={ControlInfoType.TEXT} label="Последнее обновление" value={formatDt(controller.updatedTime)} />
      <RowInfo type={ControlInfoType.TEXT} label="Алгоритм работы" value={workingAlgorithm(controller.workingAlgorithm)} />
      <RowInfo type={ControlInfoType.TEXT} label="Питание" value={power(controller.powerState)} />
      <RowInfo type={ControlInfoType.TEXT} label="Общее количество светильников" value={controller.totalObjects || 0} />
      <RowInfo type={ControlInfoType.TEXT} label="Количество светильников в ошибке" value={controller.objectsWithError || 0} />
      <RowInfo
        type={ControlInfoType.NODE}
        label="Режим работы светильников"
        value={controller.relay[0]?.hasHighPrioritySetup ? '' : <Icon Svg={ControllerIcon} width="16px" />}
      />
      <RowInfo
        type={ControlInfoType.OBJECT}
        label="Координаты"
        value={[`Широта: ${controller.coordinates.latitude}`, `Долгота: ${controller.coordinates.longtitude}`]}
      />
      <RowInfo type={ControlInfoType.TEXT} label="Версия ПО" value={controller.firmwareVersion || 0} />
    </VStack>
  )
})
