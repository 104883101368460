import React, { memo, ReactNode, useMemo } from 'react'
import { HStack, VStack } from '../Stack'
import { Text, TextSize, TextTheme } from '../Text'
import cls from './RowInfo.module.scss'
import { classNames, Mods } from '../../../utils/classNames/classNames'

export enum ControlInfoType {
  TEXT = 'text',
  NODE = 'node',
  OBJECT = 'object',
}

interface ControlInfoProps {
  label?: string
  value: string | ReactNode | string[]
  type?: ControlInfoType
  isSmall?: boolean
  isBorder?: boolean
}

export const RowInfo = memo((props: ControlInfoProps) => {
  const { label, value, type = ControlInfoType.TEXT, isSmall = false, isBorder = true } = props

  const mods: Mods = {
    [cls.isBorder]: isBorder,
  }

  const content = useMemo(() => {
    switch (type) {
      case ControlInfoType.TEXT:
        return <Text text={value as string} size={isSmall && TextSize.S} />
      case ControlInfoType.NODE:
        return value
      case ControlInfoType.OBJECT:
        return (
          <VStack align="end">{Array.isArray(value) && value.map((el: string, i: number) => <Text key={i} text={el} />)}</VStack>
        )
      default:
        return null
    }
  }, [isSmall, type, value])

  return (
    <HStack
      className={classNames('', mods)}
      max
      gap="8"
      justify="between"
      align={type === ControlInfoType.NODE ? 'center' : 'start'}
    >
      <Text text={label} theme={TextTheme.LIGHT} size={isSmall && TextSize.S} />
      {content}
    </HStack>
  )
})
