import React, { memo, useCallback } from 'react'
import { Collapse } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import { RootState } from '../../../store/store'
import { Group } from '../../../types/group'
import { NavPLCGroup } from '../NavPLCGroup/NavPLCGroup'
import { Tag } from '../../UI/Tag'
import { classNames } from '../../../utils/classNames/classNames'
import { groupsActions } from '../../../store/slices/groups/groupsSlice'
import { config } from '../../../config'
import cls from './NavAddressGroup.module.scss'

interface NavAddressGroupProps {
  padLeft: number
  currentGroup: Group
  sortedGroups: Group[]
  parent_id: number
  onGroupClick: (group_id: number, plc_id: number, light_id: number) => void
}

export const NavAddressGroup = memo((props: NavAddressGroupProps) => {
  const { padLeft, currentGroup, sortedGroups, parent_id, onGroupClick } = props
  const ui = useAppSelector(uiSelector.getUi)
  const groups = useAppSelector((state: RootState) => state.groups) as Group[]
  const dispatch = useAppDispatch()

  const handleClick = useCallback(() => {
    onGroupClick(parent_id, null, null)
  }, [onGroupClick, parent_id])

  const handleToggleMenu = useCallback(() => {
    const child = [...Object.values(groups).filter((group) => group.parent_id == currentGroup.id)]

    if (currentGroup.isMenuOpen || !currentGroup.isMenuOpen || child) {
      child.forEach((group) => dispatch(groupsActions.closeMenuChild(group.id)))
    }
    dispatch(groupsActions.toggleMenu(currentGroup.id))
  }, [currentGroup, dispatch, groups])

  return (
    <div className={cls.navGroup}>
      <div
        onClick={handleClick}
        style={{ paddingLeft: `${padLeft}px` }}
        className={classNames(
          `nav-group-title nav-group-title-${currentGroup?.isMenuOpen ? 'open' : 'close'} ${config.isRecon ? 'isRecon' : ''}`,
          {
            [cls.active]: ui.clicked_group_id === currentGroup.id,
          }
        )}
      >
        <i className="bi bi-collection" />
        <div className={`nav-group-name nav-group-name-${currentGroup?.isMenuOpen ? 'open' : 'close'}`}>
          {groups[parent_id].name}
        </div>
        {groups[parent_id].alarm_count !== 0 || groups[parent_id].warning_count !== 0 ? (
          <Tag
            label={groups[parent_id].alarm_count + groups[parent_id].warning_count}
            theme={groups[parent_id].alarm_count !== 0 ? 'error' : 'warning'}
          />
        ) : (
          <div />
        )}
        <div className="chevronBtn" onClick={handleToggleMenu}>
          <i className={`bi bi-chevron-${currentGroup?.isMenuOpen ? 'up' : 'down'}`} />
        </div>
      </div>
      <Collapse in={currentGroup?.isMenuOpen}>
        <div>
          {currentGroup?.isMenuOpen && (
            <>
              {sortedGroups
                ?.filter((group) => group.parent_id === parent_id && ui.filter.includes(group.status))
                .map((group) =>
                  group.hasOwnProperty('plc_id') ? (
                    <NavPLCGroup
                      key={group.id.toString()}
                      padLeft={padLeft + 15}
                      currentGroup={group}
                      parent_id={group.id}
                      groups={groups}
                      plc_id={group.plc_id}
                      onGroupClick={onGroupClick}
                    />
                  ) : (
                    <NavAddressGroup
                      padLeft={padLeft + 15}
                      key={group.id.toString()}
                      currentGroup={groups[group.id]}
                      sortedGroups={sortedGroups}
                      parent_id={group.id}
                      onGroupClick={onGroupClick}
                    />
                  )
                )}
            </>
          )}
        </div>
      </Collapse>
    </div>
  )
})
