import { ReactNode, useEffect, useMemo, useState } from 'react'
import { Theme } from '../../consts/theme'
import { ThemeContext } from '../context/ThemeContext'
import { config } from '../../config'

interface ThemeProviderProps {
  // initialTheme?: Theme;
  children: ReactNode
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(Theme.PANDORA)
  const [isThemeInited, setThemeInited] = useState(false)

  useEffect(() => {
    if (!isThemeInited) {
      setTheme(config.isRecon ? Theme.RECON : Theme.PANDORA)
      setThemeInited(true)
    }
  }, [isThemeInited])

  useEffect(() => {
    document.body.className = theme
  }, [theme])

  const defaultProps = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme]
  )

  return <ThemeContext.Provider value={defaultProps}>{children}</ThemeContext.Provider>
}

export default ThemeProvider
