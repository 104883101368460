import axios from 'axios'
import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { config } from '../config.js'
import { initSocket, socket } from '../utils'
import CheckLicence from './CheckLicence.jsx'
import Header from './Header'
import NavBarVert from './NavBarVert'
import { Sidebar } from './Sidebar'
import { eventsActions } from '../store/slices/events/eventsSlice'
import { MainLayout } from './Layouts/MainLayout/MainLayouts'
import { AppRouter } from './AppRouter'
import { ToggleFeatures } from '../utils/features'
import { SimplifiedLayout } from './Layouts/SimplifiedLayout/SimplifiedLayout'
import { getUser } from '../store/slices/user/userSelectors'
import { userActions } from '../store/slices/user/userSlice'

class HomePage extends Component {
  constructor(props) {
    super(props)
    this.checkLicence = this.checkLicence.bind(this)
    // this.toggleShowMainEventsSpinner = this.toggleShowMainEventsSpinner.bind(this)

    this.state = {
      activeLicence: { isActive: true },
    }
  }

  checkLicence() {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
    axios
      .post(`${config.apiUrl}/licence/current`, {})
      .then((response) => {
        if (response.status !== 200) {
          this.setState({ activeLicence: { isActive: false } })
        } else if (response.data.isActive) {
          this.setState({ activeLicence: response.data })
          if (this.props.user.companyId) socket?.emit('/groups/list', { groupId: this.props.user.companyId })
        } else this.setState({ activeLicence: { isActive: false } })
      })
      .catch((err) => {
        alert(`Ошибка проверки кода доступа. ${err}`)
      })
  }

  componentDidMount() {
    !this.props.user && this.props.logout()

    initSocket()
    socket.open()

    this.props.getEvents()

    // ping server, await 401 status for logout (temp crutch)
    this.timer = setInterval(() => {
      socket?.emit('/groups/list', 'ping')
    }, 60 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
    socket.close()
    console.log('socket.off')
  }

  render() {
    if (!this.props.user) {
      return <Navigate to="/login" />
    }
    if (!this.state.activeLicence.isActive) {
      return (
        <CheckLicence
          activateLicence={(v) => {
            this.setState({ activeLicence: v })
            if (this.props.user.companyId) socket?.emit('/groups/list', { groupId: this.props.user.companyId })
          }}
        />
      )
    }

    return (
      <Suspense fallback="">
        <ToggleFeatures
          feature="ui_script_interface"
          on={<SimplifiedLayout header={<Header />} content={<AppRouter />} />}
          off={<MainLayout header={<Header />} content={<AppRouter />} sidebar={<Sidebar />} navBarVert={<NavBarVert />} />}
        />
      </Suspense>
    )
  }
}

const mapStateToProps = (state) => ({
  user: getUser(state),
})

const mapDispatchToProps = (dispatch) => ({
  getEvents: () => dispatch(eventsActions.getEvents()),

  logout: () => dispatch(userActions.logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
