import { RootState } from '../../store'

const getUi = (state: RootState) => state.ui
const getFiltersArr = (state: RootState) => state.ui.filter
const getFilterStartDate = (state: RootState) => state.ui.filter_start_date
const getFilterFinishDate = (state: RootState) => state.ui.filter_finish_date
const getMenuSearchText = (state: RootState) => state.ui.menuSearchText
const getClickedPlcId = (state: RootState) => state.ui.clicked_plc_id
const getClickedLightId = (state: RootState) => state.ui.clicked_light_id
const getClickedGroupId = (state: RootState) => state.ui.clicked_group_id
const getIsLoadingFilters = (state: RootState) => state.ui.isLoadingFilters
const getChangedEventsId = (state: RootState) => state.ui.changedEventsId
const getIsShowMessage = (state: RootState) => state.ui.isShowMessage
const getIsLoadingGroups = (state: RootState) => state.ui.isLoadingGroups
const getErrorText = (state: RootState) => state.ui.errorFiltersText
const getCheckedEventsIds = (state: RootState) => state.ui.checkedEventsIds

export const uiSelector = {
  getUi,
  getFiltersArr,
  getFilterStartDate,
  getFilterFinishDate,
  getMenuSearchText,
  getClickedPlcId,
  getClickedLightId,
  getClickedGroupId,
  getIsLoadingFilters,
  getChangedEventsId,
  getIsShowMessage,
  getIsLoadingGroups,
  getErrorText,
  getCheckedEventsIds,
}
