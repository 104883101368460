import React, { useCallback, useState } from 'react'
import { PageTitle } from '../UI/PageTitle'
import { Button, ButtonTheme } from '../UI/Button'
import PLCMnemo from '../manage/PLSMnemo'
import { HStack } from '../UI/Stack'
import { useAppSelector } from '../../store/hooks'
import { getPLSById } from '../../store/slices/plcs/plcsSelector'
import { socket } from '../../utils'
import { getFeatureFlag } from '../../utils/features'

interface PlcHeaderProps {
  plcId: number
  isMini?: boolean
}

export const PlcHeader = ({ plcId, isMini = false }: PlcHeaderProps) => {
  const currentPlc = useAppSelector((state) => getPLSById(state, plcId))

  const [isShowMnemo, setIsShowMnemo] = useState<boolean>(false)

  const toggleMneno = useCallback(() => {
    setIsShowMnemo((prev) => !prev)
  }, [])

  const changePLCReleState = useCallback(
    (value: number, relay: number) => {
      if (getFeatureFlag('ui_manage_outputs')) {
        socket?.emit('/plc/server/relay/set', {
          groupId: currentPlc.groupId,
          relay,
          state: value,
          query: { pls_id: currentPlc.id },
        })
      }
    },
    [currentPlc]
  )

  return (
    <HStack align="center" gap="8">
      <PageTitle title={currentPlc.name} type="controller" id={currentPlc.serverRecID} isMini={isMini} />
      <Button theme={isMini ? ButtonTheme.CLEAR : ButtonTheme.OUTLINE} onClick={toggleMneno}>
        Мнемосхема
      </Button>
      <PLCMnemo isShow={isShowMnemo} handleClose={toggleMneno} changeReleState={changePLCReleState} plc={currentPlc} />
    </HStack>
  )
}
