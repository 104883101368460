import React, { memo, useMemo } from 'react'
import { Controller } from '../../../types/controller'
import { ReactComponent as OpenedIcon } from '../../../assets/icons/opened-icon.svg'
import { ReactComponent as ClosedIcon } from '../../../assets/icons/closed-icon.svg'
import { ReactComponent as HasVoltage } from '../../../assets/icons/has-voltage-icon.svg'
import { ReactComponent as NotVoltage } from '../../../assets/icons/not-voltage-icon.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warrning-icon.svg'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-icon.svg'
import { ControlParamsCard } from '../../../pages/StatusPage/ui/ControlParamsCard/ControlParamsCard'
import { Icon } from '../../UI/Icon'
import { HStack, VStack } from '../../UI/Stack'
import { ReactComponent as ControllerIcon } from '../../../assets/icons/controller-icon.svg'
import { classNames } from '../../../utils/classNames/classNames'
import { VoltageOnPhaseList } from '../../VoltageOnPhaseList/VoltageOnPhaseList'
import cls from './ControllerState.module.scss'

interface ControllerStateProps {
  plcId: number
  controller: Controller
  className?: string
  isWrap?: boolean
}

export const ControllerState = memo((props: ControllerStateProps) => {
  const { plcId, controller, className, isWrap } = props

  const cardData = useMemo(() => {
    return [
      {
        id: 2,
        title: 'Состояния дискретных выходов',
        values: controller.relay.map((el, i) => ({
          name: (++i).toString(),
          value: (
            <HStack justify="center" className={cls.valueWrapper} gap="4">
              {el.currentSetup ? <Icon Svg={ClosedIcon} /> : <Icon Svg={OpenedIcon} />}
              {!el.hasHighPrioritySetup && <Icon Svg={ControllerIcon} width="16px" />}
            </HStack>
          ),
          isActive: el.currentSetup,
        })),
        isSlide: true,
      },
      {
        id: 3,
        title: 'Состояния контрольных входов',
        values: controller.controlEntrance.map((el, i) => ({
          name: `${++i} - ${el.isExternal ? 'внеш' : 'внутр'}`,
          value: (
            <HStack justify="center" className={cls.valueWrapper}>
              {el.hasVoltage ? <Icon Svg={HasVoltage} /> : <Icon Svg={NotVoltage} />}
            </HStack>
          ),
          isActive: el.hasVoltage,
        })),
        isSlide: true,
      },
      {
        id: 4,
        title: 'Состояние охранного контура',
        values: controller.guardCircuit.map((el, i) => ({
          name: `${++i} - ${el.isExternal ? 'внеш' : 'внутр'}`,
          value: (
            <HStack justify="center" className={cls.valueWrapper}>
              {el.isLocked ? <Icon Svg={SuccessIcon} /> : <Icon Svg={WarningIcon} />}
            </HStack>
          ),
          isActive: el.isLocked,
        })),
      },
      {
        id: 5,
        title: 'Состояние дискретных входов',
        values: controller.externalEntrance.map((el, i) => ({
          name: `${++i} - ${el.isExternal ? 'внеш' : 'внутр'}`,
          value: (
            <HStack justify="center" className={cls.valueWrapper}>
              {el.currentSetup ? <Icon Svg={ClosedIcon} /> : <Icon Svg={OpenedIcon} />}
            </HStack>
          ),
          isActive: el.currentSetup,
        })),
      },
      {
        id: 6,
        title: 'Состояния измерительных входов, В',
        values: controller.voltageOnEntrance.map((el, i) => ({
          name: `${++i} - ${el.isExternal ? 'внеш' : 'внутр'}`,
          value: (
            <HStack justify="center" className={cls.valueWrapper}>
              {el.voltage}
            </HStack>
          ),
        })),
      },
    ]
  }, [controller])

  return (
    <VStack max gap="12" className={classNames('', {}, [className])}>
      <VoltageOnPhaseList plcId={plcId} />
      {cardData.map((data) => (
        <ControlParamsCard
          key={data.id}
          title={data.title}
          values={data.values}
          type="vertical"
          isSlide={data.isSlide}
          isWrap={isWrap}
        />
      ))}
    </VStack>
  )
})
