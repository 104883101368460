import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Group } from '../../../types/group'

interface GroupsSchema {
  [key: number]: Group
}

const initialState: GroupsSchema = {}

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    addGroup: (state, { payload }: PayloadAction<Group>) => {
      state[payload.id] = payload
    },
    editGroup: (state, { payload }: PayloadAction<Group>) => {
      state[payload.id] = payload
    },
    removeGroup: (state, { payload }: PayloadAction<number>) => {
      delete state[payload]
    },
    addGroups: (state, { payload }: PayloadAction<Group[]>) => {
      const newState = { ...state }
      payload.forEach((group) =>
        Object.defineProperty(newState, group.id, {
          enumerable: true,
          value: { ...group, isMenuOpen: false, childrenGroups: [] },
        })
      )

      return newState
    },
    toggleMenu: (state, { payload }: PayloadAction<number>) => {
      state[payload].isMenuOpen = !state[payload].isMenuOpen
    },
    closeMenuChild: (state, { payload }: PayloadAction<number>) => {
      state[payload].isMenuOpen = false
    },
    openMenu: (state, { payload }: PayloadAction<number>) => {
      state[payload].isMenuOpen = true
    },
    setGroupsStatus: (state, { payload }: PayloadAction<Group[]>) => {
      for (const group_id in state) {
        const status = payload.find((gr: Group) => String(gr.id) === String(group_id))
        if (status) {
          if (status.warning_count > 0) {
            state[group_id].status = 'warning'
            state[group_id].warning_count = status.warning_count
            state[group_id].alarm_count = status.alarm_count
          }
          if (status.alarm_count > 0) {
            state[group_id].status = 'alarm'
            state[group_id].warning_count = status.warning_count
            state[group_id].alarm_count = status.alarm_count
          }
          if (status.alarm_count <= 0 && status.warning_count <= 0) {
            state[group_id].status = 'work'
            state[group_id].warning_count = 0
            state[group_id].alarm_count = 0
          }
        }
      }
    },
  },
})

export const { actions: groupsActions, reducer: groupsReducer } = groupsSlice
