import { combineReducers, configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { AxiosInstance } from 'axios'
import { config } from '../config'
import info from './reducers/info'
import plc_groups from './reducers/plc_groups'
import pls_diagrams from './reducers/pls_diagrams'
import pls_scripts from './reducers/pls_scripts'
import authSlice from './slices/auth/authSlice'
import uiSlice from './slices/ui/uiSlice'
import usersSlice from './slices/users/usersSlice'
import rolesSlice from './slices/roles/rolesSlice'
import notificationSlice from './slices/notification/notificationSlice'
import eventsMiddleware from './slices/events/eventsMiddleware'
import eventsSlice from './slices/events/eventsSlice'
import { rtkApi } from '../utils/api/rtkApi'
import { groupsReducer } from './slices/groups/groupsSlice'
import { lightsReducer } from './slices/lights/lightsSlice'
import { plcsReducer } from './slices/plcs/plcsSlice'
import { $api } from '../utils/api/api'
import { mapDataReducer } from './slices/mapData/mapDataSlice'
import { plcReviewReducer } from './slices/plcReview/plcReviewSlice'
import { userReducer } from './slices/user/userSlice'

const appReducers = combineReducers({
  auth: authSlice,
  ui: uiSlice,
  user: userReducer,
  users: usersSlice,
  roles: rolesSlice,
  notification: notificationSlice,
  info,
  groups: groupsReducer,
  plcs: plcsReducer,
  plcReview: plcReviewReducer,
  plc_groups,
  pls_scripts,
  pls_diagrams,
  lights: lightsReducer,
  events: eventsSlice,
  mapData: mapDataReducer,
  [rtkApi.reducerPath]: rtkApi.reducer,
})

export interface ThunkExtraArg {
  api: AxiosInstance
}

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    return appReducers(undefined, action)
  }

  if (!JSON.parse(localStorage.getItem('user'))) {
    return appReducers(undefined, action)
  }

  return appReducers(state, action)
}

const extraArg: ThunkExtraArg = {
  api: $api,
}
const isTestServer = config.apiUrl === 'http://test02.p-on.test'
const middleware = isTestServer ? [thunk, logger] : [thunk]

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: extraArg,
      },
    }).concat(middleware.concat(eventsMiddleware).concat(rtkApi.middleware)),
  devTools: false,
})

export interface ThunkConfig<T> {
  rejectValue: T
  extra: ThunkExtraArg
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
