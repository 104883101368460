/**
 * Created by a.osipov on 10.12.2020.
 */

import axios from 'axios'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { config } from '../config.js'

class CheckLicence extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reportMessage: { color: 'red', message: 'Ваш лицензионный ключ не действителен.' },
      code: '',
    }
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  checkCode() {
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
    axios
      .post(`${config.apiUrl}/licence/activate`, {
        code: this.state.code,
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.isActive) {
            this.props.activateLicence(response.data)
          } else this.setState({ reportMessage: { color: 'red', message: 'Отправленный лицензионный ключ не действителен' } })
        }
      })
      .catch((err) => {
        if (err.response.status === 497)
          this.setState({ reportMessage: { color: 'red', message: 'Отправленный лицензионный ключ не действителен' } })
        if (err.response.status === 498)
          this.setState({ reportMessage: { color: 'red', message: 'Отправленный лицензионный ключ уже был активирован' } })
        if (err.response.status === 400)
          this.setState({ reportMessage: { color: 'red', message: 'Отправленный лицензионный ключ не корректный' } })
        if (err.response.status === 404)
          this.setState({ reportMessage: { color: 'red', message: 'Отправленный лицензионный ключ не корректный' } })
        if (err.response.status === 500 || err.response.status === 502)
          this.setState({ reportMessage: { color: 'red', message: 'Ошибка проверки ключа на сервере, попробуйте позднее' } })
        // this.setState({reportMessage:{color:"red",message:"Ответ от сервера не получен"}})
      })
  }

  render() {
    return (
      <Modal show onHide={() => null}>
        <Modal.Header>
          <Modal.Title>Проверка лицензионного ключа</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div style={{ color: this.state.reportMessage.color }}>{this.state.reportMessage.message}</div>
          <div>
            Лицензионный ключ:{' '}
            <input
              type="text"
              style={{ width: '120px', margin: '0 5px' }}
              value={this.state.code}
              onChange={(e) => {
                this.setState({ code: e.target.value })
              }}
            />
          </div>
          <Button
            variant="outline-primary"
            onClick={() => {
              this.setState({ reportMessage: { color: 'green', message: 'Запрос отправлен на сервер' } })
              this.checkCode()
            }}
            size="sm"
          >
            Активировать
          </Button>
        </Modal.Body>
        <Modal.Footer />
      </Modal>
    )
  }
}
export default CheckLicence
