import { HTMLAttributes } from 'react'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import cls from './Status.module.scss'

export enum StatusTheme {
  WORK = 'work',
  ALARM = 'alarm',
  WARNING = 'warning',
}

interface StatusProps extends HTMLAttributes<HTMLDivElement> {
  className?: string
  /**
   * Тема блока. Отвечает за цвет
   */
  theme: StatusTheme
}

export const Status = (props: StatusProps) => {
  const { className, theme = StatusTheme.WORK, ...otherProps } = props

  const mods: Mods = {
    [cls[theme]]: true,
  }

  return <div className={classNames(cls.status, mods, [className])} {...otherProps} />
}
