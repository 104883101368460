import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Modal } from 'react-bootstrap'
import axios from 'axios'
import { Button, ButtonTheme } from '../../../../components/UI/Button'
import { Icon } from '../../../../components/UI/Icon'
import { ReactComponent as AddIcon } from '../../../../assets/icons/plus-icon.svg'
import { Group } from '../../../../types/group'
import { Text, TextSize, TextTheme } from '../../../../components/UI/Text'
import { HStack, VStack } from '../../../../components/UI/Stack'
import { Input } from '../../../../components/UI/Input'
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-circle-icon.svg'
import { socket } from '../../../../utils'
import { config } from '../../../../config'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { authSelector } from '../../../../store/slices/auth/authSelector'
import { RootState } from '../../../../store/store'
import { Tabs } from '../../../../components/UI/Tabs'
import { groupsActions } from '../../../../store/slices/groups/groupsSlice'
import { getGroupById } from '../../../../store/slices/groups/groupsSelector'
import { ToggleFeatures } from '../../../../utils/features'
import { getUserCompany } from '../../../../store/slices/user/userSelectors'

interface AddGroupModalProps {
  isBigBtn?: boolean
  padLeft?: number
  parentId?: number | string
}

export const AddGroupModal = (props: AddGroupModalProps) => {
  const { isBigBtn = false, padLeft, parentId } = props
  const currentGroup = useAppSelector((state) => getGroupById(state, parentId === 'root' ? undefined : Number(parentId)))
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [plcId, setPlcId] = useState<string>('')
  const [isGeo, setIsGeo] = useState<boolean>(true)
  const [file, setFile] = useState<File>(null)
  const [error, setError] = useState<string>('')
  const [groupType, setGroupType] = useState<string>('logic')
  const [isNesting, setIsNesting] = useState<boolean>(false)
  const token = useAppSelector(authSelector.getAuthData)
  const companyId = useAppSelector(getUserCompany)
  const groups = useAppSelector((state: RootState) => state.groups) as Group[]
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentGroup) {
      const isNesting = groups[Number(currentGroup.parent_id)]?.parent_id === 'root'

      if (isNesting) {
        setGroupType('plc')
      }

      setIsNesting(isNesting)
    }
  }, [currentGroup, groups])

  const toggleModal = useCallback(() => {
    setIsOpen((prev) => !prev)
  }, [])

  const changeName = useCallback((value: string) => {
    setName(value)
  }, [])

  const changePlcId = useCallback((value: string) => {
    setPlcId(value)
  }, [])

  const changeIsGeo = useCallback(() => {
    setIsGeo((prev) => !prev)
  }, [])

  const changeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const reader = new FileReader()
    const file = e.target.files[0]

    if (!['.jpg', '.jpeg', '.png'].some((format) => file?.name.endsWith(format))) {
      setError('Формат загруженного файла не поддерживается')

      return
    }

    if (file.size > 5242880) {
      setError('Превышен допустимый объем файла')

      return
    }

    setFile(file)

    reader.onloadend = () => {
      setFile(file)
      setError(null)
    }
  }

  const onChangeTab = (value: string) => {
    setGroupType(value)
  }

  const onSubmit = useCallback(() => {
    if (groupType == 'logic') {
      socket?.emit('/groups/add', {
        root: currentGroup?.id ?? companyId,
        name,
      })
    }
    if (groupType == 'plc') {
      console.log(isGeo ? 1 : 0)
      socket?.emit('/groups/add', {
        query: { isGeo, serverID: plcId },
        root: currentGroup?.id ?? companyId,
        isGeo: isGeo ? 1 : 0,
        name,
        plc: { serverID: plcId },
      })
      socket?.on('/groups/add', (data: { type: number; query: { isGeo: boolean }; groupId: string }) => {
        if (data.type === 1 && data.query.isGeo === false) {
          const formData = new FormData()
          formData.append('groupId', data.groupId)
          formData.append('geoFile', file)
          axios
            .post(`${config.apiUrl}/api/groups/updateMap`, formData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              if (response.status !== 200) {
                console.error('error response', response)
                alert('Ошибка добавления группы')

                return
              }
              // обновляем файл карты для группы
              dispatch(
                groupsActions.editGroup({ ...groups[Number(data.groupId)], geoFileURL: response.data.filename, isGeo: false })
              )
              // this.props.editGroup({ ...groups[data.groupId], geoFileURL: response.data.filename, isGeo: false })
            })
            .catch((err) => {
              alert('Ошибка добавления группы')
            })
        }
      })
    }

    toggleModal()
  }, [companyId, currentGroup, dispatch, file, groupType, groups, isGeo, name, plcId, toggleModal, token])

  const controllerForm = useMemo(
    () => (
      <VStack max gap="12">
        <Input value={name} label="Название" placeholder="Введите название" fullWidth onChange={changeName} />
        <Input label="ID контроллера" placeholder="Введите ID контроллера" fullWidth onChange={changePlcId} />
        <Form.Check type="switch" label="Загрузить схему участка" checked={!isGeo} onChange={changeIsGeo} />
        <Text
          text="Допустимые форматы файла - pdf, png, jpeg. Объем загружаемого файла не должен превышать 2 МБ"
          theme={TextTheme.LIGHT}
          size={TextSize.S}
        />
        {!isGeo && (
          <Form.Group controlId="formFile">
            <Text text="Схема участка" theme={TextTheme.LIGHT} />
            <Form.Control type="file" onChange={changeImage} accept=".jpg, .jpeg, .png" key={Date.now()} />
          </Form.Group>
        )}
        {!!error && (
          <HStack max gap="8" align="center">
            <Icon Svg={WarningIcon} />
            <Text text={error} theme={TextTheme.ERROR} />
          </HStack>
        )}
      </VStack>
    ),
    [changeIsGeo, changeName, changePlcId, error, isGeo, name]
  )

  const groupForm = useMemo(
    () => <Input value={name} label="Название" placeholder="Введите название" onChange={changeName} />,
    [changeName, name]
  )

  const tabs = useMemo(() => {
    return [
      {
        title: 'Группа',
        eventKey: 'logic',
        content: groupForm,
      },
      {
        title: 'Контроллер',
        eventKey: 'plc',
        content: controllerForm,
      },
    ]
  }, [controllerForm, groupForm])

  return (
    <>
      <Button theme={ButtonTheme.CLEAR} onClick={toggleModal} style={{ paddingLeft: `${padLeft}px` }}>
        <HStack gap="8">
          <Icon width="16" height="16" Svg={AddIcon} />
          {isBigBtn && <Text text="Добавить объект" />}
        </HStack>
      </Button>

      <Modal show={isOpen} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление объекта</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HStack gap="4">
            <Text text="Добавить в" />
            <Text text={parentId === 'root' ? 'корень' : currentGroup?.name} isBold />
          </HStack>
          {parentId === 'root' ? (
            groupForm
          ) : (
            <ToggleFeatures
              feature="ui_manage_plcs"
              on={<>{isNesting ? controllerForm : <Tabs tabs={tabs} onChangeTab={onChangeTab} />}</>}
              off={groupForm}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button theme={ButtonTheme.BACKGROUND} onClick={onSubmit}>
            Добавить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
