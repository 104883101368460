import { createSelector } from '@reduxjs/toolkit'
import { NavbarItemType } from '../../../types/navbar'
import { getRouteEvents, getRouteGraph, getRouteManage, getRouteMap, getRouteStatus } from '../../../consts/router'
import { ReactComponent as StatusIcon } from '../../../assets/icons/status-icon.svg'
import { ReactComponent as EventsIcon } from '../../../assets/icons/events-icon.svg'
import { ReactComponent as ManageIcon } from '../../../assets/icons/manage-icon.svg'
import { ReactComponent as MapIcon } from '../../../assets/icons/map-icon.svg'
import { getFeatureFlag } from '../../../utils/features'
import { getUser } from '../user/userSelectors'

export const getNavbarItems = createSelector(getUser, (userData) => {
  const navbarItemsList: NavbarItemType[] = []
  if (userData) {
    if (getFeatureFlag('ui_status')) {
      navbarItemsList.push({
        path: getRouteStatus(),
        Icon: StatusIcon,
        title: 'Состояние',
        authOnly: true,
      })
    }
    if (getFeatureFlag('ui_events')) {
      navbarItemsList.push({
        path: getRouteEvents(),
        Icon: EventsIcon,
        title: 'Журнал',
        authOnly: true,
      })
    }
    if (getFeatureFlag('ui_map')) {
      navbarItemsList.push({
        path: getRouteMap(),
        Icon: MapIcon,
        title: 'Карты',
        authOnly: true,
      })
    }
    if (getFeatureFlag('ui_reports')) {
      navbarItemsList.push({
        path: getRouteGraph(),
        Icon: EventsIcon,
        title: 'Отчеты',
        authOnly: true,
      })
    }
    if (getFeatureFlag('ui_manage')) {
      navbarItemsList.push({
        path: getRouteManage(),
        Icon: ManageIcon,
        title: 'Объекты',
        authOnly: true,
      })
    }
  }

  return navbarItemsList
})
