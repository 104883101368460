import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from '@material-ui/core'
import { ManageHeader } from '../ManageHeader/ManageHeader'
import { useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { getGroupById } from '../../../../store/slices/groups/groupsSelector'
import { HStack, VStack } from '../../../../components/UI/Stack'
import { Content } from '../../../../components/UI/Content'
import { getPLSById } from '../../../../store/slices/plcs/plcsSelector'
import { ControllerInfo } from '../../../../components/Controller/ControllerInfo/ControllerInfo'
import { Text } from '../../../../components/UI/Text'
import { Icon } from '../../../../components/UI/Icon'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings-icon.svg'
import { ControllerState } from '../../../../components/Controller/ControllerState/ControllerState'
import { Button, ButtonTheme } from '../../../../components/UI/Button'
import { CounterCard } from '../../../../components/Controller/CounterCard/CounterCard'
import { PLSConnection } from '../PLSConnection/PLSConnection'
import { ControlDocs } from '../../../StatusPage/ui/ControlDocs/ControlDocs'
import { Card, CardTheme } from '../../../../components/UI/Card'
import { ManageLightControl } from '../ManageLightControl/ManageLightControl'
import cls from './AdminManage.module.scss'

export const AdminManage = () => {
  const groupId = useAppSelector(uiSelector.getClickedGroupId)
  const currentGroup = useAppSelector((state) => getGroupById(state, groupId))
  const plcId = useAppSelector(uiSelector.getClickedPlcId)
  const currentPlc = useAppSelector((state) => getPLSById(state, plcId))
  const lightId = useAppSelector(uiSelector.getClickedLightId)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleDrawer = useCallback(() => setIsOpen((prev) => !prev), [])

  if (lightId) {
    return <ManageLightControl />
  }

  return (
    <VStack gap="16">
      <ManageHeader currentGroup={currentGroup} />
      <Content>
        <HStack justify="between" gap="12" wrap="wrap" align="start">
          <Card theme={CardTheme.OUTLINED} className={cls.primaryContent}>
            <VStack gap="12">
              <HStack justify="between" max>
                <HStack gap="8">
                  <Text text="Состояние контроллера" isBold />
                  <Link to="/manage/settings">
                    <Icon Svg={SettingsIcon} />
                  </Link>
                </HStack>
                {Object.keys(currentPlc?.electricityMeterInfo).length > 0 && (
                  <Button theme={ButtonTheme.CLEAR} onClick={toggleDrawer}>
                    Показания счётчика
                  </Button>
                )}
              </HStack>
              <ControllerInfo controller={currentPlc} />
              <ControllerState plcId={plcId} controller={currentPlc} />
            </VStack>
          </Card>
          <VStack gap="12" className={cls.secondaryContent}>
            <PLSConnection />
            <ControlDocs plc={currentPlc} />
          </VStack>
        </HStack>
      </Content>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer}>
        <CounterCard onClose={toggleDrawer} />
      </Drawer>
    </VStack>
  )
}
