import { DetailedHTMLProps, HTMLAttributes, MutableRefObject, ReactNode } from 'react'
import { classNames, Mods } from '../../../../utils/classNames/classNames'
import cls from './Flex.module.scss'

export type FlexJustify = 'start' | 'center' | 'end' | 'between' | 'around'
export type FlexAlign = 'start' | 'center' | 'end'
export type FlexDirection = 'row' | 'column'
export type FlexGap = '4' | '8' | '12' | '16' | '24' | '32'
export type FlexWrap = 'nowrap' | 'wrap' | 'wrapReverse'

const justifyClasses: Record<FlexJustify, string> = {
  start: cls.justifyStart,
  center: cls.justifyCenter,
  end: cls.justifyEnd,
  between: cls.justifyBetween,
  around: cls.justifyAround,
}

const alignClasses: Record<FlexAlign, string> = {
  start: cls.alignStart,
  center: cls.alignCenter,
  end: cls.alignEnd,
}

const directionClasses: Record<FlexDirection, string> = {
  row: cls.directionRow,
  column: cls.directionColumn,
}

const gapClasses: Record<FlexGap, string> = {
  4: cls.gap4,
  8: cls.gap8,
  12: cls.gap12,
  16: cls.gap16,
  24: cls.gap24,
  32: cls.gap32,
}

const wrapClasses: Record<FlexWrap, string> = {
  nowrap: cls.nowrap,
  wrap: cls.wrap,
  wrapReverse: cls.wrapReverse,
}

type DivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface FlexProps extends DivProps {
  className?: string
  children: ReactNode
  justify?: FlexJustify
  align?: FlexAlign
  direction: FlexDirection
  gap?: FlexGap
  max?: boolean
  wrap?: FlexWrap
  innerRef?: MutableRefObject<HTMLDivElement>
}

export const Flex = (props: FlexProps) => {
  const {
    innerRef,
    className,
    children,
    justify = 'start',
    align = 'center',
    direction = 'row',
    gap,
    wrap = 'nowrap',
    max,
    ...otherProps
  } = props

  const classes = [
    className,
    justifyClasses[justify],
    alignClasses[align],
    directionClasses[direction],
    gap && gapClasses[gap],
    wrapClasses[wrap],
  ]

  const mods: Mods = {
    [cls.max]: max,
  }

  return (
    <div ref={innerRef} className={classNames(cls.Flex, mods, classes)} {...otherProps}>
      {children}
    </div>
  )
}
