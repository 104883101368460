/**
 * Created by a.osipov on 12.02.2020.
 */

// @ts-ignore
import io from 'socket.io-client'
import { config } from '../config.js'

let socket_opts
// eslint-disable-next-line import/no-mutable-exports
let socket: any

const initSocket = () => {
  const local = true
  let userToken
  const user = JSON.parse(localStorage.getItem('user'))
  function setToken(token: string) {
    userToken = token
  }
  if (user && user.token !== undefined) setToken(user.token)
  if (!local) {
    socket_opts = {
      path: '/socket/socket.io',
      transports: ['websocket'],
      query: {
        token: userToken,
      },
    }
  } else {
    socket_opts = {
      path: '/socket/socket.io',
      transports: ['websocket'],
      query: {
        token: userToken,
      },
    }
  }
  socket = io(config.apiUrl, socket_opts)

  return { socket, setToken }
}
// export const socket = io(url, socket_opts);
export { socket, initSocket }
