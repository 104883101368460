import React, { memo } from 'react'
import { Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Group } from '../../../types/group'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { uiSelector } from '../../../store/slices/ui/uiSelector'
import { classNames, Mods } from '../../../utils/classNames/classNames'
import { Tag } from '../../UI/Tag'
import { groupsActions } from '../../../store/slices/groups/groupsSlice'
import { NavLightGroup } from '../NavLightGroup/NavLightGroup'
import { getPLSById } from '../../../store/slices/plcs/plcsSelector'
import cls from './NavPLCGroup.module.scss'

interface PLCGroupProps {
  padLeft: number
  currentGroup: Group
  parent_id: number
  groups: Group[]
  plc_id: number
  onGroupClick: (parent_id: number, plc_id: number, light_id: number) => void
  isManage?: boolean
}

export const NavPLCGroup = memo((props: PLCGroupProps) => {
  const { padLeft, currentGroup, parent_id, groups, plc_id, onGroupClick, isManage = false } = props
  const ui = useAppSelector(uiSelector.getUi)
  const currentPlc = useAppSelector((state) => getPLSById(state, plc_id))
  const dispatch = useAppDispatch()

  const handleClick = () => {
    onGroupClick(parent_id, plc_id, null)
  }

  const handleToggleMenu = () => {
    dispatch(groupsActions.toggleMenu(currentGroup.id))
  }

  const mods: Mods = {
    [cls.isOpen]: currentGroup?.isMenuOpen,
    [cls.isSearchedValue]: ui.menuSearchText && groups[parent_id]?.name.toLowerCase().includes(ui.menuSearchText.toLowerCase()),
  }

  return (
    <div className={cls.navGroup}>
      <div
        onClick={handleClick}
        style={{ paddingLeft: `${padLeft}px` }}
        className={classNames(cls.navGroupTitle, {
          [cls.isOpen]: currentGroup?.isMenuOpen,
          [cls.active]: ui.clicked_group_id === currentGroup?.id,
        })}
      >
        <i className={classNames(cls.controllerIcon, {}, ['bi bi-cpu'])} />
        <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-group-name">{groups[parent_id]?.name}</Tooltip>}>
          <div className={classNames(cls.navGroupName, mods)}>{groups[parent_id]?.name}</div>
        </OverlayTrigger>
        {!isManage && groups[parent_id] && (groups[parent_id]?.alarm_count !== 0 || groups[parent_id]?.warning_count !== 0) ? (
          <Tag
            label={groups[parent_id].alarm_count + groups[parent_id].warning_count}
            theme={groups[parent_id].alarm_count !== 0 ? 'error' : 'warning'}
          />
        ) : (
          <div />
        )}
        <div className="chevronBtn" onClick={handleToggleMenu}>
          <i className={`bi bi-chevron-${currentGroup?.isMenuOpen ? 'up' : 'down'}`} />
        </div>
      </div>
      <Collapse in={currentGroup?.isMenuOpen}>
        <div>
          {currentGroup?.isMenuOpen && (
            <NavLightGroup
              onGroupClick={onGroupClick}
              padLeft={padLeft}
              isManage={isManage}
              plc_id={plc_id}
              parent_id={parent_id}
              lightsIds={currentPlc.light_ids}
            />
          )}
        </div>
      </Collapse>
    </div>
  )
})
