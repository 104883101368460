/**
 * Created by Aleksey Osipov on 26.05.2020.
 */

let new_plc_script
let newState

const pls_scripts = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_PLS_SCRIPT':
      new_plc_script = {}
      Object.defineProperty(new_plc_script, action.script.id, {
        enumerable: true,
        value: { ...action.script },
      })

      return { ...state, ...new_plc_script }

    case 'ADD_PLS_SCRIPTS':
      newState = { ...state }
      action.scripts.forEach((script) => {
        Object.defineProperty(newState, script.id, { enumerable: true, value: script })
      })

      return { ...newState }

    case 'EDIT_PLS_SCRIPT':
      return { ...state, ...new_plc_script }

    case 'DEL_PLS_SCRIPT':
      newState = structuredClone(state)
      Object.values(newState).forEach((sc) => {
        if (sc.plc_id == action.plc_id) delete newState[sc.id]
      })

      return { ...newState }

    default:
      return state
  }
}

export default pls_scripts
