import { Text, TextTheme } from '../../../components/UI/Text'
import { useAppSelector } from '../../../store/hooks'
import { getOrganisationLicense } from '../../../store/slices/user/userSelectors'
import cls from './LicenseMessage.module.scss'

export const LicenseMessage = () => {
  const license = useAppSelector(getOrganisationLicense)

  const date =
    license?.finishDate &&
    new Date(license?.finishDate).toLocaleString('ru', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    })

  return license?.licenseType === 'limited' ? (
    <Text text={`${license.success} ${date ? `до ${date}` : ''}`} className={cls.messageBlock} theme={TextTheme.ERROR} />
  ) : (
    <></>
  )
}
