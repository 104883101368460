import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { setFeatureFlags } from '../../../utils/features'
import { User } from '../../../types/user'
import { socket } from '../../../utils'
import { licenseService } from '../auth/licenseService'

const localStorageUser = JSON.parse(localStorage.getItem('user'))

if (localStorageUser?.features) {
  setFeatureFlags(localStorageUser.features)
}

const initialState: User = localStorageUser ?? null

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }: PayloadAction<User>) => {
      state = payload

      if (payload.features) {
        setFeatureFlags(payload.features)
      }

      return state
    },

    saveUser: (state, { payload }: PayloadAction<User>) => {
      state = { ...state, ...payload }
      localStorage.setItem('user', JSON.stringify(state))

      return state
    },

    toggleAckSort: (state, action: PayloadAction<{ checked: boolean }>) => {
      state.isAscSort = action.payload.checked
      localStorage.setItem('user', JSON.stringify(state))
    },

    logout: (state) => {
      state = null
      localStorage.removeItem('user')
      socket.close()

      return state
    },

    changePhoneConfirm: (state, { payload }: PayloadAction<number>) => {
      state.phone_confirmed = payload
      localStorage.setItem('user', JSON.stringify(state))
    },
    changeEmailConfirm: (state, { payload }: PayloadAction<number>) => {
      state.email_confirmed = payload
      localStorage.setItem('user', JSON.stringify(state))
    },
    changePhone: (state, { payload }: PayloadAction<string>) => {
      state.phone = payload
      localStorage.setItem('user', JSON.stringify(state))
    },
    changeEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload
      localStorage.setItem('user', JSON.stringify(state))
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(licenseService.pending, (state) => {
        state.organisationLicence.error = ''
      })
      .addCase(licenseService.rejected, (state) => {
        state.organisationLicence.error = 'Ошибка выполнения запроса на сервер'
      })
      .addCase(licenseService.fulfilled, (state, { payload }) => {
        if (payload.result === 'error') {
          state.organisationLicence.error = payload.errorText
        } else {
          state.organisationLicence = payload.organisationLicence
        }

        console.log('payload', payload)
      })
  },
})

export const { actions: userActions, reducer: userReducer } = userSlice
