import React, { Children, memo, ReactElement, useMemo, useRef, useState } from 'react'
import { HStack } from '../Stack'
import { Button, ButtonTheme } from '../Button'
import { classNames } from '../../../utils/classNames/classNames'
import { ReactComponent as LeftArrow } from '../../../assets/icons/left-arrow-icon.svg'
import { ReactComponent as RightArrow } from '../../../assets/icons/right-arrow-icon.svg'
import { Icon } from '../Icon'
import cls from './Slider.module.scss'

interface SliderProps {
  className?: string
  children: ReactElement
}

export const Slider = memo((props: SliderProps) => {
  const { className, children } = props
  const [offset, setOffset] = useState(0)
  const sliderRef = useRef(null)

  const limitedEl = useMemo(() => {
    const countEl = Children.count(children.props.children)
    const allWidth = sliderRef.current?.scrollWidth
    const viewWidth = sliderRef.current?.getBoundingClientRect().width
    if (countEl && allWidth && viewWidth) {
      const countViewEl = Math.floor(viewWidth / (allWidth / countEl))

      return -(countEl - countViewEl) < 0 ? -(countEl - countViewEl) : offset
    }

    return -(countEl - 8) < 0 ? -(countEl - 8) : offset
  }, [children.props.children, offset])

  return (
    <HStack className={classNames(cls.sliderWrapper, {}, [className])}>
      <Button
        theme={ButtonTheme.CLEAR}
        disabled={offset === 0}
        onClick={() => {
          setOffset((prev) => prev + 1)
          sliderRef.current.style.left = `${(offset + 1) * 80}px`
        }}
      >
        <Icon Svg={LeftArrow} />
      </Button>
      <div className={cls.contentWrap}>
        <div className={cls.content} ref={sliderRef}>
          {children}
        </div>
      </div>
      <Button
        theme={ButtonTheme.CLEAR}
        disabled={offset === limitedEl}
        onClick={() => {
          setOffset((prev) => prev - 1)
          sliderRef.current.style.left = `${(offset - 1) * 80}px`
        }}
      >
        <Icon Svg={RightArrow} />
      </Button>
    </HStack>
  )
})
