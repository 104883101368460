import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { PageTitle } from '../../../../components/UI/PageTitle'
import { useAppSelector } from '../../../../store/hooks'
import { getLightById } from '../../../../store/slices/lights/lightsSelector'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { HStack, VStack } from '../../../../components/UI/Stack'
import { Button } from '../../../../components/UI/Button'
import { Content } from '../../../../components/UI/Content'
import { Text } from '../../../../components/UI/Text'
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings-icon.svg'
import { Icon } from '../../../../components/UI/Icon'
import { LightInfo } from '../../../MapPage/ui/Light/LightInfo/LightInfo'
import { socket } from '../../../../utils'
import { LightDeleteBtn } from '../../../../components/LightSettings/LightDeleteBtn/LightDeleteBtn'
import cls from './ManageLightControl.module.scss'

export const ManageLightControl = () => {
  const lightId = useAppSelector(uiSelector.getClickedLightId)
  const groupId = useAppSelector(uiSelector.getClickedGroupId)
  const light = useAppSelector((state) => getLightById(state, lightId))
  const [blinkMode, setBlinkMode] = useState<boolean>(false)

  const changeBlinkMode = useCallback(() => {
    if (!blinkMode)
      socket?.emit('/plc/lamps/mode/on', {
        serverGroupId: groupId,
        id: light?.lampRecID,
        sourceNumber: -1,
        timeout: 300,
        plc: { frequency: 1, modulation: 5 },
      })
    else
      socket?.emit('/plc/lamps/mode/off', {
        serverGroupId: groupId,
        id: light.lampRecID,
        sourceNumber: -1,
        timeout: 300,
        plc: { frequency: 1, modulation: 5 },
      })
    setBlinkMode((prev) => !prev)
  }, [blinkMode, groupId, light?.lampRecID])

  return (
    <VStack gap="16">
      <HStack justify="between" max>
        <HStack gap="16">
          <PageTitle title={light?.name} type="light" id={String(parseInt(light?.lampRecID, 16))} />
          <Button onClick={changeBlinkMode}>{blinkMode ? 'Выключить' : 'Включить'} мерцание</Button>
        </HStack>

        <HStack gap="8" className={cls.btnsWrapper}>
          <LightDeleteBtn lightId={lightId} />
        </HStack>
      </HStack>
      <Content>
        <HStack max justify="between">
          <HStack gap="8">
            <Text text="Состояние светильника" isBold />
            <Link to="/manage/settings">
              <Icon Svg={SettingsIcon} />
            </Link>
          </HStack>
          {/* <Button theme={ButtonTheme.CLEAR}>Обновить параметры</Button> */}
        </HStack>
        {light && <LightInfo light={light} isFullInfo className={cls.infoWrapper} />}
      </Content>
    </VStack>
  )
}
