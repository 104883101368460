import { MouseEvent, memo, useState } from 'react'
import { Popover } from '@material-ui/core'
import { Button, ButtonTheme } from '../../UI/Button'
import { Icon } from '../../UI/Icon'
import { ReactComponent as NotificationIcon } from '../../../assets/icons/notification-icon.svg'
import { useAppSelector } from '../../../store/hooks'
import { notificationSelector } from '../../../store/slices/notification/notificationSelector'
import { HStack } from '../../UI/Stack'
import { Tag } from '../../UI/Tag'
import { NotificationList } from '../NotificationList/NotificationList'
import cls from './NotificationButton.module.scss'

export const NotificationButton = memo(() => {
  const alarmCount = useAppSelector(notificationSelector.getAlarmCount)
  const warningCount = useAppSelector(notificationSelector.getWarningCount)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button theme={ButtonTheme.CLEAR} onClick={handleClick} className={cls.btn}>
        <HStack>
          {(alarmCount > 0 || warningCount > 0) && (
            <Tag label={alarmCount || warningCount} theme={alarmCount ? 'error' : 'warning'} />
          )}
          <Icon Svg={NotificationIcon} />
        </HStack>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList />
      </Popover>
    </>
  )
})
