import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Event, EventComment } from '../../../types/event'
import { eventTypes } from '../../../consts/common'

interface EventsState {
  [key: number]: Event
}

const initialState: EventsState = {}

interface RequestProps {
  groupId: number
  from: number
  to: number
  count?: number
  eventFilters?: string[]
  filter?: number
  offset?: number
  query?: {
    groupId: number
    eventFilters?: string[]
  }
}

interface AddParams {
  eventId: number
  eventIds: number[]
  time: number
  comment: string
  status: string
  query?: {
    eventId: number
  }
}

const editEvent = (event: Event, isOnce: boolean) => {
  const new_event = {}

  const createEventObj = (event: Event, new_event: {}, evt_name: string, work_status: string) => {
    const new_id = event.eventID ?? event.eventId
    Object.defineProperty(new_event, new_id, {
      enumerable: true,
      value: {
        id: new_id,
        name: evt_name,
        date: new Date(event.appearTime * 1000).toLocaleString('ru', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        }),
        time: new Date(event.appearTime * 1000).toLocaleString('ru', { hour: '2-digit', minute: '2-digit' }),
        status: event.emergencyStatus == 2 ? 'alarm' : event.emergencyStatus == 1 ? 'warning' : 'work',
        work_status,
        lampID: event.type === 14 ? event.lampID : null,
        eventID: new_id,
        plc_id: event.serverId,
        ...event,
      },
    })

    return new_event
  }

  if (eventTypes.hasOwnProperty(event.type)) {
    let evt_name = eventTypes[event.type][event.subtype] || '[Неизвестный тип события]'
    evt_name += event.type === 14 ? `. ID светильника ${parseInt(event.lampID, 16)}` : ''
    if (event.type === 16 && !(event.subtype === 7 || event.subtype === 8)) {
      evt_name =
        event.payload?.dimmingPercentage === 255
          ? `Режим работы светильников переведён под управление контроллера`
          : `${evt_name} изменено на ${event.payload?.dimmingPercentage}%`
    }

    let work_status = 'comment'
    if (!isOnce && event.eventComments.length != 0) {
      work_status = event.eventComments.find((ec) => ec.status == 'done')
        ? 'done'
        : event.eventComments.find((ec) => ec.status == 'in_progress')
        ? 'on_progress'
        : 'comment'
    }

    createEventObj(event, new_event, evt_name, work_status)
  }

  if (event.type === 128 && !event.payload?.userId) {
    const evt_name =
      event.payload.state !== 255
        ? `Пользователь отправил команду на ${event.payload.state === 1 ? 'включение' : 'выключение'} реле номер ${
            event.payload.relay
          }`
        : `Пользователь перевел реле номер ${event.payload.relay} под управление контроллера`
    const work_status = 'comment'
    createEventObj(event, new_event, evt_name, work_status)
  }

  return new_event
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    getEvents: (state) => {
      return state
    },
    addEvent: (state, { payload }: PayloadAction<Event>) => {
      const new_event = editEvent(payload, true)

      return { ...state, ...new_event }
    },
    addEvents: (state, { payload }: PayloadAction<Event[]>) => {
      let events = {}
      payload.forEach((event) => {
        const new_event = editEvent(event, false)
        events = { ...events, ...new_event }
      })

      return { ...events }
    },
    clearEvents: (state) => {
      return {}
    },
    updateEvents: (state, action: PayloadAction<RequestProps>) => {
      return state
    },
    updateEventsStatus: (state, action: PayloadAction<RequestProps>) => {
      return state
    },
    addEventParam: (state, action: PayloadAction<AddParams>) => {
      return state
    },
    setEventWorkStatus: (state, { payload }: PayloadAction<Event>) => {
      const new_state: { [key: number]: Event } = {}
      new_state[payload.id] = { ...state[payload.id], work_status: payload.work_status }

      return { ...state, ...new_state }
    },
    setEventComments: (state, { payload }: PayloadAction<{ id: number; comments: EventComment[] }>) => {
      const new_state1: { [key: number]: Event } = {}
      new_state1[payload.id] = { ...state[payload.id] }
      new_state1[payload.id].eventComments = payload.comments
      new_state1[payload.id].work_status = 'comment'
      if (payload.comments.length != 0) {
        new_state1[payload.id].work_status = payload.comments.find((ec) => ec.status == 'done')
          ? 'done'
          : payload.comments.find((ec) => ec.status == 'in_progress')
          ? 'on_progress'
          : 'comment'
      }

      return { ...state, ...new_state1 }
    },
  },
})

export const eventsActions = eventsSlice.actions

export default eventsSlice.reducer
