import { memo, ReactNode } from 'react'
import { Tab as TabRB, Tabs as TabsRB } from 'react-bootstrap'
import { classNames } from '../../../utils/classNames/classNames'
import cls from './Tabs.module.scss'

export interface Tab {
  title: string
  content: ReactNode
  eventKey?: string
}

interface TabsProps {
  className?: string
  onChangeTab?: (value: string) => void
  tabs: Tab[]
}

export const Tabs = memo((props: TabsProps) => {
  const { onChangeTab, tabs, className } = props

  return (
    <TabsRB className={classNames(cls.tabs, {}, [className])} onSelect={onChangeTab}>
      {tabs.map((tab) => (
        <TabRB key={tab.eventKey} title={tab.title} eventKey={tab.eventKey}>
          {tab.content}
        </TabRB>
      ))}
    </TabsRB>
  )
})
