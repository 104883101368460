import React, { useCallback, useEffect, useState } from 'react'
import { NavLightPanel } from '../NavLightPanel/NavLightPanel'
import { NavLightItem } from '../NavLightItem/NavLightItem'

interface NavLightGroupProps {
  onGroupClick: (parentId: number, plcId: number, lightId: number) => void
  padLeft: number
  isManage: boolean
  plc_id: number
  parent_id: number
  lightsIds: number[]
}

export const NavLightGroup = (props: NavLightGroupProps) => {
  const { onGroupClick, padLeft, isManage, plc_id, parent_id, lightsIds } = props
  const [currentIds, setCurrentIds] = useState<number[]>(lightsIds)

  useEffect(() => {
    setCurrentIds(lightsIds)
  }, [lightsIds])

  const onChangeIds = useCallback(
    (ids: number[], type?: 'none') => {
      if (type === 'none') {
        setCurrentIds(lightsIds)
      } else {
        setCurrentIds(ids)
      }
    },
    [lightsIds]
  )

  return (
    <>
      {isManage && (
        <NavLightPanel padLeft={padLeft} allLightsIds={lightsIds} currentLightsIds={currentIds} onChange={onChangeIds} />
      )}
      {currentIds.map((id) => (
        <NavLightItem
          key={id}
          lightId={id}
          onGroupClick={onGroupClick}
          padLeft={padLeft}
          isManage={isManage}
          plc_id={plc_id}
          parent_id={parent_id}
        />
      ))}
    </>
  )
}
