import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { config } from '../../config'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { NotificationButton } from '../Notification'
import { ReactComponent as LogoIcon } from '../../assets/icons/logo-icon.svg'
import { HStack } from '../UI/Stack'
import { Icon } from '../UI/Icon'
import { ToggleFeatures } from '../../utils/features'
import { LicenseMessage } from '../../pages/LicensedPage'
import { userActions } from '../../store/slices/user/userSlice'
import { getUser } from '../../store/slices/user/userSelectors'
import cls from './Header.module.scss'

const Header = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUser)

  const exitHandler: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()
    dispatch(userActions.logout())
  }

  return (
    <HStack>
      <ToggleFeatures
        feature="ui_script_interface"
        on={
          <HStack align="center" justify="center" className={cls.logoWrapper}>
            <Icon Svg={LogoIcon} />
          </HStack>
        }
        off={<></>}
      />

      <div className="mainview-header">
        <LicenseMessage />
        <ToggleFeatures feature="ui_notification_button" on={<NotificationButton />} off={<></>} />
        <div className={`dropdown ${config.isRecon && 'isRecon'}`}>
          <a
            className="nav-link dropdown-toggle"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {user.companyName}
          </a>

          <ul className="dropdown-menu mainview-header-dropdown-menu">
            <ToggleFeatures
              feature="ui_profile"
              on={
                <li>
                  <Link className="dropdown-item" to="/user">
                    Настройки
                  </Link>
                </li>
              }
              off={<></>}
            />
            <li>
              <hr className="dropdown-divider m-0" />
            </li>
            <li>
              <a className="dropdown-item mainview-header-exit" href="#" onClick={(e) => exitHandler(e)}>
                <span>
                  <div className="mainview-header-name">{user.name}</div>
                  <div className="mainview-header-role">{user.roleName}</div>
                </span>

                <i className="bi bi-box-arrow-right" style={{ color: 'red', fontSize: '2.5rem' }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </HStack>
  )
}

export default memo(Header)
