import React from 'react'
import { useAppSelector } from '../../../../store/hooks'
import { uiSelector } from '../../../../store/slices/ui/uiSelector'
import { HStack } from '../../../../components/UI/Stack'
import { Group } from '../../../../types/group'
import { AddGroupModal } from '../AddGroupModal/AddGroupModal'
import { EditGroupModal } from '../EditGroupModal/EditGroupModal'
import { DeleteGroupModal } from '../DeleteGroupModal/DeleteGroupModal'
import { PlcHeader } from '../../../../components/PlcHeader/PlcHeader'
import { Text, TextSize } from '../../../../components/UI/Text'
import { ToggleFeatures } from '../../../../utils/features'
import cls from './ManageHeader.module.scss'

interface ManageHeaderProps {
  currentGroup: Group
}

export const ManageHeader = (props: ManageHeaderProps) => {
  const { currentGroup } = props
  const plcId = useAppSelector(uiSelector.getClickedPlcId)

  return (
    <HStack max justify="between" align="center">
      {plcId ? <PlcHeader plcId={plcId} /> : <Text title={currentGroup?.name} size={TextSize.L} />}
      <ToggleFeatures
        feature={plcId ? 'ui_manage_plcs' : 'ui_manage_groups'}
        on={
          <HStack gap="8" align="center" className={cls.btnGroup}>
            {!plcId && <AddGroupModal />}
            <EditGroupModal currentGroup={currentGroup} />
            <DeleteGroupModal />
          </HStack>
        }
        off={<></>}
      />
    </HStack>
  )
}
