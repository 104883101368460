import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Light } from '../../../types/light'
import { Group } from '../../../types/group'

interface LightsSchema {
  [key: number]: Light
}

const initialState: LightsSchema = {}

interface IRModeParams {
  serverGroup?: number
  light_mode?: string
  standbyPower?: number
  activePower?: number
  timeout?: number
  locationShow?: boolean
}

const lightsSlice = createSlice({
  name: 'lightsSlice',
  initialState,
  reducers: {
    addLight: (state, { payload }: PayloadAction<Light>) => {
      state[payload.lampID] = { ...payload, id: payload.lampID, locationShow: false }
    },
    removeLight: (state, { payload }: PayloadAction<number>) => {
      delete state[payload]

      return state
    },
    addLights: (state, { payload }: PayloadAction<Light[]>) => {
      const newState = { ...state }
      payload.forEach((light) =>
        Object.defineProperty(newState, light.lampID, {
          enumerable: true,
          value: { ...light, status: 'work', id: light.lampID, locationShow: false },
        })
      )

      return newState
    },

    setLightsStatus: (state, { payload }: PayloadAction<Light[]>) => {
      for (const lightId in state) {
        const status = payload.find((gr) => gr.id == state[lightId].groupId)
        if (status) {
          if (status.warning_count > 0) {
            state[lightId].status = 'warning'
            state[lightId].warning_count = status.warning_count
            state[lightId].alarm_count = status.alarm_count
          }
          if (status.alarm_count > 0) {
            state[lightId].status = 'alarm'
            state[lightId].warning_count = status.warning_count
            state[lightId].alarm_count = status.alarm_count
          }
          if (status.alarm_count <= 0 && status.warning_count <= 0) {
            state[lightId].status = 'work'
            state[lightId].warning_count = 0
            state[lightId].alarm_count = 0
          }
        }
      }
    },
    changeLightPower: (state, { payload }: PayloadAction<{ id: number; power: number }>) => {
      state[payload.id].state[0].highPriorityPower = payload.power
      if (payload.power !== 255) {
        state[payload.id].state[0].setupPower = payload.power
      }
    },
    changeLightPowerByGroup: (state, { payload }: PayloadAction<{ plcId: number; serverGroup: number; power: number }>) => {
      for (const lightId in state) {
        if (state[lightId].serverID === payload.plcId && state[lightId].serverGroup === payload.serverGroup) {
          state[lightId].state[0].highPriorityPower = payload.power / 100
        }
      }
    },
    changeLightIRModeParams: (state, { payload }: PayloadAction<{ id: number; value: IRModeParams }>) => {
      state[payload.id].light_mode = payload.value.light_mode
      state[payload.id].serverGroup = payload.value.serverGroup
      state[payload.id].standbyPower = payload.value.standbyPower
      state[payload.id].activePower = payload.value.activePower
      state[payload.id].timeout = payload.value.timeout
      state[payload.id].locationShow = payload.value.locationShow
    },
    changeLightIRParams: (state, { payload }: PayloadAction<{ id: number; value: IRModeParams }>) => {
      state[payload.id].standbyPower = payload.value.standbyPower
      state[payload.id].activePower = payload.value.activePower
      state[payload.id].timeout = payload.value.timeout
    },
    changeLight: (state, { payload }: PayloadAction<Light>) => {
      state[payload.id] = { ...state[payload.id], ...payload }
    },
  },
})

export const { actions: lightsActions, reducer: lightsReducer } = lightsSlice
