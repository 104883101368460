import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState, ThunkConfig } from '../../store'
import { IMap } from '../../../types/map'

interface MapDataParsingProps {
  groupId: number
  plcId: number
  lightId: number
}

export const mapDataParsing = createAsyncThunk<IMap, MapDataParsingProps, ThunkConfig<string>>(
  'mapDataParsing',
  async ({ groupId, plcId, lightId }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi

    try {
      const { mapData, plcs, lights, groups } = getState() as RootState
      const newMapData = { ...mapData }

      if (plcId !== null) {
        newMapData.center =
          lightId === null
            ? [Number(plcs[plcId].coordinates.latitude), Number(plcs[plcId].coordinates.longtitude)]
            : [Number(lights[lightId]?.coordinates.latitude), Number(lights[lightId]?.coordinates.longtitude)]
        newMapData.clicked_plc_id = plcId
        newMapData.clicked_light_id = lightId
        newMapData.light_ids = plcs[plcId].light_ids

        return newMapData
      }
      if (groupId) {
        const childrenGroups = Object.values(groups).filter((f) => f.parent_id === groupId)

        let sumLatitude = 0
        let sumLongtitude = 0
        newMapData.clicked_plc_id = []
        const childrenPlc = childrenGroups
          .map((group) => {
            if (group.plc_id) {
              const plc = plcs[group.plc_id]
              sumLatitude += parseFloat(plc.coordinates.latitude)
              sumLongtitude += parseFloat(plc.coordinates.longtitude)
              if (Array.isArray(newMapData.clicked_plc_id) && group.isGeo) {
                newMapData.clicked_plc_id.push(plc.id)
              }

              return plc
            }

            return Object.values(groups).reduce((acc, el) => {
              if (el.parent_id === group.id && el.isGeo) {
                const plc = plcs[el.plc_id]
                if (plc) {
                  sumLatitude += parseFloat(plc.coordinates.latitude)
                  sumLongtitude += parseFloat(plc.coordinates.longtitude)
                  if (Array.isArray(newMapData.clicked_plc_id)) {
                    newMapData.clicked_plc_id.push(plc.id)
                  }
                  acc.push(plc)
                }
              }

              return acc
            }, [])
          })
          .flat()
        if (sumLatitude && sumLongtitude) {
          newMapData.center = childrenGroups.length
            ? [sumLatitude / childrenPlc.length, sumLongtitude / childrenPlc.length]
            : null
        }

        newMapData.light_ids = []

        return newMapData
      }

      return mapData
    } catch (e) {
      console.log(e)
      rejectWithValue('')
    }
  }
)
